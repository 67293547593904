import React, { useState } from "react";
// import logo from "../../assets/images/tuteehub_lgo.webp";
import { Formik } from "formik";
import * as Yup from "yup";
import { ForgotPasswordApi, LoginApi, ResetPasswordApi } from "../../api/AuthService";
import { useAuth } from "../../Context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Loadingicon } from "../../AppIcons";
import { getUserToken } from "../../api/OurService";
import { PostData } from "../../api/feeds/PostData";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

function ResetPassword() {
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const { setUser, setFToken } = useAuth();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const DisplayingErrorMessagesSchema = Yup.object().shape({
        token: Yup.string().required("Token is required"),
        email: Yup.string().email("Invalid email").required("Email is required"),
        password: Yup.string().required("Password is required").matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{6,}$/,
            "Password must be at least 6 characters and contain at least one uppercase letter, one lowercase letter, one number, and one special character"
          ),
      confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required")
       
    });

    

    const handleResetPassword = async (values, resetForm) => {
        setLoading(true);
        try {
          const response = await ResetPasswordApi(values);
         
          if (response?.data?.success) {
            toast.success("Your password reset successfully!");
            setTimeout(() => { navigate("/login"); }, 1000)
            resetForm()
                 } else {
            toast.error(response.data?.errors || "Token is not valid");
          }
        } catch (err) {
          toast.error(err.message || "Something went wrong!");
        } finally {
          setLoading(false);
        }
    };

    return (
        <section className="bg-white" id="top-area" style={{ padding: 0 }}>
            <div className="flex flex-col items-center justify-center px-6 mx-auto py-4 lg:py-0 main-container-login">

                <div className="w-full bg-white rounded-lg shadow-customs mb-4 md:mt-0 sm:max-w-md xl:p-0 form-container">
                    <div className="w-100 text-center">
                        <Link
                            to={'/'}
                            className="flex items-center justify-center mt-6 mb-2 text-2xl font-semibold text-gray-900 dark:text-white"
                        >
                            <img className=" mr-2" src={require('../../assets/images/tuteehub_lgo.webp')} alt="logo" width={130}/>
                        </Link>
                    </div>

                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <p className="text-gray-500 text-sm">Enter the token sent on registered email and reset your new password.</p>
                        <Formik
                            initialValues={{
                                email: "",
                                token:"",
                                password:"",
                                confirmPassword:""
                            }}
                            validationSchema={DisplayingErrorMessagesSchema}
                            onSubmit={(values, {resetForm}) => {
                                handleResetPassword(values, resetForm);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                            }) => (
                                <form className="" onSubmit={handleSubmit}>
                                     <div>
                                        <label
                                            htmlFor="token"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                                        >
                                            Token
                                            <span className="text-red-400 text-base">*</span>
                                        </label>
                                       <input
                                            type="text"
                                            name="token"
                                           
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                            placeholder=""
                                            required=""
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.token}
                                        />
                                        <div className="text-red-500 text-sm text-right min-h-[20px]">
                                            {touched.token && errors.token && errors.token}
                                        </div>
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="email"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                                        >
                                            Email Id
                                            <span className="text-red-400 text-base">*</span>
                                        </label>
                                        <input type="text" name="fake-email" style={{ display: 'none' }} />
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                            placeholder="email@example.com"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                        <div className="text-red-500 text-sm text-right min-h-[20px]">
                                            {touched.email && errors.email && errors.email}
                                        </div>
                                    </div>
                                    <div>
                                    <label
                                    htmlFor="newpassword"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                                    >
                                    New Password<span className="text-red-400 text-base">*</span>
                                    </label>
                                    <div className="flex position-relative">
                                    <input type="password" name="fake-password" style={{ display: 'none' }} />
                                    <input
                                    type={showPassword?"text":"password"}
                                    name="password"
                                    id="password"
                                    placeholder="••••••••"
                                    autoComplete="off"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    required=""
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    />
                                     <button
                                        type="button"
                                        className="password-toggle-button"
                                        onClick={() => setShowPassword(!showPassword)}
                                        >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </button>
                                    </div>
                                    <div className="text-red-500 text-sm text-right min-h-[20px]">
                                    {touched.password && errors.password && errors.password}
                                    </div>
                                </div>
                                <div>
                                    <label
                                    htmlFor="confirmpassword"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                                    >
                                    Confirm Password<span className="text-red-400 text-base">*</span>
                                    </label>
                                    <div className="flex position-relative">
                                    <input type="password" name="fake-password" style={{ display: 'none' }} />
                                    <input
                                    type={showConfirmPassword?"text":"password"}
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    placeholder="••••••••"
                                    autoComplete="off"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    required=""
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.confirmPassword}
                                    />
                                      <button
                                        type="button"
                                        className="password-toggle-button"
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </button>
                                    </div>
                                    <div className="text-red-500 text-sm text-right min-h-[20px]">
                                    {touched.confirmPassword && errors.confirmPassword && errors.confirmPassword}
                                    </div>
                                </div>
                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className="mt-2 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center"
                                    >
                                        {loading ? (
                                            <div className="h-5 w-5">
                                                <Loadingicon />
                                            </div>
                                        ) : (
                                            "Submit"
                                        )}
                                    </button>
                                </form>
                            )}
                        </Formik>
                        <p className="mt-2 mb-0 pt-1 text-sm font-semibold text-center">
                            Back to 
                            <Link to="/login" className="text-themecolor transition duration-150 ease-in-out hover:text-danger-600 focus:text-danger-600 active:text-danger-700 ml-1">Sign In</Link>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ResetPassword;
