import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function CustomTab({ defaultActiveTab }) {
  const [activeTab, setActiveTab] = useState(defaultActiveTab || 1);
    const navigate = useNavigate();
  useEffect(() => {
    // This code snippet runs only once after the component mounts
    updateIndicator(defaultActiveTab);
  }, []);

  // Function to update the tab indicator position
  const updateIndicator = (activeT) => {
    const activeTabElement = document.querySelector(`.tab-item[data-tab="${activeT}"]`);
    if (activeTabElement) {
      const indicator = document.querySelector('.custom-tab-indicator');
      const indicatorWidth = activeTabElement.offsetWidth;
    //   console.log(indicatorWidth);
      const left = activeTabElement.offsetLeft;
    //   console.log(activeT == 1 ? left + 5 : activeT == 3 ? left - 5 : left + 'px')
      indicator.style.left = activeT == 1 ? left + 'px': activeT == 3 ? left + 'px' : left + 'px';
      indicator.style.width = activeT == 1 ? indicatorWidth + 'px': activeT == 3 ? indicatorWidth + 'px' : indicatorWidth + 'px';
    }
  };

  // Handle tab click
  const handleTabClick = (tabId, href) => {
    setActiveTab(tabId);
    updateIndicator(tabId);
    navigate(href)
    // Perform any other actions related to tab clicking here
  };

  return (
    <div className="row mx-0">
      <div className="custom-tabs mb-4 mx-auto px-1">
        <div className={`tab-item ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1, "/gigs/tasks")} data-tab="1" data-href="/campaign">Tasks</div>
        {/* <div className={`tab-item ${activeTab === 2 ? 'active' : ''}`} onClick={() => handleTabClick(2, "/offers")} data-tab="2" data-href="/offers">Offers</div> */}
        <div className={`tab-item ${activeTab === 3 ? 'active' : ''}`} onClick={() => handleTabClick(3, "/surveys")} data-tab="3" data-href="/surveys">Surveys</div>
        <div className="custom-tab-indicator"></div>
      </div>
    </div>
  );
}

export default CustomTab;
