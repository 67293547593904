import React, { useState, useEffect } from "react";
// import logo from "../../assets/images/tuteehub_lgo.webp";
import { Formik } from "formik";
import * as Yup from "yup";
import { LoginApi, SocialLoginApi } from "../../api/AuthService";
import { useAuth } from "../../Context/AuthContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Loadingicon } from "../../AppIcons";
import { getUserInfo, getUserToken } from "../../api/OurService";
import { PostData } from "../../api/feeds/PostData";
import { GoogleLogin } from "react-google-login";
import GoogleSocialLogin from "../sociallogin";
import { CDN_BASE_URL, GOOGLE_WEB_CLIENT_ID, G_CAPTCHA_V2_SITE_KEY, G_CAPTCHA_V3_SITE_KEY, USER_INFO_KEY, fetchUserInfo, isMobile } from "../../utils/utils";
import { useVerifyUserStatus } from "../../Context/VerifyStatusContext";
import PasswordToggleIcon from "../common/passwordVisibility";
import ReCAPTCHA from "react-google-recaptcha";

function Login() {
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { setUser, setFToken, setFinance, user, setGlobalToken } = useAuth();
  const { setIsEmailVerified } = useVerifyUserStatus();
  const [isVerified, setIsVerified] = useState(false);
  // const location = useLocation();
  const DisplayingErrorMessagesSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  // const getParams = () => {
  //   const queryParams = new URLSearchParams(location.search);
  //   const type = queryParams.get('type'); // Get the 'type' query parameter
  //   const tCat = queryParams.get('tCat'); // Get the 'tCat' query parameter
  //   const tScat = queryParams.get('tScat'); // Get the 'tScat' query parameter
  //   const tId = queryParams.get('tId'); // Get the 'tId' query parameter
  //   return {
  //     type: type || null,
  //     tCat: tCat || null,
  //     tScat: tScat || null,
  //     tId: tId || null,
  //   }
  // }

  const feedsToken = async (loggedData) => {
    // localStorage.setItem("___fTkn", loggedData?.secret_token);
    // localStorage.setItem("userDetails", JSON.stringify({ ...loggedData }));
    // localStorage.setItem('ev_______', loggedData?.email_verified);
	// setIsEVerified(loggedData?.email_verified);
    setGlobalToken(loggedData?.secret_token);
    fetchUserInfo(loggedData?.tuteehub_id, loggedData?.secret_token, setUser, setFinance, loggedData, setLoading, null, loggedData?.imageUrl);
  }

  const handleRecaptchaVerify = (token) => {
    setRecaptchaToken(token);
  };

  const loginSerice = async (values) => {
    if (isVerified) {
    setLoading(true);
    try {
      const response = await LoginApi(values);

      if (response?.data?.status === 1) {
        setIsEmailVerified(response.data.data?.email_verified);
        feedsToken(response.data.data)
      } else {
        toast.error(response.data.message || "Something went wrong!");
        setLoading(false);
      }
    } catch (err) {
      toast.error(err.message || "Something went wrong!");
      setLoading(false);
    } finally {

    }
  } else {
    toast.error("Captcha is required!");
}
  };

  const handleGoogleLoginSuccess = async (response) => {

    const { email, familyName, givenName, googleId, imageUrl, name } = response?.profileObj;
  
    const registerParams = {
      fname: givenName,
      lname: familyName,
      email,
      user_type: 'user',
      deviceType: 1,
      deviceToken: 'desktopweb',
      osVersion: 'desktopweb',
      isRegisterSource: 'tuteehub'
    }
    setLoading(true);
    try {
      const response = await SocialLoginApi(registerParams);
      if (response?.data?.success) {
        setIsEmailVerified(response.data.data?.email_verified);
        feedsToken({...response.data.data, imageUrl:imageUrl})
      } else {
        toast.error(response?.data?.message || "Something went wrong!");
        setLoading(false);
      }
    } catch (err) {
      toast.error(err.message || "Something went wrong!");
      setLoading(false);
    } finally {
    }
  };

  const handleGoogleLoginFailure = (error) => {
    // toast.error("Something went wrong!");
  };

  const handleVerify = (response) => {
    if (response) {
    setIsVerified(true);
    }
  };

  const handleExpired = () => {
    setIsVerified(false); 
    toast.error("reCAPTCHA verification has expired. Please try again.");
  };

  return (
    <section className="bg-white" id="top-area" style={{ padding: 0 }}>
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 main-container-login">

        <div className="w-full bg-white rounded-lg shadow-customs md:mt-0 sm:max-w-md xl:p-0 form-container">
          <div className="w-100 text-center">
            <Link
              to={'/'}
              className="flex items-center justify-center mt-6 mb-2 text-2xl font-semibold text-gray-900 dark:text-white"
            >
              <img className=" mr-2" src={require('../../assets/images/tuteehub_lgo.webp')} alt="logo" width={130} />
            </Link>
          </div>

          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <GoogleLogin
              clientId={GOOGLE_WEB_CLIENT_ID}
              onSuccess={handleGoogleLoginSuccess}
              onFailure={handleGoogleLoginFailure}
              cookiePolicy={"single_host_origin"}
              scope="profile email"
              prompt="select_account"
              render={(renderProps) => (<>
                {loading ? (
                  <div style={{
                    background: 'linear-gradient(45deg, #fafafa,rgb(255 241 229), white)',
                    color: '#5f4b4b',
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    width: '100%',
                    display: 'flex',
                    boxShadow: '0px 0px 3px rgba(0,0,0,0.3)',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    <div className="h-5 w-5">
                      <Loadingicon />
                    </div>
                  </div>
                ) : <GoogleSocialLogin onClick={renderProps.onClick} btnTitle={'SignIn with Google'} />
                }
              </>
              )}
            />

            <div className="divider">
              <div className="line"></div>
              <div className="or">or</div>
              <div className="line"></div>
            </div>

            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={DisplayingErrorMessagesSchema}
              onSubmit={(values) => {
                // window.grecaptcha.ready(() => {
                //   window.grecaptcha.execute(G_CAPTCHA_V3_SITE_KEY, { action: 'submit' }).then((token) => {
                //     handleRecaptchaVerify(token);
                    loginSerice(values);
                //   });
                // });
                // loginSerice(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form className="" onSubmit={handleSubmit}>

                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                    >
                      Your email
                      <span className="text-red-400 text-base">*</span>
                    </label>
                    <input type="text" name="fake-email" style={{ display: 'none' }} />
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      placeholder="email@example.com"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <div className="text-red-500 text-sm text-right min-h-[20px]">
                      {touched.email && errors.email && errors.email}
                    </div>
                  </div>
                  <div className="position-relative">
                    <label
                      htmlFor="password"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                    >
                      Password<span className="text-red-400 text-base">*</span>
                    </label>
                    <input type="password" name="fake-password" style={{ display: 'none' }} />
                    <input
                      type={showPassword?'text':'password'}
                      name="password"
                      id="password"
                      placeholder="••••••••"
                      autoComplete="off"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      required=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <PasswordToggleIcon showPassword={showPassword} onClick={()=>setShowPassword(!showPassword)}/>
                    <div className="text-red-500 text-sm text-right min-h-[20px]">
                      {touched.password && errors.password && errors.password}
                    </div>
                  </div>
                  <div className="flex items-center justify-content-end mt-0">
                    {/* <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id="remember"
                          aria-describedby="remember"
                          type="checkbox"
                          className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
                          required=""
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="remember"
                          className="text-gray-500 dark:text-gray-300"
                        >
                          Remember me
                        </label>
                      </div>
                    </div> */}
                      <a onClick={()=>navigate('/forgot-password')} className="cursor-pointer text-sm font-medium text-themecolor hover:underline dark:text-primary-500"
                    >
                      Forgot password?
                    </a>
                  </div>
                  <div style={{marginTop:15}} className="w-full">
                  <ReCAPTCHA sitekey={G_CAPTCHA_V2_SITE_KEY} onChange={handleVerify} onExpired={handleExpired} size={isMobile()?'compact':'normal'} />
                  </div>
                  <button
                    type="submit"
                    disabled={loading}
                    className="mt-5 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center"
                  >
                    {loading ? (
                      <div className="h-5 w-5">
                        <Loadingicon />
                      </div>
                    ) : (
                      "Sign In"
                    )}
                  </button>
                </form>
              )}
            </Formik>
            <p className="mt-2 mb-0 pt-1 text-sm font-semibold text-center">
              Don't have an account?
              <a onClick={()=>navigate('/register')} className="cursor-pointer text-themecolor transition duration-150 ease-in-out hover:text-danger-600 focus:text-danger-600 active:text-danger-700 ml-1">Register</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
