import React, { useState } from 'react';
import Joyride from 'react-joyride';
import approvedIcon from '../../../assets/images/ico/accept.png';
import rejectedIcon from '../../../assets/images/ico/rejected.png';
import inProgressIcon from '../../../assets/images/ico/in_progress.png';
import availableIcon from '../../../assets/images/ico/available_all.png';
import favouriteIcon from '../../../assets/images/ico/favourite.png';
import creditIcon from '../../../assets/images/ico/coin.png';
import { useAuth } from '../../../Context/AuthContext';

function TourGuideComponent ({ onClose, stepTargets, type, status=null }) {
    const {isHeader, setIsHeader} = useAuth();
   
    const StepContent = ({ htmlContent }) => (
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
    const scrollOffset = 50; 
    const [steps] = useState(type=='myCampaign'?((status==1 || status==4)?[
        // {
        //     scrollOffset:-scrollOffset,
        //     disableBeacon: true,
        //     target:stepTargets[0],
        //     content:<StepContent htmlContent={`<div class="text-left" style="font-size:.9rem;line-height:24px;">As an employer, you'll begin by creating a campaign and keep monitoring its progress 📊. Stay engaged and watch your efforts pay off 🚀</div>`}/>
        // },
        {
            scrollOffset:-scrollOffset,
            disableBeacon:true,
            target: stepTargets[0], // First target class
            content: <StepContent htmlContent={`<div class="text-left" style="font-size:.9rem;line-height:24px;">Add credits now to get started! Campaigns without credits cannot initiate tasks</div>`}/>,
        },{
            scrollOffset:-scrollOffset,
            disableBeacon:true,
            target:stepTargets[1],
            content: <StepContent htmlContent={`<div class="text-left" style="font-size:.9rem;line-height:24px;">Quick Stats to Monitor Campaign Performance. For Details click on each icon.
       </div>`} />
        }
    ]:[
        // {
        //     scrollOffset:-scrollOffset,
        //     disableBeacon: true,
        //     target:stepTargets[0],
        //     content:<StepContent htmlContent={`<div class="text-left" style="font-size:.9rem;line-height:24px;">As an employer, you'll begin by creating a campaign and keep monitoring its progress 📊. Stay engaged and watch your efforts pay off 🚀</div>`}/>
        // },
        {
            scrollOffset:-scrollOffset,
            disableBeacon:true,
            target: stepTargets[0], // First target class
            content: <StepContent htmlContent={`<div class="text-left" style="font-size:.9rem;line-height:24px;">Add credits now to get started! Campaigns without credits cannot initiate tasks</div>`}/>,
        },{
            scrollOffset:-scrollOffset,
            disableBeacon:true,
            target:stepTargets[1],
            content: <StepContent htmlContent={`<div class="text-left" style="font-size:.9rem;line-height:24px;">Quick Stats to Monitor Campaign Performance. For Details click on each icon.
       </div>`} />
        }        ,{
            scrollOffset:-scrollOffset,
            disableBeacon:true,
            target:stepTargets[2],
            content: <StepContent htmlContent={`<div class="text-left" style="font-size:.9rem;line-height:24px;">Enhance the campaign\'s visibility and engagement via Plans / Promotions. For details click on each icon.
            </div>`} />
        }
    ]):type=='campaignInfo'?[
        {
            scrollOffset:-scrollOffset,
            disableBeacon: true,
            target:stepTargets[0],
            content:<StepContent htmlContent={`<div class="text-left" style="font-size:.9rem;line-height:24px;">
            <p class="d-flex align-items-center gap-2 mt-2"><img src=${availableIcon} width="18" height="18" /> All tasks—available</p> <p class="d-flex align-items-center gap-2"><img src=${inProgressIcon} width="18" height="18" /> In Progress</p> 
            <p class="d-flex align-items-center gap-2"><img src=${approvedIcon} width="18" height="18" /> Completed</p> 
            <p class="d-flex align-items-center gap-2"><img src=${rejectedIcon} width="20" height="20" /> Rejected</p></div>`}/>
        }]:type=='myTask'?[
            {
                scrollOffset:-scrollOffset,
                disableBeacon: true,
                target:stepTargets[0],
                content:<StepContent htmlContent={`<div class="text-left" style="font-size:.9rem;line-height:24px;">
                
                <p class="d-flex align-items-start flex-column gap-2"><strong>Submit Proof:</strong>Click to submit your completed tasks with evidence for review.
                </p></div>`}/>
            }]:[
        {
            scrollOffset:-scrollOffset,
            disableBeacon: true,
            target:stepTargets[0],
            content:<StepContent htmlContent={`<div class="text-left" style="font-size:.9rem;line-height:24px;">Employers can now delve into the details of tasks completed by workers on their posted campaigns. 🕵️‍♂️ Explore task specifics, evaluate worker performance, and ensure tasks align with campaign objectives with ease.</div>`}/>
        },
        {
            scrollOffset:-scrollOffset,
            disableBeacon:true,
            target: stepTargets[1], 
            content: <StepContent htmlContent={`<div class="text-left" style="font-size:.9rem;line-height:24px;">Evaluate workers based on their success rate, ⭐️ ratings, and overall performance metrics. Identify top performers, acknowledge their dedication, and foster a productive work environment.</div>`}/>,
        },{
            scrollOffset:-scrollOffset,
            disableBeacon:true,
            target: stepTargets[2], 
            content:<StepContent htmlContent={`<div class="text-left" style="font-size:.9rem;line-height:24px;">Utilize performance metrics to make informed decisions: Accept, reject, or cancel tasks, and provide valuable ratings and reviews to workers. Enhance collaboration and streamline workflows by recognizing excellence and offering constructive feedback</div>`}/>
        }
    ]);

    const handleJoyrideCallback = (data) => {
        const { action, index, type } = data;
        // console.log(data)
        if (type === 'step:before') {
            setIsHeader(false); // Set isHeader to false when a step is active
            const targetElement = document.querySelector(steps[index].target);
            if (targetElement) {
                const offsetTop = targetElement.getBoundingClientRect().top + window.scrollY - scrollOffset;
                window.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth'
                });
            }
        } else if (data?.status === 'finished' || action === 'skip') {
            setIsHeader(true); // Set isHeader back to true when Joyride is finished or skipped
        }
        // if (type === 'step:before') {
            
        // }
    };
    

    return (
        <div>
            {/* <button onClick={onClose}>Close Tour</button> */}
            <Joyride
                steps={steps}
                continuous={true}
                showSkipButton={true}
                showProgress={true}
                autoStart={true}
                // disableScrolling={true}
                callback={handleJoyrideCallback}
                styles={{
                    options: {
                        arrowColor: '#fff',
                        backgroundColor: '#fff',
                        overlayColor: 'rgba(0,0,0, 0.4)',
                        primaryColor: '#ff7200',
                        textColor: '#333',
                        
                        // width: 900,
                        zIndex: 100000000,
                    },
                }}
            />
        </div>
    );
};

export default TourGuideComponent;