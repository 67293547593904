import { NavLink, useLocation, useMatch, useParams } from "react-router-dom";
import { slugToNormal } from "../../utils/utils";

function BreadCrumbs({ username = '' }) {
  const { pathname } = useLocation();

  const { taskCatName, taskSubCatName, slug, cId, mCSlug, tLSlug, fUId, rTSlug, mTSlug, streamId, streamSlug, branchId, branchSlug } = useParams();
  let pathnames = pathname.split("/").filter(Boolean);
  let originalPathnames = pathname.split("/").filter(Boolean);
  let lastPartPathname = '';
  if (taskCatName && taskSubCatName && !slug) {
    lastPartPathname = originalPathnames.pop();
    originalPathnames.pop();
  }

  const { state } = useLocation();

  if (streamId && streamSlug) {
    pathnames.pop();
    pathnames.pop();
    pathnames.push(slugToNormal(streamSlug));
  }
  if (branchId && branchSlug && streamId) {
    pathnames.pop();
    pathnames.pop();
    pathnames.pop();
    pathnames.push(slugToNormal(branchSlug));
  }
  if (taskCatName && taskSubCatName && !slug) {

    pathnames.pop();
    pathnames.pop();
    pathnames.pop();

    const catParts = taskCatName.split('-');
    const subCatParts = taskSubCatName.split('-');
    if (catParts.length > 1) {
      const lastCatPart = catParts[catParts.length - 1];
      if (!isNaN(lastCatPart)) {
        catParts.pop();
      }
      pathnames.push(catParts.join('-'));
    } else {
      pathnames.push(taskCatName);
    }
    if (subCatParts.length > 1) {
      const lastSubCatPart = subCatParts[subCatParts.length - 1];
      if (!isNaN(lastSubCatPart)) {
        subCatParts.pop();
      }
      pathnames.push(subCatParts.join('-'));
    } else {
      pathnames.push(taskSubCatName);
    }
  }

  if (taskCatName && taskSubCatName && slug) {
    const slugPart = pathnames.pop();

    pathnames.pop();
    pathnames.pop();
    pathnames.pop();

    const catParts = taskCatName.split('-');
    const subCatParts = taskSubCatName.split('-');
    if (catParts.length > 1) {
      const lastCatPart = catParts[catParts.length - 1];
      if (!isNaN(lastCatPart)) {
        catParts.pop();
      }
      pathnames.push(catParts.join('-'));
    } else {
      pathnames.push(taskCatName);
    }
    if (subCatParts.length > 1) {
      const lastSubCatPart = subCatParts[subCatParts.length - 1];
      if (!isNaN(lastSubCatPart)) {
        subCatParts.pop();
      }
      pathnames.push('');
      pathnames.push(subCatParts.join('-'));
    } else {
      pathnames.push('');
      pathnames.push(taskSubCatName);
    }

    pathnames.push(slugPart);
  }

  if (taskCatName && !taskSubCatName && !slug) {
    pathnames.pop();
    const catParts = taskCatName.split('-');
    if (catParts.length > 1) {
      const lastPart = catParts[catParts.length - 1];
      if (!isNaN(lastPart)) {
        catParts.pop();
      }
      pathnames.push(catParts.join('-'));
    } else {
      pathnames.push(taskCatName);
    }
  }
  if (cId) {
    pathnames.pop();
  }
  if (mCSlug || tLSlug || mTSlug || rTSlug) {
    pathnames.pop();
    let lstPath = rTSlug ? rTSlug?.split('-') : mTSlug ? mTSlug?.split('-') : tLSlug ? tLSlug?.split('-') : mCSlug?.split('-');
    lstPath.pop();
    pathnames.push(lstPath.join(' '));
  }

  if (fUId) {
    pathnames.pop();
    pathnames.pop();
    const thirdPosVal = pathnames.pop();
    const normalVal = slugToNormal(thirdPosVal) + "'s Campaigns";
    pathnames.push(normalVal);
  }
  const listItems = document.querySelectorAll('.breadcrumb-item');
  // Iterate over each list item
  listItems.forEach(item => {
    // Get the <a> element within the list item
    const link = item.querySelector('a');
    // Check if the <a> element exists and its text content is blank or empty
    if (link && link.textContent.trim() === '') {
      // Hide the list item
      item.style.display = 'none';
    }
  });

  return (
    // <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
    //   <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
    //     <li className={`breadcrumb-item capitalize rest-bread`}>
    //       <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
    //     </li>
    //     {pathnames?.map((item, index) => {
    //       const routeTo = ((taskCatName && taskSubCatName && slug) || (taskCatName && !taskSubCatName && !slug) || (taskCatName && taskSubCatName && lastPartPathname == 'tasks' && !slug)) ? `/${originalPathnames.slice(0, index + 1).join("/")}` : `/${pathnames.slice(0, index + 1).join("/")}`;
    //       return (
    //         <li key={index + "b"} className={`breadcrumb-item capitalize ${pathnames?.length !== index + 1 && "rest-bread"} ${pathnames?.length === index + 1 && "active clamping ellipsis"}`}>
    //           <NavLink to={`${routeTo}`} style={{ fontSize: 14, textDecoration: 'none' }} state={{ ...state }} dangerouslySetInnerHTML={{ __html: decodeURIComponent(item.replace(/-/g, " ")) }}></NavLink>
    //         </li>
    //       );
    //     })}
    //   </ol>
    // </nav>
    <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
      <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
        <li className={`breadcrumb-item capitalize rest-bread`}>
          <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
        </li>
        {pathnames?.map((item, index) => {
          const isLastItem = pathnames.length === index + 1;
          const routeTo = ((taskCatName && taskSubCatName && slug) || 
                            (taskCatName && !taskSubCatName && !slug) || 
                            (taskCatName && taskSubCatName && lastPartPathname === 'tasks' && !slug)) 
                            ? `/${originalPathnames.slice(0, index + 1).join("/")}` 
                            : `/${pathnames.slice(0, index + 1).join("/")}`;

          return (
            <li 
              key={index + "b"} 
              className={`breadcrumb-item capitalize ${!isLastItem && "rest-bread"} ${isLastItem && "active clamping ellipsis"}`}
            >
              {isLastItem ? (
                decodeURIComponent(item.replace(/-/g, " "))
              ) : (
                <NavLink 
                  to={routeTo} 
                  style={{ fontSize: 14, textDecoration: 'none' }} 
                  state={{ ...state }} 
                  dangerouslySetInnerHTML={{ __html: decodeURIComponent(item.replace(/-/g, " ")) }}
                />
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}

export default BreadCrumbs;
