import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserToken } from "../../api/OurService";
import {
  ArrowRighticon,
  QBankIcon,
  IQIcon,
  CNewsIcon,
  FFormIcon,
  DictionaryIcon,
} from "../../AppIcons";
import { useAuth } from "../../Context/AuthContext";
import LoadingScreen from "../common/LoadingScreen";
import Serviceslist from "../common/ServicesList";
import OurServiceHeader from "./shared/OurServiceHeader";

function OurServices() {
  const [loading, setLoading] = useState(false);
  

  return (
    <>
      {/* <div className="pt-10">
        <OurServiceHeader title={"Services"} />
      </div> */}
      {loading ? <LoadingScreen /> : null}
      {/* <div className="py-8 px-6 grid grid-cols-12 accordion-card">
        <Serviceslist viewNmae={"accordian-view"} setLoading={setLoading}/>
      </div> */}
    </>
  );
}

export default OurServices;
