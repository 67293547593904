import { isArray, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import { getFavQuestions } from "../../../api/OurService";
import { Loadingicon } from "../../../AppIcons";
import { useAuth } from "../../../Context/AuthContext";
import BreadCrumbs from "../../common/BreadCrumbs";
import Nodata from "../../common/NoData";

function FavouriteQuestions() {
  const [loading, setLoading] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  
  const { qToken, iQToken, ffToken, dcToken, globalToken } = useAuth();
  const { moduleName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('feedDetail', 'feedDetail');
  }, []);

  useEffect(() => {
    setCurrentPage(1); // Reset currentPage when moduleName changes
    setListData([]);
    getFavQuestionsData();
  }, [moduleName]);

  useEffect(() => {
    if (currentPage > 1) {
      getFavQuestionsData();
    }
  }, [currentPage]);

  const getFavQuestionsData = async () => {
    const token = globalToken;
    // const token = getTokenForModule(moduleName);
    setLoading(true);
    setScrollLoading(true);

    try {
      const res = await getFavQuestions(token, moduleName, currentPage);
      const newData = res?.data?.data || [];
      console.log(newData)
      setListData(prevData => [...prevData, ...newData]);

      if (newData.length < 30) {
        setHasMore(false);
      }
    } catch (error) {
      toast.error(error.message || "Something went wrong!");
    } finally {
      setLoading(false);
      setScrollLoading(false);
    }
  };

  // const getTokenForModule = moduleName => {
  //   switch (moduleName) {
  //     case "question-Bank":
  //       return qToken?.token;
  //     case "fullforms":
  //       return ffToken?.token;
  //     case "dictionary":
  //       return dcToken?.token;
  //     default:
  //       return iQToken?.token;
  //   }
  // };

  const handleListItemLink = (id, isFavouriteByUser, topicID, slug) => {
    const qId = id;
    const topicName = "";
    const isFavourite = isFavouriteByUser === 1;
    const catId = topicID;
    navigate(
      `/${moduleName}/favourite-questions/${slug}`,
      {
        state: {
          qId,
          topicName,
          isFavourite,
          catId,
        },
      }
    );
  };

  // Placeholder function for refreshFunction to satisfy prop requirement
  const refreshFunction = () => {};

  return (
    <>
      <BreadCrumbs />
      <div className="grid grid-cols-12 graph-card-box text-center py-3 px-0">
        <div className="col-span-5 text-card flex items-center">
          <h2
            className="heading-star px-0 flex items-center text-left cursor-pointer"
            style={{ color: "#021442" }}
          >
            Favourite Questions
          </h2>
        </div>
      </div>
      <div className="py-4 px-0 grid grid-cols-1 group-card card-body gap-4">
        {loading ? (
          <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
            <div className="h-10 w-10">
              <Loadingicon />
            </div>
          </div>
        )
        : isEmpty(listData) ? (
          <div className="flex justify-center mt-5">
            <Nodata />
          </div>
        ) : (
          <div className="col-span-9 py-2 w-full card-body border-none child-menu">
            <ol className="child-content pl-3">
              <InfiniteScroll
                dataLength={listData.length}
                next={() => setCurrentPage(prevPage => prevPage + 1)}
                hasMore={hasMore}
                loader={scrollLoading && <div className="ml-5 text-themecolor">Loading...</div>}
                scrollableTarget="qlist"
                pullDownToRefresh
                pullDownToRefreshThreshold={90}
                refreshFunction={refreshFunction} // Added placeholder refreshFunction
              >
                <div style={{ overflow: "auto" }} id="qlist">
                  {isArray(listData) &&
                    listData.map((item, index) => (
                      <li
                        className={`item-list ${index === 0 && "active"} cursor-pointer`}
                        onClick={() => handleListItemLink(item.question.id, item.favourite, item.question.topic_id, item.question.slug)}
                        key={item.question.id}
                      >
                        <div className="number-cy">
                          <span>{index + 1}</span>
                        </div>
                        {item.question.question}
                      </li>
                    ))}
                </div>
              </InfiniteScroll>
            </ol>
          </div>
        )}
      </div>
    </>
  );
}

export default FavouriteQuestions;
