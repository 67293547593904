import React from 'react'
import { CDN_BASE_URL, convertDateFormat, convertDateFormatOnly, limitSubtitle, nFormatter, stripTags } from '../../../../utils/utils';
import careerThumbPlaceholder from '../../../../assets/images/feeds_default_placeholder.webp';
import { Link, useNavigate } from 'react-router-dom';
import lyricsThumbPlaceholder from '../../../../assets/images/lyric_image_placeholder.webp';

export default function LList({ lyric, thumbPrefix='showtalent/images/lyrics/category/thumbs/', redirectionFn, type }) {

    const navigation = useNavigate();
    return (
        <div className={`d-flex w-100 hover-div flex-md-row flex-column justify-content-between align-items-center list-alter  lafter mb-2 py-2 pr-3 position-relative overflow-hidden cursor-pointer`}  onClick={(e) => {e.preventDefault();e.stopPropagation();redirectionFn(e)}} style={{ border: '1px solid #f1e4e4', borderLeft: '3px solid #ccc' }}>

            <div className="d-flex align-items-start p-md-2 pe-0 pe-md-2 py-md-2 py-2 px-2 w-100 w-md-auto ">

                <div className="thumbnail-containers border-0 mr-3">
                    {
                        lyric?.image_url ?
                        <img
                        src={
                            lyricsThumbPlaceholder
                        }
                        width="60"
                        height="60"
                        className="rounded bg-none border-0 qThumbs me-md-3 opacity-05"
                        alt="Thumbnail"
                    />
                            : <img src={`${lyricsThumbPlaceholder}`} width="90" height="90" className="rounded bg-gray" style={{ border: '1px solid #f1e4e4' }} />
                    }

                </div>

                <div className="flex-1">
                    <h6 className="mb-0 justify-content-between d-md-block d-flex align-items-start w-100 mt-0 text-capitalize title-task color-333">
                        <span className="theme-a cursor-pointer d-flex align-items-center gap-2 pr-md-0 pr-5 lh-1p6"><strong>{stripTags(lyric?.title)}</strong></span>
                    </h6>
                    <p class="mb-0 mt-1 color-gray fsize-meta desc-camp">{limitSubtitle(stripTags(lyric?.lyrics), 290)}</p>
                    <div className="d-flex  mt-2 w-100 gap-2 align-items-center mw-200">
                        {
                            type=='lByLyricist'?<>
                                 <Link onClick={(e)=>{e.stopPropagation();e.preventDefault();navigation(`/lyrics/artist/${lyric?.writer?.id}/${lyric?.writer?.slug}`)}} className=" hover-theme text-black-50">
                            
                            <div className="user-pic">
                                {
                                    lyric?.writer?.image && lyric?.writer?.image !== '' ? 
                                    <img src={`${CDN_BASE_URL + 'showtalent/images/lyrics/lyricists/thumbs/' + lyric?.writer?.image}`} width={25} height={25} style={{borderRadius:100,height:25}}/>
                                        :
                                        <img src={require('../../../../assets/images/placeholder_big.png')} width={25} height={25} />
                                }
                            </div>
                        </Link>
                        <div className='flex-1'>
                            <small className="m-0">
                                <Link onClick={(e)=>{e.stopPropagation();e.preventDefault();navigation(`/lyrics/artist/${lyric?.writer?.id}/${lyric?.writer?.slug}`)}} className=" hover-theme text-black-50">
                                    <em>by </em>
                                    <strong className="text-capitalize ">{lyric?.writer?.name}</strong>
                                </Link>
                            </small>
                            {/* </a> */}
                        </div>
                            </>:<>
                             <Link onClick={(e)=>{e.stopPropagation();e.preventDefault();navigation(`/lyrics/artist/${lyric?.artist?.id}/${lyric?.artist?.slug}`)}} className=" hover-theme text-black-50">
                            
                            <div className="user-pic">
                                {
                                    lyric?.artist?.image && lyric?.artist?.image !== '' ? 
                                    <img src={`${CDN_BASE_URL + 'showtalent/images/lyrics/artists/thumbs/' + lyric?.artist?.image}`} width={25} height={25} style={{borderRadius:100,height:25}}/>
                                        :
                                        <img src={require('../../../../assets/images/placeholder_big.png')} width={25} height={25} />
                                }
                            </div>
                        </Link>
                        <div className='flex-1'>
                            <small className="m-0">
                                <Link onClick={(e)=>{e.stopPropagation();e.preventDefault();navigation(`/lyrics/artist/${lyric?.artist?.id}/${lyric?.artist?.slug}`)}} className=" hover-theme text-black-50">
                                    <em>by </em>
                                    <strong className="text-capitalize ">{lyric?.artist?.name}</strong>
                                </Link>
                            </small>
                            {/* </a> */}
                        </div></>
                        }
                       
                    </div>
                </div>
            </div>
        </div>
    )
}
