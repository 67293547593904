import React, { useState } from 'react';

function SectionDescription ({ title, subTitle }) {  

    return (
        <div>
            <h5 className='mt-3 mb-1 text-capitalize' dangerouslySetInnerHTML={{__html:`${title}`}}></h5>
            <p className='color-gray mb-0' dangerouslySetInnerHTML={{__html:`${subTitle}`}}></p>
        </div>
    );
};

export default SectionDescription;