import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllSurveys, initiatedSurveys } from "../../../api/OurService";
import notFound from '../../../assets/images/not_found_new.png';
import * as Yup from "yup";
import { Loadingicon } from "../../../AppIcons";
import BreadCrumbs from "../../common/BreadCrumbs";
import { useAuth } from "../../../Context/AuthContext";

function MySurveys() {
    const [loading, setLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [pagNoOffer, setPagNoOffer] = useState(1);
    const { user } = useAuth();
    const navigation = useLocation();
    const navigate = useNavigate();
    const [allSurveys, setAllSurveys] = useState();
    const [isLoadMore, setIsLoadMore] = useState(true);
    
    useEffect(() => {
        fetchAllSurveys(pagNoOffer, 'full');
    }, [])

    
    const loadMoreOffer = ()=>{

        // const nxtPage = pagNoOffer + 1;
        // let fParams = `app_id=${CPX_SURVEY_APP_ID}&ext_user_id=${user?.tuteehub_id}&limit=${pagNoOffer * showRecord}&output_method=api&ip_user=${clientIp}&subid_1=${user?.tuteehub_id}&subid_2=${'tuteehub'}`;
        // fetchAllSurveys(fParams, nxtPage);            
        //     setPagNoOffer(nxtPage)
    }




    const fetchAllSurveys = async (pNo, type) => {
        if (type == 'full')
            setLoading(true)
        if (type !== 'full')
            setBtnLoading(true)
        const allSurveysRes = await initiatedSurveys({ token: user?.access_token, pNo:pNo });
        //

        if (allSurveysRes.success) {
            // console.log(allSurveysRes)
            if(allSurveysRes?.data?.next_page_url){
                setIsLoadMore(true);
               }else{
                setIsLoadMore(false)
               }
            if(pNo==1){
                console.log(allSurveysRes)
            setAllSurveys(allSurveysRes);   
            }else{
                setAllSurveys({
                    ...allSurveys,
                    data: {...allSurveys?.data, data:[...allSurveys.data?.data, ...allSurveysRes.data.data]}
                }); 
            }      
        } else {
            setAllSurveys(null);
            setIsNotFound(true)
        }
        setBtnLoading(false)
        setLoading(false)
    }

    const isMobileDevice = () => {
        const mediaQuery = window.matchMedia('(max-width: 767px)');
        return mediaQuery.matches;
    }

    


    return (
        <>
            <div className="d-flex ">
            <BreadCrumbs />
            </div>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css" />

            <div className=" py-4 px-3 grid grid-cols-1 group-card card-body gap-1">
                {loading && !isNotFound ? (
                    <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                        <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                            <div className="h-10 w-10">
                                <Loadingicon />
                            </div>
                        </div>
                    </div>) : !loading && isNotFound ? (
                        <div className="py-4 px-2 px-md-3 grid grid-cols-1 group-card card-body gap-1">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="vdo-module-notfound">
                                    <img src={notFound} className="notFoundImg" />
                                    <p className="color-gray text-center">No survey found</p>
                                </div>
                            </div>
                        </div>) : (
                    <>
                   
                        {
                            allSurveys?.data?.data?.length > 0 ?
                                allSurveys?.data?.data?.map((survey, index) => <>
{
    isMobileDevice()?<div className="d-md-none d-block  list-alter my-2 px-3 py-3">
    <div className="d-flex justify-content-between mb-4">
        <div className="thumbnail-containers mr-3">
            <div  style={{ background: 'rgb(167 199 252)', padding: '4px', borderRadius: '4px' }}>
            <img src={survey?.image} style={{minWidth:140}} className="rounded" />
            </div>                    
        </div>
        <div className="d-flex align-items-center gap-1" style={{gap:'4px'}}>         
                <h5 className="mb-0 mt-0 text-capitalize title-task color-333">
                <strong>{survey?.min_length}</strong> </h5>
                <small className="text-black-50">Minutes</small>
        </div>  
        {/* <div className="d-flex align-items-center gap-1" style={{gap:'4px'}}>
            <h5 className="mb-0 mt-0 text-capitalize title-task color-333">
                <strong className="pr">{survey?.credits > 0? Math.floor(survey?.credits): 0}</strong> </h5>
                <small  className="text-black-50">Credits</small>
        </div> */}
    </div>
    <div className="d-flex justify-content-between w-100 align-items-center">
        <div className="d-flex align-items-center gap-1" style={{gap:'4px'}}>
            <h5 className="mb-0 mt-0 text-capitalize title-task color-333">
                <strong className="pr">{survey?.credits > 0? Math.floor(survey?.credits): 0}</strong> </h5>
                <small  className="text-black-50">Credits</small>
        </div>
        {
        survey?.status==0?<div className="label label-warning">Pending</div>:<div className="label label-success">Completed</div>
    }
    </div>
</div>:<div className="d-flex w-100 flex-md-row flex-column justify-content-between py-2 align-items-center list-alter pr-md-4 pr-3"><div className="d-flex align-items-start p-md-2 py-md-2 py-2 px-2 w-md-auto" key={index}>

    <div className="thumbnail-containers mr-3">
            <div  style={{background: 'rgb(167 199 252)',padding:'4px'}} className="rounded">
                <img src={survey?.image} width={168}  />
                </div>
               
                
    </div>
    <div className="flex-1">
    
        {/* <small className="text-black-50 text-capitalize">{survey?.type}</small>
        <h6 className="mb-0 mt-0 text-capitalize title-task color-333">
            <a href="javascript:;"><strong>{survey?.payout > 0? Math.floor(survey?.payout): 0}</strong> <small style={{fontSize:'.9rem'}}>Credits</small></a></h6> */}
        {/* <p className="mb-2 color-gray fsize-meta">
        <div className="d-flex align-items-center survey-rating mt-md-0 mt-2">
            <div className="d-flex align-items-center survey-rating">
                <i className="fa fa-star mr-2"></i>
                <i className="fa fa-star mr-2"></i>
                <i className="fa fa-star mr-2"></i>
                <i className="fa fa-star mr-2"></i>
                <i className="fa fa-star mr-2"></i>
            </div>
            <span>({survey?.statistics_rating_count} <span className="">Rating</span>)</span>
        </div>
        </p> */}
        {/* <p className="mb-2 text-black-50 fsize-meta">
    <span className="metas"> <Link to={`/`} className="a-hover">Catname</Link></span><span className="sep">|</span><span className="metas"> <a style={{ cursor: 'pointer' }} onClick={() => alert('')} className="a-hover">Subcat Name</a></span><span className="sep">|</span>
    <span className="metas">{'12/04/2024'}</span>
    
    </p>                            */}
    
    
        {/* <div className="w-100 text-right mt-3"> */}
    
        {/* <StartTaskButton taskId={task?.id} />                                        */}
        {/* </div> */}
    </div>
    
    
    </div>
    <div className="text-center d-flex flex-column">
    <h5 className="mb-0 mt-0 text-capitalize title-task color-333">
            <strong className="pr">{survey?.credits > 0? Math.floor(survey?.credits): 0}</strong> </h5>
            <small style={{fontSize:'.7rem'}} className="text-black-50">Credits</small>
    </div>
    
    <div className="text-center d-flex flex-column">         
    <h5 className="mb-0 mt-0 text-capitalize title-task color-333">
            <strong>{survey?.min_length}</strong> </h5>
            <small style={{fontSize:'.7rem'}} className="text-black-50">Length(Minutes)</small>
    </div>            
    <div className="text-right d-flex mb-md-0 mb-3  align-items-center justify-content-end w-md-auto w-full" >
        {/* <div className=" me-3 me-md-4">
    <h5 className="mb-0" style={{ color: "#3d3d3d" }}><strong>{offer?.payout_currency} {offer?.amount}</strong></h5>
    <small className="text-black-50 no-wrap">Payout</small>
    </div> */}
     
        
     {
        survey?.status==0?<div className="label label-warning">Pending</div>:<div className="label label-success">Completed</div>
    }
    
    
    </div>
    </div>
}


                                    {/* <div className="card border rounded mb-3 shadow overflow-hidden">
                                        <div className="card-header px-3 survey-card-header">
                                            <div className="d-flex align-items-md-center gap-1 align-items-start flex-md-row flex-column justify-content-between  survey-heading-container">
                                                <h6 className="m-md-0 mb-1 text-capitalize">{survey?.type} - #{survey?.id}</h6>
                                                <div className="d-flex align-items-center survey-rating mt-md-0 mt-2">
                                                    <div className="d-flex align-items-center survey-rating">
                                                        <i className="fa fa-star mr-2"></i>
                                                        <i className="fa fa-star mr-2"></i>
                                                        <i className="fa fa-star mr-2"></i>
                                                        <i className="fa fa-star mr-2"></i>
                                                        <i className="fa fa-star mr-2"></i>
                                                    </div>
                                                    <span>({survey?.statistics_rating_count} <span className="">Rating</span>)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body py-4 my-1">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4 className="m-0"><strong>{survey?.payout > 0? Math.floor(survey?.payout): 0}</strong> <small style={{fontSize:'.9rem'}}>Credits</small></h4>
                                                <div className="d-flex align-items-center gap-1">
                                                    <h6 className="m-0"> <i className="fa fa-clock"></i> ~ {survey?.loi} Min</h6>
                                                    <a type="button" target="_blank" href={survey?.href_new} className="btn-1 d-md-inline-block d-none btn survey-btn text-white">Start Survey</a>
                                                </div>
                                            </div>
                                            <a type="button" target="_blank" href={survey?.href_new} className="btn-1 d-md-none d-block float-right btn survey-btn text-white mt-4">Start Survey</a>
                                        </div>
                                    </div> */}
                                </>
                                ) : <div className="vdo-module-notfound">
                                    <img src={notFound} className="notFoundImg" />
                                    <p className="color-gray text-center">No survey found</p>
                                </div>
                        }
                        {
                            allSurveys?.data?.data >= 20 && isLoadMore ? <button type="button" disabled={btnLoading} onClick={() => loadMoreOffer()} className={`d-block text-center mt-4 btn text-white ${btnLoading ? 'btn-secondary' : ''} load-more-custom`}>
                                {btnLoading ? (
                                    <div className="h-5 w-5 m-auto">
                                        <Loadingicon />
                                    </div>
                                ) : (
                                    "Load More"
                                )}
                            </button> : null
                        }

                    </>
                )
                }
            </div>


        </>
    );
}
export default MySurveys;
