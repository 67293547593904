import { useEffect, useState } from "react";
import {changePassword, getUserInfo } from "../../../api/OurService";

import * as Yup from "yup";
import { Loadingicon } from "../../../AppIcons";
import BreadCrumbs from "../../common/BreadCrumbs";
import { Formik } from "formik";
import { useAuth } from "../../../Context/AuthContext";
import { Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import pending from '../../../assets/images/clock.png';
import verified from '../../../assets/images/tick-mark.png';
import { onProfileVerification, onProfileVerificationStatus } from "../../../api/AuthService";
function ProfileVerification() {
    const [loading, setLoading] = useState(true);
    const [btnLoader, setBtnLoader] = useState(false);
    const [isProfileVerified, setIsProfileVerified] = useState();

    const DisplayingErrorMessagesSchema = Yup.object().shape({
        selfie: Yup.string().required("Selfie Photo is required"),
        id_photo: Yup.string().required("ID Photo is required")
    });
    const { user, globalToken } = useAuth();
    const [selfieImg, setSelfieImg] = useState();
    const [idImg, setIdImg] = useState();

    const verificationStatus =[
        {
            label:'No Rquestion',
            value:0,
            message:'Your profile verification request has not been sent.'

        },{
            label:'Verified',
            value:1,
            message:'Your profile has been verified.'
        },{
            label:'Pending',
            value:2,
            message:'Your profile verification request is pending.'
        },{
            label:'Rejected',
            value:3,
            message:'Your profile verification request has been rejected.'
        }
    ]
    const handleProfileVerification = async (values) => {  
        setBtnLoader(true)    
        const res = await onProfileVerification({...values, token:globalToken});   
       
        if(res?.success){
            handleProfileVerificationStatus();
            toast.success(res?.message);
        }else{
            toast.error(res?.message) 
        }
        // if(res.success)
        // setUserDetails(res?.data)
        setBtnLoader(false)    
    }


    const handleProfileVerificationStatus = async () => {  
        // setBtnLoader(true)    
        const res = await onProfileVerificationStatus({token:globalToken}); 
         
        if(res?.success){
            setIsProfileVerified(verificationStatus[res?.data?.status])
        }else{
            // toast.error(res?.message) 
        }
        setLoading(false)  
    }

    useEffect(()=>{
        handleProfileVerificationStatus();
    }, [])

    const handleFileChange = (field, event, setFieldValue) => {
        const file = event.target.files[0];
    
        if (file) {
          const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    
          if (allowedTypes.includes(file.type)) {
            setFieldValue(field, file);
          } else {
            event.currentTarget.value = '';
            alert('Please choose a valid image file.');
          }
        }
      };
  

    const formData = [
        {
            id: 'id_type',
            title: 'ID Type',
            type: 'text',
            name: 'id_type',
            placeholder: 'Id Type',
            required: true,
        },
        {
            id: 'id_photo',
            title: 'ID Photo',
            type: 'file',
            name: 'id_photo',
            placeholder: 'Id Photo',
            required: true,
        },
        {
            id: 'selfie',
            title: 'Selfie Photo',
            type: 'file',
            name: 'selfie',
            placeholder: 'Selfie Photo',
            required: true,
        }
    ]
    return (
        <>
            <BreadCrumbs />
          
            <div className=" py-4 px-0 grid grid-cols-1 group-card card-body gap-4" style={{minHeight:660}}>
                {loading ? (
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                ) : (
                    <>
                    {
                        isProfileVerified?.value==1 || isProfileVerified?.value==2 || isProfileVerified?.value==3?<div className="mx-3 w-100 text-center">
                        {
                        isProfileVerified?.value==1?<h6 className="w-100 text text-success d-flex flex-column align-items-center py-md-5 my-md-5 mx-auto gap-4">
                            <span><img src={verified} width={60} style={{opacity:0.7}}/></span>
                            {isProfileVerified?.message}</h6>
                        :isProfileVerified?.value==2?<h6 className="w-100 text text-warning d-flex flex-column align-items-center py-md-5 my-md-5 mx-auto gap-4">
                            <span><img src={pending} width={60} style={{opacity:0.7}}/></span>
                            {isProfileVerified?.message}</h6>
                        :isProfileVerified?.value==3?<span className="w-100 text text-danger">{isProfileVerified?.message}</span>:null
                        }
                        </div>:null
                    }
                    {
                        isProfileVerified?.value==3 || isProfileVerified?.value==0?
                        <div className="px-3">
                            <div className="row">
                               
                                <div className="col-md-9">
                                            <Formik    
                                                initialValues={{
                                                    id_type: 'Aadhaar',
                                                    id_photo: '',
                                                    selfie: '',
                                                  }}                                           
                                                validationSchema={DisplayingErrorMessagesSchema}
                                                onSubmit={(values) => {
                                                   handleProfileVerification(values)
                                                }}
                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    setFieldValue,
                                                    handleSubmit,
                                                }) => (
                                                    <form className="" onSubmit={handleSubmit}>
                                                        <div className="row">
                                                            {
                                                                formData?.map(fieldMeta => (
                                                                    <div className={`${fieldMeta.width ? 'col-md-12' : 'col-md-12'}`}>
                                                                        <label
                                                                            htmlFor={fieldMeta?.id}
                                                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                                                                        >
                                                                            {fieldMeta?.title}

                                                                            {
                                                                                fieldMeta?.required ? <span className="text-red-400 text-base">*</span> : null
                                                                            }
                                                                        </label>
                                                                        <input type="password" name="fake-password" autoComplete="new-password" style={{ display: 'none' }} />
                                                                        {
                                                                           fieldMeta?.id=="id_type"?
                                                                           <select name="id_type" onChange={handleChange} onBlur={handleBlur} className="mb-4 form-controls bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                                                                            <option value="Aadhaar">Aadhaar</option>
                                                                            <option value="Voter Card">Voter Card</option>
                                                                            <option value="Pan Card">Pan Card</option>
                                                                            <option value="Passport">Passport</option>
                                                                           </select>
                                                                           : <>
                                                                                       <div className="position-relative"> 
                                                                                        <input
                                                                                            onChange={(e) => handleFileChange(fieldMeta.name, e, setFieldValue)}
                                                                                            type={fieldMeta?.type}
                                                                                            name={fieldMeta?.name}
                                                                                            autoComplete={'off'}
                                                                                            id={fieldMeta?.name}
                                                                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                                                            placeholder={fieldMeta?.title}
                                                                                            // onChange={handleChange}
                                                                                            onBlur={handleBlur}
                                                                                            // value={values[fieldMeta?.name]}
                                                                                        />
                                                                                       
                                                                                        </div>
                                                                                        <small className="text-black-50 mt-1 d-block">Note: Allowed file formats: JPEG, JPG, PNG</small>

                                                                                        <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                            {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                    </div>
                                                                ))
                                                            }
                                                            <div className="col-md-2">
                                                                <button
                                                                type="submit"
                                                                disabled={btnLoader}
                                                                className="mt-2 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center"
                                                                >
                                                                {btnLoader ? (
                                                                    <div className="h-5 w-5">
                                                                    <Loadingicon />
                                                                    </div>
                                                                ) : (
                                                                    "Submit"
                                                                )}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                )}

                                        </Formik>
                                </div>
                            </div>
                        </div>:null
                }
                    </>
                )
                }
            </div>
        </>
    );
}
export default ProfileVerification;
