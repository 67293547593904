import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RedirectApi } from "../../api/AuthService";
import { useAuth } from "../../Context/AuthContext";
import { useVerifyUserStatus } from "../../Context/VerifyStatusContext";
import { useFeeds } from "../../Context/FeedsContext";
import { toast } from "react-toastify";
import { PostData } from "../../api/feeds/PostData";
import CryptoJS, { AES } from 'crypto-js';


function Redirection() {
    const { user, setUser } = useAuth();
    const { setIsEmailVerified } = useVerifyUserStatus();
    // const { feedsToken } = useFeeds();
  const [loading, setLoading] = useState(true);
  const { thid } = useParams();
  const navigate = useNavigate();

  /* const feedsToken = async (tuteehubId) => {
    // const res = await PostData('api/fd/user/api-token', 'normal', { tuteehubId: thid });

    // localStorage.setItem("___fTkn", res?.data?.token);
  } */
  useEffect(() => {
    handleRedirect();
   
  }, []);

  const decryptId = () =>{
   
    const splitencryptedData = thid.split('__rxd');
    const keyHex = "743665d963c43b5b68a295a66c4013ab";
    
    // Extract IV from the end of thId
    const ivHex = splitencryptedData[1].substring(splitencryptedData[1].length - 32);
    const encryptedData = splitencryptedData[0];
    
    // Convert hex-encoded key and IV to WordArray
    const key = CryptoJS.enc.Hex.parse(keyHex);
    const iv = CryptoJS.enc.Hex.parse(ivHex);
    
    // Replace "__23" with '/'
    const encryptedDataWithSlash = encryptedData.replace(/__23/g, '/');
    
    // Decrypt using AES-128-CBC with PKCS7 padding
    const decrypted = CryptoJS.AES.decrypt(encryptedDataWithSlash, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    
    // Get the decrypted data as a string
    const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
    
    return decryptedData;
  }
  
  const handleRedirect = async () =>{
   
        setLoading(true);
        try {
          const response = await RedirectApi({tuteehub_id: decryptId()});
          if (response.data.status === 1) {
            setUser(response.data.data);
           
            setIsEmailVerified(response.data.data?.email_verified);
            // feedsToken(response.data.data?.tuteehub_id)
			      setUser(response.data.data);
            // localStorage.setItem("userDetails", JSON.stringify(response.data.data));
			      // setIsEVerified(response.data.data?.email_verified);
            // localStorage.setItem('ev_______', response.data.data?.email_verified);    
            navigate("/");
          } else {
            navigate("/")
          }
        } catch (err) {
          toast.error(err.message || "Something went wrong!");
        } finally {
          setLoading(false);
        }
      
  }

  return (
    <div>
      {loading ? (
        <div className="loader loader-redirect">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        <div>
          {/* Your content when loading is false */}
        </div>
      )}
    </div>
  );
}

export default Redirection;
