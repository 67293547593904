import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import { followProfile, getAccountDetail, getAllCountries, getAllOffers, getFollowersList, getFollowingList, getOfferConfig, getPlansList, getPublicUserProfile, getUserInfo, initiateOffer, initiatedOffers, onAdduserDetails, onUpdateuserDetails, rejectFollower, unFollowProfile } from "../../../api/OurService";
import notFound from '../../../assets/images/not_found_new.png';
import premiumVideo from '../../../assets/images/premium_banner_1.png';
import premiumPhoto from '../../../assets/images/premium_banner_2.png';
import premiumEngagement from '../../../assets/images/premium_banner_3.png';
import * as Yup from "yup";
import { Loadingicon } from "../../../AppIcons";
import BreadCrumbs from "../../common/BreadCrumbs";
import { Formik } from "formik";
import { useAuth } from "../../../Context/AuthContext";

import { Card } from "react-bootstrap";
import { CardContent, CircularProgress, IconButton } from "@material-ui/core";
import PhoneNumberInput from "../../common/phonenumberinput";
import { CDN_BASE_URL, CPA_LEAD_OFFERWALL_ID, SHOWTALENT_APP_DEEP_LINK, convertDateFormat, formatDate, getCountryName, getParams, getRandomSmoothColor, lightenColor, limitSubtitle, nFormatter, stripHtmlTags } from "../../../utils/utils";
import { userProfileUpdate } from "../../../api/AuthService";
import { LocalOfferOutlined } from "@material-ui/icons";
import CustomSelect from "../../common/customselect";
import CustomTab from "../../customtab";
function MyOffers() {
    const [loading, setLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [pagNoOffer,setPagNoOffer] = useState(1);
    const { user } = useAuth();
    const navigate = useNavigate();
    const [allOffers, setAllOffers] = useState();
    const [offerConfig, setOfferConfig] = useState();
    const [isLoadMore, setIsLoadMore] = useState(true);
   
    
    useEffect(() => {      
        fetchAllOffers(pagNoOffer , 'full');
    }, []);

    const loadMoreOffer = ()=>{
            const nxtPage = pagNoOffer + 1;     
            fetchAllOffers(nxtPage, 'full');            
            setPagNoOffer(nxtPage);
    }

    

    const fetchAllOffers = async (pNo, type) => {
        if(type=='full')
        setBtnLoading(true)
        if(type!=='full')
        setBtnLoading(true)
        const allOffersRes = await initiatedOffers({ token: user?.access_token, pNo:pNo });
       

        if (allOffersRes.success) {
            if(allOffersRes?.data?.next_page_url){
                setIsLoadMore(true);
               }else{
                setIsLoadMore(false)
               }
            if(pNo==1){
            setAllOffers(allOffersRes);   
            }else{
                setAllOffers({
                    ...allOffers,
                    data: {...allOffers?.data, data:[...allOffers.data?.data, ...allOffersRes.data.data]}
                }); 
            }      
        //    if(type!=='full' && allOffersRes?.number_offers < type * showRecord){
        //     setIsLoadMore(false)
        //    }else{
        //     setIsLoadMore(true)
        //    }
        //     if (allOffersRes?.number_offers <= 0) {
        //         setIsNotFound(true)
        //     } else {
        //         setIsNotFound(false)
        //     }
        } else {
            setAllOffers(null);
            setIsNotFound(true)
        }
        setBtnLoading(false)
        setLoading(false)
    }

   
   

    return (
        <>
            <div className="d-flex align-items-center flex-md-row flex-column justify-content-between">
            <BreadCrumbs />
           </div>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css" />
            <div className="py-4 px-3 grid grid-cols-1 group-card card-body gap-1">
                {loading && !isNotFound ? (
                    <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                        <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                            <div className="h-10 w-10">
                                <Loadingicon />
                            </div>
                        </div>
                    </div>) : !loading && isNotFound ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="vdo-module-notfound">
                                    <img src={notFound} className="notFoundImg" />
                                    <p className="color-gray text-center">No offer found</p>
                                </div>
                            </div>
                        </div>) : (
                    <>
                    
                        {
                            allOffers?.data?.data?.length > 0 ?
                                allOffers?.data?.data?.map((offer, index) => <><div className="d-flex w-100 flex-md-row flex-column justify-content-between align-items-center list-alter pr-md-4 pr-3"><div className="d-flex align-items-start p-md-2 py-md-2 py-2 px-2 w-100 w-md-auto" key={index}>

                                    <div className="thumbnail-container mr-3">
                                        {
                                            offer?.image ?
                                                <img src={offer?.image} width="70" height="70" className="rounded" />
                                                :
                                                <span className="placeholder-thumbnail">
                                                    <svg className="MuiSvgIcon-root ms-0" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="button" style={{ height: 58 }}>
                                                        <path d="M19 5v14H5V5h14m1.1-2H3.9c-.5 0-.9.4-.9.9v16.2c0 .4.4.9.9.9h16.2c.4 0 .9-.5.9-.9V3.9c0-.5-.5-.9-.9-.9zM11 7h6v2h-6V7zm0 4h6v2h-6v-2zm0 4h6v2h-6zM7 7h2v2H7zm0 4h2v2H7zm0 4h2v2H7z"></path>
                                                    </svg>
                                                </span>
                                        }
                                    </div>
                                    <div className="flex-1">
                                        {/* <small className="text-black-50 text-capitalize">{offer?.category}</small> */}
                                        <h6 className="mb-0 mt-0 text-capitalize title-task color-333">
                                            <a>{offer?.campaign_name}</a></h6>
                                        <p className="mb-0 color-gray fsize-meta">
                                        {limitSubtitle(stripHtmlTags(offer?.description), 200)}
                                        </p>
                                        <p className="text-black-50 text-capitalize mb-0">                                            
                                        <small>Offer ID:<strong> #{offer?.campaign_id}</strong></small>
                                            <span className="big-dots"></span>
                                            <small>Category:<strong> {offer?.category}</strong></small>
                                            <span className="big-dots"></span>
                                            <small>Initiated On: <strong>{convertDateFormat(offer?.updated_at)}</strong></small>
                                            <span className="big-dots"></span>
                                            <small>Credits:<strong className="pr"> {offer?.credits}</strong></small>

                                        </p>
                                     
                                    </div>

                                </div>
                                

                                    <div className="text-right d-flex mb-md-0 mb-3  align-items-center justify-content-end w-md-auto w-full" >
                                                {
                                                    !offer?.status?<div className="label label-warning">Pending</div>:<div className="label label-success">Completed</div>
                                                }
                                                

                                    </div>
                                </div>
                           
                            </>
                            ) : <div className="vdo-module-notfound">
                                    <img src={notFound} className="notFoundImg" />
                                    <p className="color-gray text-center">No offer found</p>
                                </div>
                        }
                        {
                            allOffers?.data?.next_page_url && isLoadMore?<button type="button" disabled={btnLoading} onClick={()=>loadMoreOffer()} className={`d-block text-center mt-4 btn text-white ${btnLoading?'btn-secondary':''} load-more-custom`}>
                            {btnLoading ? (
                                    <div className="h-5 w-5 m-auto">
                                        <Loadingicon />
                                    </div>
                                ) : (
                                    "Load More"
                                )}
                            </button>:null
                        }
                        
                    </>
                )
                }
            </div>

        </>
    );
}
export default MyOffers;
