import { FEEDS_API_Basepath } from "../../utils/utils";

export function PostData(url, type, data) {

  return new Promise((resolve, reject) => {
    fetch(type==="showtalentVdo"?`https://showtalent.tuteehub.com/api/v2/services?method=get_recent_video`:type==="showtalent"?'https://showtalent.tuteehub.com/api/v2/services?method=video_with_category':FEEDS_API_Basepath + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
