import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UseLocalStorage from "../Hooks/useLocalStorage";

const FeedsContext = React.createContext({});

const FeedProvider = (props) => {
  const navigate = useNavigate();
  const [feeds, setFeeds] = useState();
  const [myFav, setMyFav] = useState(false);
  const [modalOpen,setModalOpen] = useState(false);
  const [myFeeds,setMyFeeds] = useState(false);
  const [search, setSearch] = useState(false);
  const [feedDetail, setFeedDetail] = useState();
  const [loading, setLoading] = useState(true);

  const feedsContextValue = {
    feeds,
    setFeeds,
    myFav,
    setMyFav,
    modalOpen,
    setModalOpen,
    setMyFeeds,
    myFeeds,
    search,
    setSearch,
    setFeedDetail,
    feedDetail,
    loading,
    setLoading
  };

  return <FeedsContext.Provider value={feedsContextValue} {...props} />;
};

const useFeeds = () => useContext(FeedsContext);

export { FeedProvider, useFeeds };
