import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { useInView } from 'react-intersection-observer';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import CircularProgress from '@material-ui/core/CircularProgress';

const FeedVideo = ({ url }) => {
  const playerRef = useRef(null);
  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const [isPlaying, setIsPlaying] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [isSeekbar, setIsSeekbar] = useState(false);
  const [played, setPlayed] = useState(0);
  const [buffering, setBuffering] = useState(false);

  useEffect(() => {
    if (!inView && isPlaying) {
      playerRef.current.seekTo(0);
      setIsPlaying(false);
      setShowControls(true)
    }
    if(inView && !isPlaying){
        setIsSeekbar(false)
    }
  }, [inView, isPlaying]);

  const handleVideoClick = () => {
    setShowControls(!showControls);
  };

  const handlePlayPause = (event) => {
    if(!isPlaying){
        setIsSeekbar(true)
        setTimeout(() => { setShowControls(false); }, 700)
        
    }
    event.stopPropagation();  // Prevent the click event from bubbling up to the video container
    setIsPlaying(!isPlaying);
  };

  const handleProgress = (state) => {
    setPlayed(state.played);
  };

  const handleSeekChange = (event) => {
    const seekTo = parseFloat(event.target.value);
    playerRef.current.seekTo(seekTo);
    setPlayed(seekTo);
  };

  const handleBuffer = () => {
    // setShowControls(true);
    // setIsSeekbar(false);
    setBuffering(true);
  };

  const handleBufferEnd = () => {
    // setShowControls(false);
    // setIsSeekbar(true);
    setBuffering(false);
  };

  const handleVideoEnd = () => {
        playerRef.current.seekTo(0);
        setIsPlaying(false);
        setShowControls(true);
        setIsSeekbar(false);
  };


  return (
    <div ref={ref} className="video-container" onClick={handleVideoClick}
        onMouseEnter={() => setShowControls(true)} 
        onMouseLeave={() => isPlaying && setShowControls(false)}
    >
        {buffering && isPlaying?<CircularProgress size={15} thickness={5} color='white' style={{position:'absolute',right:10,top:20,color:'#ff7200'}}/>:null
}
      <ReactPlayer
        ref={playerRef}
        url={url}
        playing={isPlaying}
        controls={false}
        width="100%"
        // height={'80vh'}
        // height={400}
        
        onProgress={handleProgress}
        onBuffer={handleBuffer}
        onBufferEnd={handleBufferEnd}
        onEnded={handleVideoEnd}
        
      />
      {showControls && (<>
        <button onClick={handlePlayPause} className="play-pause-button d-flex align-items-center justify-content-center">
          {isPlaying ? <PauseIcon style={{fontSize:40}}/> : <PlayArrowIcon  style={{fontSize:40}}/> }
        </button>
        {
            isSeekbar?<div className="seekbar-container">
            <input
              type="range"
              min={0}
              max={1}
              step="any"
              value={played}
              onChange={handleSeekChange}
              className="seekbar"
              style={{ '--seekbar-value': played }}
            />
          </div>:null
        }
        
        </>
      )}
    </div>
  );
};

export default FeedVideo;
