import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getSystemNotifications } from "../../../api/OurService";
import { useAuth } from "../../../Context/AuthContext";

function AdminNotifications() {
  const { user } = useAuth();
  const [isSystemMsg, setIsSystemMsg] = useState(true);
  const [isInnerMsg, setisInnerMsg] = useState(true);
  const [systemMsg, setSystemMsg] = useState();
  const handleSystemNotificaitons = async () => {
    const adminNotiRes = await getSystemNotifications({token:user?.access_token});
    // console.log(adminNotiRes);
    setSystemMsg(adminNotiRes?.data);
  }
  useEffect(() => {
    handleSystemNotificaitons();
    setTimeout(() => { setIsSystemMsg(false);setisInnerMsg(false)}, 5000)
  }, [])

  const showHideSystemMsg = () =>{
    setIsSystemMsg(!isSystemMsg);    
    setTimeout(() => { setisInnerMsg(!isInnerMsg) }, isInnerMsg?50:400)
    
  }
  return (
    <>
    {
    systemMsg && systemMsg?.is_highlighted?
    <div className='system-msg-container px-md-4' style={{ position: 'fixed', right: 20, bottom: 90,zIndex:100 }}>
      <div className='card bg-transparent'>
        <div className={`card-body text-white shadow ${isSystemMsg?'':'hide'} p-md-4 position-relative`}>
            {
              isInnerMsg?<div className='system-msg'>
              <h5>{systemMsg?.title}</h5>
              <p>{systemMsg?.description}</p>
            </div>:null
            }
            
          
        </div>
        <div className="card-footers">
        <div className='btn-msg-system' onClick={()=>showHideSystemMsg()}>
            <i className='fa fa-message'></i>
          </div>
        </div>
      </div>
    </div>:null
}
    </>
  );
}

export default AdminNotifications;
