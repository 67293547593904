import { toast } from "react-toastify";
import { DASHBOARD_URL, FEEDS_API_Basepath } from "../../utils/utils";

export function GetDataAuth(url, token) {
//  alert(token)
  try {
    return new Promise((resolve, reject) => {
      fetch(FEEDS_API_Basepath + url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      })
        // .then((response) => response.json())
        .then((response) => {
          if (response.status === 401) {
            // alert(1);
            // toast.error('Season Timed Out');
            // Clear all local storage
            localStorage.clear();
            // Redirect to login page
            setTimeout(() => { window.location.href = DASHBOARD_URL; }, 2000)
            
            return;
          }
          return response.json()})
        .then((responseJson) => {
          
          resolve(responseJson);
        })
        .catch((error) => {
          reject(error);
        });
    });
  } catch (e) {
    console.log(e);
  }
}
