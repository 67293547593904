import { useEffect, useRef, useState, createContext } from "react";
import LoadingScreen from "../../../common/LoadingScreen";
import { FeedsLoader } from "../../../common/loaders";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GetDataAuth } from "../../../../api/feeds/GetDataAuth";
import styles from "../../../../assets/css/styles/Home.module.css";
import { Navigation } from "@material-ui/icons";
import { FeedCard } from "../../../common";
import BreadCrumbs from "../../../common/BreadCrumbs";
import { useAuth } from "../../../../Context/AuthContext";
import { useFeeds } from "../../../../Context/FeedsContext";
import { PostDataAuth } from "../../../../api/feeds/PostDataAuth";
import { GetData } from "../../../../api/feeds/GetData";
import { getUserInfo } from "../../../../api/OurService";
import { USER_INFO_KEY, fetchUserInfo } from "../../../../utils/utils";
import { toast } from "react-toastify";
// import { slugify } from "../../../../utils/utils";

function FeedDetail() {
  const [loading, setLoading] = useState(true);
  const [favBtnLoader, setFavBtnLoader] = useState(false);
  const [apiToken, setApiToken] = useState();
  const {id, slug} = useParams();
  let emailVerifiedStatus = true;
  const {user, setUser, setFinance, globalToken, setIsEmailVerifyCover} = useAuth();
  const {setFeeds, feeds, feedDetail, setFeedDetail} = useFeeds();
  const naivigation = useNavigate();
  const location = useLocation();
  
  const slugify = (text) => {
    return text
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  
//   const fetchUserInfo = async (userId) => {
//     const res = await getUserInfo(userId, user?.access_token);
//     if(res.success){
//         setUser({...user, credits:res?.data?.credits})
//         localStorage.setItem('userDetails',JSON.stringify({...user, credits:res?.data?.credits}))
//         localStorage.setItem('____fi', JSON.stringify({__crdt:res.data?.credits}))
//         setFinance({__crdt:res.data?.credits}) 
//     } 
//     // console.log(userDetails?.slug)

// }

const _feedDetails = async (id) => {
  
    if (localStorage.getItem("userDetails")) {
      // const aToken = localStorage.getItem("___fTkn");
      // setApiToken(aToken)
      const feedDetailsReturn = await GetDataAuth(
        `feed-more/details/${id}/via-authentic-user`,
        globalToken
      );
      if (feedDetailsReturn?.success) {
        
        if(feedDetailsReturn?.data && slug ===slugify(feedDetailsReturn?.data?.feed?.feed_text || feedDetailsReturn?.data?.feed_text)){
          
            setFeedDetail([feedDetailsReturn?.data])
        }else{
            naivigation('/')
        }
      } else {
        
      }
    } else {
    }
    setLoading(false)

  };

  useEffect(()=>{
    
    _feedDetails(id);
    return ()=> localStorage.setItem('feedDetail', 'feedDetail')
  },[])

  const _favoriteFeed = async (item, favouriteType) => {
    setFavBtnLoader(true);
    // await setFeedDetail([])
    if (localStorage.getItem("userDetails")) {
      // const aToken = localStorage.getItem("___fTkn");
      const favouriteDataReturn = await PostDataAuth(
        `feed-favourite/add`,
        globalToken,
        { feedId: item?.id, favourite: favouriteType }
      );
      if (favouriteDataReturn?.success) {
        _allFeed(1);
        _feedDetails(id);
      } else {
        if(favouriteDataReturn?.status==5){         
          toast.error(favouriteDataReturn?.errors);
          setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
      }
      }
    } else {
    }
    setFavBtnLoader(false);
  };

  const _allFeed = async (pageNumber) => {
    if (
      user &&
      globalToken
    ) {
      // const token = localStorage.getItem("___fTkn");
      // await setApiToken(token);
      const allFeeds = await GetDataAuth(
        `get/feed/list/via-authentic-user?page=${pageNumber}`,
        globalToken
      );
      if (allFeeds?.current_page !== allFeeds?.last_page && pageNumber !== 1) {
        const mergedFeeds = [...feeds?.data, ...allFeeds?.data];
        setFeeds({ ...feeds, data: mergedFeeds, current_page: pageNumber });
      } else {
        setFeeds(allFeeds);
      }
    } else {
      const allFeeds = await GetData(`get/feed/list?page=${pageNumber}`);
      if (allFeeds?.current_page !== allFeeds?.last_page && pageNumber !== 1) {
        const mergedFeeds = [...feeds?.data, ...allFeeds?.data];
        setFeeds({ ...feeds, data: mergedFeeds, current_page: pageNumber });
      } else {
        setFeeds(allFeeds);
      }
    }
  };

  const onLikeDislikeHandler = async (item, index) => {
    if (globalToken) {
      const likeUnlikeReturn = await PostDataAuth(
        `feed/like`,
        globalToken,
        { feed_id: item?.id }
      );
      // console.log(likeUnlikeReturn)
      if (likeUnlikeReturn?.success) {
        toast.success(likeUnlikeReturn?.message);
        _allFeed(1);
        _feedDetails(id);
        fetchUserInfo(user?.tuteehub_id, user?.access_token, setUser, setFinance, user)

      } else {
        if(likeUnlikeReturn?.status==5){   
          emailVerifiedStatus = false;      
          toast.error(likeUnlikeReturn?.errors);
          setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
      }
        // setModalOpen(true);
      }
    } else {
    //   setModalOpen(true);
    }
  };
  return (
    <>  
  
    <div className="feed-detail-breadcrumb"><BreadCrumbs /></div>
      {loading ? <FeedsLoader /> : null}
      <>
      {feedDetail?.length
            ? feedDetail?.map((feed, index) => (<FeedCard
        key={index} 
        apiToken={globalToken}
        user={user}
        likeUnlike={onLikeDislikeHandler}
        feed={feed}
        _allFeed={_allFeed}
        setModalOpen={null}
        modalOpen={null}
        hideReadMore={false}
        goToFeedDetail={null}
        feedRefreshMain={null}
        _favoriteFeed={_favoriteFeed}
        favBtnLoader={favBtnLoader}
        emailVerifiedStatus={emailVerifiedStatus}

        myFav={false}
    />)):loading?(<FeedsLoader />):<div  style={{paddingBottom:5}} className={("poscard ", styles.postcard)}></div>
    }    
      </>
    </>
  );
}

export default FeedDetail;
