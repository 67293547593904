import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const calculateTotalAndSuccessRate = (
    userInprogressTask,
    userInReviewTask,
    userApproveTask,
    userRejectedTask,
    userExpiredTask,
    userDisputedTask,
    userReferredToAdminTask
) => {
    
    const totalTasks = userInReviewTask + userInprogressTask + userApproveTask + userRejectedTask + userExpiredTask + userDisputedTask + userReferredToAdminTask;
    
    const successRate = totalTasks > 0 ? (userApproveTask / totalTasks) * 100 : 0;
    return {
        totalTasks,
        successRate
    };
};

const TaskProgress = ({
    userInprogressTask,
    userInReviewTask,
    userApproveTask,
    userRejectedTask,
    userExpiredTask,
    userDisputedTask,
    userReferredToAdminTask
}) => {
    const { totalTasks, successRate } = calculateTotalAndSuccessRate(
        userInprogressTask,
        userInReviewTask,
        userApproveTask,
        userRejectedTask,
        userExpiredTask,
        userDisputedTask,
        userReferredToAdminTask
    );

    return (
        <div className='my-0'>
            {/* <h3>Total Tasks: {totalTasks}</h3> */}
            <div style={{ width: '50px', height: '50px', margin: '0 auto' }}>
                <CircularProgressbar
                    value={successRate}
                    text={`${successRate.toFixed()}%`}
                    styles={buildStyles({
                        textSize: '25px',
                        fontWeight:800,
                        pathColor: `rgba(62, 152, 199, ${successRate / 100})`,
                        textColor: '#585858',
                        trailColor: '#d6d6d6',
                    })}
                />
            </div>
        </div>
    );
};

export default TaskProgress;
