import React, { useState } from "react";
// import logo from "../../assets/images/tuteehub_lgo.webp";
import { Formik } from "formik";
import * as Yup from "yup";
import { ForgotPasswordApi, LoginApi } from "../../api/AuthService";
import { useAuth } from "../../Context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Loadingicon } from "../../AppIcons";
import { getUserToken } from "../../api/OurService";
import { PostData } from "../../api/feeds/PostData";

function ForgotPassword() {
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const { setUser, setFToken } = useAuth();
    const DisplayingErrorMessagesSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email").required("Email is required"),
    });

    

    const handleForgotPassword = async (values, resetForm) => {
        setLoading(true);
        try {
          const response = await ForgotPasswordApi(values);
            // console.log(response)
          if (response?.data?.success) {
            toast.success("We have e-mailed your token code to reset password");
            resetForm();
            navigate("/reset-password");
                 } else {
                    // console.log(response)
            toast.error(response?.data?.errors);
          }
        } catch (err) {
            // console.log(err)
          toast.error(err.message || "Something went wrong!");
        } finally {
          setLoading(false);
        }
    };

    return (
        <section className="bg-white" id="top-area" style={{ padding: 0 }}>
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 main-container-login">

                <div className="w-full bg-white rounded-lg shadow-customs md:mt-0 sm:max-w-md xl:p-0 form-container">
                    <div className="w-100 text-center">
                        <Link
                            to={'/'}
                            className="flex items-center justify-center mt-6 mb-2 text-2xl font-semibold text-gray-900 dark:text-white"
                        >
                            <img className=" mr-2" width={130} src={require('../../assets/images/tuteehub_lgo.webp')} alt="logo" />
                        </Link>
                    </div>

                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <p className="text-gray-500 text-sm">Did you forgot your password ?... Don't worry.
                    Please enter your registered email id in below box to get link to update your credential.</p>
                        <Formik
                            initialValues={{
                                email: ""
                            }}
                            validationSchema={DisplayingErrorMessagesSchema}
                            onSubmit={(values, {resetForm}) => {
                                handleForgotPassword(values, resetForm);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                            }) => (
                                <form className="" onSubmit={handleSubmit}>
                                    <div>
                                        <label
                                            htmlFor="email"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                                        >
                                            Your email
                                            <span className="text-red-400 text-base">*</span>
                                        </label>
                                        <input type="text" name="fake-email" style={{ display: 'none' }} />
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                            placeholder="email@example.com"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                        <div className="text-red-500 text-sm text-right min-h-[20px]">
                                            {touched.email && errors.email && errors.email}
                                        </div>
                                    </div>

                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className="mt-5 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center"
                                    >
                                        {loading ? (
                                            <div className="h-5 w-5">
                                                <Loadingicon />
                                            </div>
                                        ) : (
                                            "Submit"
                                        )}
                                    </button>
                                </form>
                            )}
                        </Formik>
                        <p className="mt-2 mb-0 pt-1 text-sm font-semibold text-center">
                            Back to 
                            <Link to="/login" className="text-themecolor transition duration-150 ease-in-out hover:text-danger-600 focus:text-danger-600 active:text-danger-700 ml-1">Sign In</Link>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ForgotPassword;
