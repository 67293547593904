import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import { ERROR_SOMETHING_WENT_WRONG, slugToNormal } from '../../../../utils/utils';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import PopularCareerCategories from '../popularcategories';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { getQuotesByAuthor, getQuotesByCategory } from '../../../../api/quotes';
import { useAuth } from '../../../../Context/AuthContext';
import QList from '../common/quotesList';


function QuotesList() {
    const { globalToken } = useAuth();
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allQuotes, setAllQuotes] = useState([]);
    const [qCatId, setQCatId] = useState();
    const [qAthrId, setQAthrId] = useState();
    const params = useParams();
    const navigation = useNavigate();   
    useEffect(() => {
        if(params?.qCatId){
            setQCatId(params?.qCatId);
            handleFetchallQuotes(1, params?.qCatId);
        }
        if(params?.qAthrId){
            setQAthrId(params?.qAthrId);
            handleFetchallQuotes(1, params?.qAthrId);
        }
        
    }, [])

    // fetch all quotes by category
    const handleFetchallQuotes = async (pageNo, cId) => {
        const resAllQuotesByCat = params?.qAthrId?await getQuotesByAuthor({ token:globalToken ,pageNo: pageNo, qAthrId: cId}):await getQuotesByCategory({ token:globalToken ,pageNo: pageNo, catId: cId});
        if (resAllQuotesByCat?.success) {
            console.log('quoteslisting', resAllQuotesByCat?.data);
            setIsNotFound(false)
            resAllQuotesByCat?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resAllQuotesByCat?.data?.length && pageNo == 1) {
                setAllQuotes(resAllQuotesByCat?.data);
            } else if (resAllQuotesByCat?.data?.length && pageNo !== 1) {
                setAllQuotes([...allQuotes, ...resAllQuotesByCat?.data]);
            } else {
                if (pageNo == 1) {
                    setAllQuotes([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
    }

    // load more quotes data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        if(qCatId)
        handleFetchallQuotes(nxtPage, qCatId);
        if(qAthrId)
        handleFetchallQuotes(nxtPage, qAthrId);
        setPageNo(nxtPage);
    }

    const popularCareerCats = [
        {
            name: "Joyce Meyer",
            value: "Joyce Meyer",
            redirectUrl: "#"
        },
        {
            name: "Henry Rollins",
            value: "Henry Rollins",
            redirectUrl: "#"
        },
        {
            name: "Helen Mirren",
            value: "Helen Mirren",
            redirectUrl: "#"
        },
        {
            name: "Justin Trudeau",
            value: "Justin Trudeau",
            redirectUrl: "#"
        },
        {
            name: "Helen Fisher",
            value: "Helen Fisher",
            redirectUrl: "#"
        },
        {
            name: "Maya Angelou",
            value: "Maya Angelou",
            redirectUrl: "#"
        },
        {
            name: "Helen Keller",
            value: "Helen Keller",
            redirectUrl: "#"
        },
        {
            name: "Julie Sweet",
            value: "Julie Sweet",
            redirectUrl: "#"
        },
        {
            name: "More ...",
            value: "More ...",
            redirectUrl: "#"
        }
    ];
    


    return (<>
        {/* <BreadCrumbs /> */}
        <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                    </li>    
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/quotes' style={{ fontSize: 14, textDecoration: 'none' }}>Quotes</NavLink>
                    </li>                    
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                        {
                            params?.qCatId?<NavLink to='/quotes/topics' style={{ fontSize: 14, textDecoration: 'none' }}>Topics</NavLink>:<NavLink to='/quotes/authors' style={{ fontSize: 14, textDecoration: 'none' }}>Authors</NavLink>
                        }                    
                    </li>                    
                   
                    <li 
                        className={`breadcrumb-item capitalize active clamping ellipsis`}
                        >
                        {slugToNormal(params?.topicSlug || params?.athrSlug)} Quotes
                    </li>
                    
      </ol>
    </nav>
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    <div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong>Top 10 ${slugToNormal(params?.topicSlug || params?.athrSlug)} Quotes</strong>`} subTitle={`
<p class="mb-0">${params?.catId?``:`Discover ${slugToNormal(params?.athrSlug)}'s famous and rare quotes.`}</p>`} />
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />

                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <>
                        {
                            allQuotes?.map((quote, sIndex) => (
                                <QList quote={quote} key={sIndex} type="quote" redirectionFn={(e)=>{e.preventDefault();e.stopPropagation(); navigation(`/quotes/detail/${quote?.id}/${quote?.slug}`)}}/>
                            ))
                        }
                    </>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
            {/* <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div> */}
        </div>

    </>)
}

export default QuotesList;
