import React from 'react'
import { Loadingicon } from '../../../AppIcons'
import { useNavigate } from 'react-router-dom'

export default function ViewAllButton({disabled=false, redirectionUrl, handleClickFn, btnText, btnClass, type=null, loading=false }) {
    const navigation = useNavigate();
  return (
    <button type="button" 
    disabled={loading}    
    onClick={()=>type=='link'?navigation(redirectionUrl):handleClickFn()} 
    className={`d-block w-100 text-center mt-4 outline-0 ${loading?'':'btn'}  ${btnClass}`}
    >
        {loading ? (
            <span className="h-3 w-3 m-auto" class="load-more-span">
                <Loadingicon />
            </span>
        ) : (
            <span>{btnText}</span>
        )}
    </button>
  )
}
