import NodDataImg from "../../assets/images/no-data.jpg";

function Nodata() {
  return (
    <div className="flex justify-center flex-col items-center bg-white text-center pb-10 w-full">
      <img src={NodDataImg} className="max-w-[400px]" />
      <div className="font-bold text-xl">No Data to Display.</div>
    </div>
  );
}

export default Nodata;
