import React from "react";

function GoogleSocialLogin({ onClick, btnTitle }) {
  return (
    <button
      onClick={onClick}
      style={{
        background: 'linear-gradient(45deg, #fafafa,rgb(255 241 229), white)',
        color: '#5f4b4b',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        width: '100%',
        display: 'flex',
        boxShadow: '0px 0px 3px rgba(0,0,0,0.3)',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src="https://img.icons8.com/color/28/000000/google-logo.png"
        alt="Google Icon"
        style={{ marginRight: "8px" }}
      />
      {btnTitle}
    </button>
  );
}

export default GoogleSocialLogin;
