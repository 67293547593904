import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import { ERROR_SOMETHING_WENT_WRONG, slugToNormal } from '../../../../utils/utils';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import PopularCareerCategories from '../popularcategories';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { getQuotesByAuthor, getQuotesByCategory } from '../../../../api/quotes';
import { useAuth } from '../../../../Context/AuthContext';
import QList from '../common/lyricsList';
import { getAllLyricsByAlbum, getAllLyricsByArtist } from '../../../../api/lyrics';
import LList from '../common/lyricsList';


function LyricsListByArtist() {
    const { globalToken } = useAuth();
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allLyrics, setAllLyrics] = useState([]);
    const [artistId, setArtistId] = useState();
    const [artistSlug, setArtistSlug] = useState();
    const params = useParams();
    const navigation = useNavigate();   
    useEffect(() => {
        if(params?.artistId){
            setArtistId(params?.artistId);
            setArtistSlug(params?.albumSlug);
            handleFetchAllLyrics(1, params?.artistId);
        }        
    }, [])

    // fetch all lyrics by artist
    const handleFetchAllLyrics = async (pageNo, aId) => {
        const resAllLyricsByArtist = await getAllLyricsByArtist({ pageNo: pageNo, artistId: aId});
        if (resAllLyricsByArtist?.success) {
            console.log('lyricslisting', resAllLyricsByArtist?.data);
            setIsNotFound(false)
            resAllLyricsByArtist?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resAllLyricsByArtist?.data?.length && pageNo == 1) {
                setAllLyrics(resAllLyricsByArtist?.data);
            } else if (resAllLyricsByArtist?.data?.length && pageNo !== 1) {
                setAllLyrics([...allLyrics, ...resAllLyricsByArtist?.data]);
            } else {
                if (pageNo == 1) {
                    setAllLyrics([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
    }

    // load more lyrics data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        if(artistId)
        handleFetchAllLyrics(nxtPage, artistId);
        setPageNo(nxtPage);
    }

    const popularCareerCats = [
        {
            name: "Joyce Meyer",
            value: "Joyce Meyer",
            redirectUrl: "#"
        },
        {
            name: "Henry Rollins",
            value: "Henry Rollins",
            redirectUrl: "#"
        },
        {
            name: "Helen Mirren",
            value: "Helen Mirren",
            redirectUrl: "#"
        },
        {
            name: "Justin Trudeau",
            value: "Justin Trudeau",
            redirectUrl: "#"
        },
        {
            name: "Helen Fisher",
            value: "Helen Fisher",
            redirectUrl: "#"
        },
        {
            name: "Maya Angelou",
            value: "Maya Angelou",
            redirectUrl: "#"
        },
        {
            name: "Helen Keller",
            value: "Helen Keller",
            redirectUrl: "#"
        },
        {
            name: "Julie Sweet",
            value: "Julie Sweet",
            redirectUrl: "#"
        },
        {
            name: "More ...",
            value: "More ...",
            redirectUrl: "#"
        }
    ];
    


    return (<>
        {/* <BreadCrumbs /> */}
        <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                    </li>    
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/lyrics/artists' style={{ fontSize: 14, textDecoration: 'none' }}>Artists</NavLink>
                    </li> 
                
                    <li 
                        className={`breadcrumb-item capitalize active clamping ellipsis`}
                        >
                        {slugToNormal(params?.artistSlug)}
                    </li>
                    
      </ol>
    </nav>
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    <div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong>Top 10 ${slugToNormal(params?.artistSlug)} Lyrics</strong>`} subTitle={`
<p class="mb-0">Explore popular lyrics from  ${slugToNormal(params?.artistSlug)}. Every song on this list is full of meaning and well worth checking out!</p>`} />
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />

                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <>
                        {
                            allLyrics?.map((lyric, sIndex) => (
                                <LList lyric={lyric} key={sIndex} type="lByArtist" redirectionFn={(e)=>{e.stopPropagation();e.preventDefault(); navigation(`/lyrics/detail/${lyric?.id}/${lyric?.slug}`)}}/>
                            ))
                        }
                    </>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
            {/* <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div> */}
        </div>

    </>)
}

export default LyricsListByArtist;
