import React, { useState } from "react";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { LoginApi, RegisterApi, SocialLoginApi } from "../../api/AuthService";
import { useAuth } from "../../Context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Loadingicon } from "../../AppIcons";
import { getUserInfo, getUserToken } from "../../api/OurService";
import { PostData } from "../../api/feeds/PostData";
import PhoneNumberInput from "../common/phonenumberinput";
import ReCAPTCHA from "react-google-recaptcha";
import GoogleLogin from "react-google-login";
import GoogleSocialLogin from "../sociallogin";
import { CDN_BASE_URL, GOOGLE_WEB_CLIENT_ID, G_CAPTCHA_V2_SITE_KEY, USER_INFO_KEY, fetchUserInfo, isMobile } from "../../utils/utils";
import { useVerifyUserStatus } from "../../Context/VerifyStatusContext";
import PasswordToggleIcon from "../common/passwordVisibility";

function Register() {
  const [loading, setLoading] = useState(false);
  const formik = useFormikContext();
  const navigate = useNavigate();
  const { setUser, setFToken, setFinance, setGlobalToken, setIsEVerified } = useAuth();
  const [isVerified, setIsVerified] = useState(false);
  const { setIsEmailVerified } = useVerifyUserStatus();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

 
  const DisplayingErrorMessagesSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{6,}$/,
        "Password must be at least 6 characters and contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    ccode: Yup.string().required("Country code is required"),
    phone: Yup.string().required("Mobile number is required") 
  });
  


  const feedsToken = async (loggedData) => {
    // console.log(loggedData)
      // localStorage.setItem("___fTkn", loggedData?.secret_token);
      // localStorage.setItem("userDetails", JSON.stringify({...loggedData}));
      // localStorage.setItem('ev_______', loggedData?.email_verified);
	  // setIsEVerified(loggedData?.email_verified);
      setGlobalToken(loggedData?.secret_token);
      await fetchUserInfo(loggedData?.tuteehub_id, loggedData?.secret_token, setUser, setFinance, loggedData, setLoading);
      navigate('/')
  }

//   const fetchUserInfo = async (loggedData,userId, nToken) => {
//     const res = await getUserInfo(userId, nToken);
//     if(res.success){
//     setUser({...loggedData,access_token:nToken, phone:res?.data?.phone,country_code:res?.data?.country_code, name:res?.data?.name,profile_image:(res?.data?.profileImage)?CDN_BASE_URL + res?.data?.profileImage:null})
//     localStorage.setItem('userDetails',JSON.stringify({...loggedData, access_token:nToken, phone:res?.data?.phone,country_code:res?.data?.country_code, name:res?.data?.name,profile_image:(res?.data?.profileImage)?CDN_BASE_URL + res?.data?.profileImage:null}))
//     localStorage.setItem('____fi', JSON.stringify({__crdt:res.data?.credits}))
//     setFinance({__crdt:res.data?.credits})       
//     }
// }

    const handleVerify = (response) => {
        if (response) {
        setIsVerified(true);
        }
      };
 
      const handleExpired = () => {
        setIsVerified(false); // Reset verification status when reCAPTCHA expires
        toast.error("reCAPTCHA verification has expired. Please try again.");
      };
  const handleRegister = async (values) => {
   if (isVerified) {
    const registerParams = {
        name:values?.name,
        email:values?.email,
        password:values?.password,
        phone:values?.phone,
        ccode:values?.ccode,
        user_type:values?.userType,
        deviceType:1,
        deviceToken:'desktopweb',
        osVersion:'desktopweb',
        isRegisterSource:'tuteehub'
    }
   
    setLoading(true);
    try {
      const response = await RegisterApi(registerParams);
      console.log(response)
      if (response.data.success) {  
        if(response?.data?.data?.message ==='User Already Registered!'){
            toast.error(response?.data?.data?.message);
            return;
        }else{

            toast.success(response?.data?.data?.message);
            setIsEmailVerified(response.data.data?.email_verified);
            feedsToken(response.data.data)
        }     
        
      } else {
        toast.error(response.data.message || "Something went wrong!");
      }
    } catch (err) {
      toast.error(err.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
    } else {
        toast.error("Captcha is required!");
    }
    
  };

  const handleGoogleLoginSuccess = async (response) => {

    const { email, familyName, givenName, googleId, imageUrl, name} = response?.profileObj;

    const registerParams = {
      fname:givenName,
      lname:familyName,
      email,
      user_type: 'user',
      deviceType: 1,
      deviceToken: 'desktopweb',
      osVersion: 'desktopweb',
      isRegisterSource: 'tuteehub'
    }
    setLoading(true);
    try {
      const response = await SocialLoginApi(registerParams);
      // console.log(response)
      if (response.data.success) {
          // setUser(response.data.data);
          setIsEmailVerified(response.data.data?.email_verified);
          feedsToken({...response.data.data, imageUrl:imageUrl})
          // localStorage.setItem("userDetails", JSON.stringify(response.data.data));
          // navigate("/feeds");
      } else {
        toast.error(response.data.message || "Something went wrong!");
      }
    } catch (err) {
      toast.error(err.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLoginFailure = (error) => {
    toast.error("Something went wrong!");
  };

  return (
    <section className="bg-white py-0" id="top-area">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto py-4 lg:py-0 main-container-login">
        
        <div className="w-full bg-white rounded-lg shadow-customs dark:border mb-4 md:mt-0 sm:max-w-md xl:p-0">
        <div className="w-100 text-center">
            <Link
              to={'/'}
              className="flex items-center justify-center mt-6 mb-2 text-2xl font-semibold text-gray-900 dark:text-whites"
            >
              <img className=" mr-2" src={require('../../assets/images/tuteehub_lgo.webp')} alt="logo" width={130} />
            </Link>
        </div>
        
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
           
              <GoogleLogin
              clientId={GOOGLE_WEB_CLIENT_ID}
              onSuccess={handleGoogleLoginSuccess}
              onFailure={handleGoogleLoginFailure}
              cookiePolicy={"single_host_origin"}
              scope="profile email"
              prompt="select_account"
              render={(renderProps) => (<>
                  {loading ? (
                  <div  style={{
                    background: 'linear-gradient(45deg, #fafafa,rgb(255 241 229), white)',
                    color: '#5f4b4b',
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    width: '100%',
                    display: 'flex',
                    boxShadow: '0px 0px 3px rgba(0,0,0,0.3)',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    <div  className="h-5 w-5">
                    <Loadingicon />
                    </div>
                  </div>
                ) : <GoogleSocialLogin onClick={renderProps.onClick} btnTitle={'SignUp with Google'}/>
                }
                </>
              )}
            />

            <div className="divider">
              <div className="line"></div>
              <div className="or">or</div>
              <div className="line"></div>
            </div>
            <Formik
              initialValues={{
                name:"",
                email: "",
                password: "",
                confirmPassword:"",
                phone:null,
                ccode:"",
                userType:"user"
              }}
              validationSchema={DisplayingErrorMessagesSchema}
              onSubmit={(values) => {
                handleRegister(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
              }) => (
                <form className="" onSubmit={handleSubmit}>
                    
                    <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                    >
                      Name
                      <span className="text-red-400 text-base">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      placeholder="Your Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    <div className="text-red-500 text-sm text-right min-h-[20px]">
                      {touched.name && errors.name && errors.name}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                    >
                      Your email
                      <span className="text-red-400 text-base">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      placeholder="email@example.com"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <div className="text-red-500 text-sm text-right min-h-[20px]">
                      {touched.email && errors.email && errors.email}
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="mobile"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                    >
                      Mobile Number
                      <span className="text-red-400 text-base">*</span>
                    </label>
                   <PhoneNumberInput
                   name="phone"
                   onChange={(value, country, e,formattedValue) => {
                            setFieldValue('ccode', country.dialCode);
                            const dialCodeWithoutPlus = (country.dialCode).replace("+", "");
                            setFieldValue('phone', value.replace(dialCodeWithoutPlus,""));
                            
                        }}
                   />
                    <div className="text-red-500 text-sm text-right min-h-[20px]">
                      {touched.phone && errors.phone && errors.phone}
                    </div>
                  </div>
                  <div className="position-relative">
                    <label
                      htmlFor="password"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                    >
                      Password<span className="text-red-400 text-base">*</span>
                    </label>
                    <input
                      type={showPassword?"text":"password"}
                      name="password"
                      id="password"
                      placeholder="••••••••"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      required=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <PasswordToggleIcon onClick={()=>setShowPassword(!showPassword)} showPassword={showPassword}/>
                    <div className="text-red-500 text-sm text-right min-h-[20px]">
                      {touched.password && errors.password && errors.password}
                    </div>
                  </div>
                  <div className="position-relative">
                    <label
                      htmlFor="confirmPassword"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                    >
                      Confirm Password<span className="text-red-400 text-base">*</span>
                    </label>
                    <input
                      type={showConfirmPassword?"text":"password"}
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder="••••••••"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      required=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                    />
                    <PasswordToggleIcon onClick={()=>setShowConfirmPassword(!showConfirmPassword)} showPassword={showConfirmPassword}/>
                    <div className="text-red-500 text-sm text-right min-h-[20px]">
                      {touched.confirmPassword && errors.confirmPassword && errors.confirmPassword}
                    </div>
                  </div>
                  <div style={{marginTop:15}} className="w-full">
                  <ReCAPTCHA sitekey={G_CAPTCHA_V2_SITE_KEY} onChange={handleVerify} onExpired={handleExpired} size={isMobile()?'compact':'normal'}/>
                  </div>
                  <button
                    type="submit"
                    disabled={loading}
                    className="mt-5 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center"
                  >
                    {loading ? (
                      <div className="h-5 w-5">
                        <Loadingicon />
                      </div>
                    ) : (
                      "Register"
                    )}
                  </button>
                </form>
              )}
            </Formik>
            <p className="mt-2 mb-0 pt-1 text-sm font-semibold text-center">
                Already have an account?
                <Link  to="/login" className="text-themecolor transition duration-150 ease-in-out hover:text-danger-600 focus:text-danger-600 active:text-danger-700 ml-1">Sign In</Link>
              </p>
          </div>
        </div>
      </div>
     
    </section>
  );
}

export default Register;
