import React, { useEffect, useState, useContext } from "react";
// import UseLocalStorage from "../Hooks/useLocalStorage";
import useLocalStorage from "../Hooks/useLocalStorage";

const VerifyStatusContext = React.createContext({});

const VerifyUserStatusProvider = (props) => {
 
  const [isEmailVerified, setIsEmailVerified] = useLocalStorage("ev_______", null);
 
  const verifyStatusContextValue = {
    isEmailVerified,
    setIsEmailVerified
  };

  return <VerifyStatusContext.Provider value={verifyStatusContextValue} {...props} />;
};

const useVerifyUserStatus = () => useContext(VerifyStatusContext);

export { VerifyUserStatusProvider, useVerifyUserStatus };
