import { isArray, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getQBankCatList, globalSearchQuestion } from "../../../api/OurService";
import { Loadingicon } from "../../../AppIcons";
import { useAuth } from "../../../Context/AuthContext";
import BreadCrumbs from "../../common/BreadCrumbs";
import Pagination from "../../common/Pagination";
import InfiniteScroll from "react-infinite-scroll-component";
import Nodata from "../../common/NoData";
import { convertLongNumberAbbreviated, slugToNormal, stripHtmlTags, yearSatra } from "../../../utils/utils";
import { useSearch } from "../../../Context/SearchContext";
import SectionDescription from "../../common/sectionDescription";
import QBTopicRectangleBox from "../../common/rectanglebox/qb/topicRectangleBox";

function QuestionBankCategories() {
  const [loading, setLoading] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [imgBaseurl, setImgBaseUrl] = useState("");
  const { listData, setListData, searchValue, setSearchValue } = useSearch();

  const navigate = useNavigate();
  const { state } = useLocation();
  const { qToken, iQToken, ffToken, dcToken, globalToken } = useAuth();
  const { name, catName } = useParams();

  useEffect(() => {
   
    setCurrentPage(1);
    setListData([]);
    if (!state) {
      navigate("/");
    } else {
      getBankList(1, true);  // Pass the initial page and reset flag
    }
  }, []);

  useEffect(() => {
    if(searchValue) {
      setCurrentPage(1);
      listData.length = 0;
      getBankList(1, true);  // Pass the initial page and reset flag
    } else {
      setCurrentPage(1);
      getBankList(1, true);  // Pass the initial page and reset flag
    }   
  }, [searchValue]);

  useEffect(() => {
    if(currentPage > 1) {
      getBankList(currentPage, false);  // Do not reset data
    }
  }, [currentPage]);

  const getBankList = async (page, resetData) => {
    const token = globalToken;
    if (resetData) {
      setLoading(true);
    }
    setScrollLoading(true);
    try {
      let res;
      if (!searchValue) {
        res = await getQBankCatList(state?.subid, page, name);
      } else {
        res = await globalSearchQuestion("topic", searchValue, token, page, name);
      }
      // console.log(res?.data)
      setImgBaseUrl(res?.data?.image_base_url);
      if (resetData) {
        setListData(res?.data?.data || res?.data);
      } else {
        setListData([...listData, ...res?.data?.data]);
      }
      if (res?.data?.data?.length < 30) {
        setHasMore(false);
      }
      setScrollLoading(false);
    } catch (error) {
      toast.error(error.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  const handlePgaeChange = (selectedObject) => {
    setCurrentPage(selectedObject.selected + 1);
  };

  const handListItemLink = (id, topic_name) => {
    setSearchValue(null)
    const strippedQuestion = stripHtmlTags(topic_name);
    const lowercaseQuestion = strippedQuestion.toLowerCase();
    const qSlug = lowercaseQuestion.replace(/ /g, '-');
    const topicName = topic_name;
    const catId = id;
    const moduleName = name;
    const subid = state?.subid;
    navigate(qSlug, {
      state: {
        subid,
        topicName,
        catId,
        moduleName,
      },
    });
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-2">
    <BreadCrumbs />
    {/* <div className="search-boxs px-2 d-flex w-50 align-items-center border rounded bg-white d-flex justify-content-between" style={{marginBottom:'1rem'}}><input type="text" autocomplete="chrome-off" className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0" placeholder="Search Solved Questions Across Any Subject Or Topic..." value=""/>
      <i className="fa fa-search opacity-5"></i>
    </div> */}
    </div>
      <div className="grid grid-cols-12 graph-card-box text-center px-10">
      </div>
      <div className="mb-2 p-2 grid grid-cols-1 group-card card-body gap-4">
        {loading ? (
          <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
            <div className="h-10 w-10">
              <Loadingicon />
            </div>
          </div>
        ) : isEmpty(listData) ? (
          <div className="flex justify-center mt-5">
            <Nodata />
          </div>
        ) : (
          <div className="grid grid-cols-1 group-card card-body gap-1">
  <div className="px-4 w-100">
  <SectionDescription title={name=='question-Bank'?`<strong>Top ${slugToNormal(catName)} Popular Question Bank Topics For Practice Session ${yearSatra()}</strong>`:name=='interview-questions'?`<strong>Top ${slugToNormal(catName)} Interview Questions For Session ${yearSatra()}</strong>`:name=='fullforms'?`<strong>Top ${slugToNormal(catName)} Full Forms For Session ${yearSatra()}</strong>`:name=='dictionary'?`<strong>Top ${slugToNormal(catName)} Word Meanings For Session ${yearSatra()}</strong>`:''} subTitle={name=='question-Bank'?`TuteeHub has over 1.5 Million Practice questions subjectwise/topicwise that are typically included in any exam syllabus. There are free practice questions for beginners with explanation along with subscription based packages with unlimited questions of various difficulty levels. If you're preparing for any competition, employment, entrance exam that includes multiple-choice questions then TuteeHub can help you practice with confidence.`:name=='interview-questions'?`TuteeHUB has over 1.5 Million questions with answers subjectwise/topicwise/skillwise/designationwise that are typically asked in any job interview.To help you prepare for any thing, we've taken the time to prepare this huge list of commonly asked interview questions. You won't be asked all of these questions at your job interview, but if you expect it to be asked then you'll find it in this list.`:name=='fullforms'?`Our comprehensive list of full forms on different topics is categorized in educational, organizational, finance, IT, technology, science, computer and general etc. To view each acronym, select a category and click on its link.`:name=='dictionary'?`Our comprehensive list of Dictionarys on different topics is categorized in educational, organizational, finance, IT, technology, science, computer and general etc. To view each acronym, select a category and click on its link.`:''}/>
  <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-0" />
  </div>
          <InfiniteScroll
            dataLength={listData?.length}
            next={() => {
              setCurrentPage(currentPage + 1);
            }}
            hasMore={hasMore}
            loader={
              scrollLoading ? (
                <div className="w-full flex items-center justify-center mb-20 text-themecolor min-h-[400px] absolute top-0 left-0 bg-white h-full bg-opacity-50">
                  <div className="h-10 w-10">
                    <Loadingicon />
                  </div>
                </div>
              ) : null
            }
            refreshFunction={() => {}}
            scrollableTarget={"qCat"}
            pullDownToRefresh
            pullDownToRefreshThreshold={90}
            className="relative"
          >
            <div
              className={`py-8 grid pr-2 p-4 pr-md-2 gap-4 multi-card lg:mt-0 mb-0 grid-cols-4 scroll-container-custom `}
              style={{ overflow: "auto" }}
              id="qCat"
            >
              {isArray(listData) &&
                listData?.map((item) =>
                  isEmpty(searchValue) ? (
                    <>
                  <QBTopicRectangleBox 
                    key={item?.id}
                    nData={item}
                    boxSize={'col-md-12 px-0'}
                    mH={true}
                    type={name}
                    thumbBaseUrl={imgBaseurl}
                    redirectionFn={()=>handListItemLink(item.id, item.topic_name || item.name)}
                  />
                  </>
                    // <div
                    //   className="category-main-box cursor-pointer rounded-xl"
                    //   onClick={() => handListItemLink(item.id, item.topic_name || item.name)}
                    //   key={item.id}
                    // >
                    //   <span className="px-2">
                    //     <img
                    //       className="rounded-xl"
                    //       src={
                    //         item?.image
                    //           ? imgBaseurl + "/" + item?.image
                    //           : require('../../../assets/images/feeds_default_placeholder.webp')
                    //       }
                    //       alt={item.topic_name}
                    //     />
                    //   </span>
                    //   <div className="px-2 py-1 category-name">
                    //     <h3 dangerouslySetInnerHTML={{ __html: item.topic_name || item.name }}></h3>
                    //     <p>
                    //       {item?.question_count
                    //         ? convertLongNumberAbbreviated(item?.question_count) + " Questions "
                    //         : null}{" "}
                    //     </p>
                    //   </div>
                    // </div>
                  ) : (
                    <div
                      className="m-3 px-5 py-5 child-box cursor-pointer bg-white shadow rounded-2xl flex justify-content-center align-items-center text-center"
                      onClick={() => handListItemLink(item.id, item?.name)}
                    >
                      <div className="text-xl category-text text-blue lineelip3">
                        {item?.name}
                      </div>
                    </div>
                  )
                )}
            </div>
          </InfiniteScroll>
          </div>
        )}
      </div>
    </>
  );
}

export default QuestionBankCategories;
