import { useEffect, useState } from "react";
import {changePassword, getUserInfo } from "../../../api/OurService";

import * as Yup from "yup";
import { Loadingicon } from "../../../AppIcons";
import BreadCrumbs from "../../common/BreadCrumbs";
import { Formik } from "formik";
import { useAuth } from "../../../Context/AuthContext";
import { Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function ChangePassword() {
    const [loading, setLoading] = useState(false);
    const[btnLoader, setBtnLoader] = useState(false);
    const [isShowOld, setIsShowOld] = useState(false);
    const [isShowNew, setIsShowNew] = useState(false);
    const [isShowConfirm, setIsShowConfirm] = useState(false);

    const DisplayingErrorMessagesSchema = Yup.object().shape({
        current_password: Yup.string().required("Old password is required"),
        new_password: Yup.string().required("New password is required").matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{6,}$/,
            "Password must be at least 6 characters and contain at least one uppercase letter, one lowercase letter, one number, and one special character"
          ),
        confirm_password: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref('new_password'), null], 'Confirm password must match')
    });
    const { user, globalToken, handleLogout } = useAuth();
    const navigate = useNavigate();
    // useEffect(() => { fetchUserInfo(user?.tuteehub_id) }, [])
    const handleChangePassword = async (values) => {  
        setBtnLoader(true);     
        const res = await changePassword(values, globalToken);
        // console.log(res);
        if(res?.success){
            toast.success('Password changed. Signing off to apply new password.');
            handleLogout();
            // navigate('/my-profile');
        }else{
            if(res?.errors)
           toast.error(res?.errors?.new_password[0]) 
            else
            toast.error(res?.message) 
        }
        // if(res.success)
        // setUserDetails(res?.data)
        setBtnLoader(false);     


    }
    const showHidePassword = (type) =>{
            type=='current_password'?setIsShowOld(!isShowOld):type=='new_password'?setIsShowNew(!isShowNew):setIsShowConfirm(!isShowConfirm);
    }
    const formData = [
        {
            id: 'current_password',
            title: 'Old Password',
            type: !isShowOld?'password':'text',
            name: 'current_password',
            placeholder: 'Old Password',
            required: true,
            icon:!isShowOld?'fa fa-eye':'fa fa-eye-slash'
        },
        {
            id: 'new_password',
            title: 'New Password',
            type: !isShowNew?'password':'text',
            name: 'new_password',
            placeholder: 'New Password',
            required: true,
            icon:!isShowNew?'fa fa-eye':'fa fa-eye-slash'
        },{
            id: 'confirm_password',
            title: 'Confirm Password',
            type: !isShowConfirm?'password':'text',
            name: 'confirm_password',
            placeholder: 'Confirm Password',
            required: true,
            icon:!isShowConfirm?'fa fa-eye':'fa fa-eye-slash'
        }
    ]
    return (
        <>
            <BreadCrumbs />
          
            <div className=" py-4 px-0 grid grid-cols-1 group-card card-body gap-4">
                {loading ? (
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="px-3">
                            <div className="row">
                               
                                <div className="col-md-9">
                                            <Formik    
                                                initialValues={{
                                                    current_password: '',
                                                    new_password: '',
                                                    confirm_password: '',
                                                  }}                                           
                                                validationSchema={DisplayingErrorMessagesSchema}
                                                onSubmit={(values) => {
                                                   handleChangePassword(values)
                                                }}
                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    setFieldValue,
                                                    handleSubmit,
                                                }) => (
                                                    <form className="" onSubmit={handleSubmit}>
                                                        <div className="row">
                                                            {
                                                                formData?.map(fieldMeta => (
                                                                    <div className={`${fieldMeta.width ? 'col-md-12' : 'col-md-12'}`}>
                                                                        <label
                                                                            htmlFor={fieldMeta?.id}
                                                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                                                                        >
                                                                            {fieldMeta?.title}

                                                                            {
                                                                                fieldMeta?.required ? <span className="text-red-400 text-base">*</span> : null
                                                                            }
                                                                        </label>
                                                                        <input type="password" name="fake-password" autoComplete="new-password" style={{ display: 'none' }} />
                                                                        {
                                                                            <>
                                                                                       <div className="position-relative"> 
                                                                                        <input
                                                                                            type={fieldMeta?.type}
                                                                                            name={fieldMeta?.name}
                                                                                            autoComplete={'off'}
                                                                                            id={fieldMeta?.name}
                                                                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                                                            placeholder={fieldMeta?.title}
                                                                                            onChange={handleChange}
                                                                                            onBlur={handleBlur}
                                                                                            value={values[fieldMeta?.name]}
                                                                                        />
                                                                                        <button
                                                                                            type="button"
                                                                                            className="bg-transparent btn border-0 text-black-50 position-absolute right-0 top-0 btn-default"
                                                                                            onClick={()=>showHidePassword(fieldMeta.name)}
                                                                                            style={{marginTop:4}}
                                                                                        >
                                                                                            <span><i className={fieldMeta?.icon}></i></span>
                                                                                        </button>
                                                                                       
                                                                                        </div>
                                                                                        <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                            {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                    </div>
                                                                ))
                                                            }
                    <div className="col-md-2">
                        <button
                          type="submit"
                          disabled={btnLoader}
                          className="mt-2 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center"
                        >
                          {btnLoader ? (
                            <div className="h-5 w-5">
                              <Loadingicon />
                            </div>
                          ) : (
                            "Save"
                          )}
                        </button>
                      </div>
                                                        </div>
                                                    </form>
                                                )}

                                        </Formik>
                                </div>
                            </div>
                            <small className="text-black-50 mt-4 d-block">Note: Your new password will be applied the next time you log in.</small>
                        </div>
                        
                    </>
                )
                }
            </div>
        </>
    );
}
export default ChangePassword;
