

import { BASE_URL, FEEDS_API_Basepath } from '../../utils/utils';
export function GetData(url, data, type){
  return new Promise((resolve, reject)=>{
      fetch(type=='login'?BASE_URL+url:FEEDS_API_Basepath+url,
      {
          method:'GET',
          headers:{
              "Content-Type":'application/json',
          }
      })
      .then((response)=>response.json())
      .then((responseJson)=>{            
          resolve(responseJson);
      })
      .catch((error)=>{
          reject(error);
      })
  })
}