import axios from "axios";
import { BASE_URL } from "../utils/utils";
import { toast } from "react-toastify";

let token =null;
if (typeof localStorage !== 'undefined') {
  const userDetails = JSON.parse(localStorage.getItem("useDetails"));
  token = userDetails?.access_token;
  
}

if (token) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${token?.deviceToken}`;
} else {
  delete axios.defaults.headers.common["Authorization"];
}

export const axiosReq = axios.create({
  baseURL: BASE_URL,
  headers: {
    // "Content-Type": "application/json",
    "Accept": "*/*",
  },
});

axiosReq.interceptors.request.use(
  (req) => {
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axiosReq.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status === 401) {
      window.localStorage.clear();
      toast.error("Unauthorized!");
      window.location.href = "/login";
    } else {
      toast.error(err.message || "Something went wrong!");
    }
    return Promise.reject(err);
  }
);
