import { useEffect, useState } from "react";
// import { changePassword, getUserInfo } from "../../../api/OurService";

import * as Yup from "yup";
import { Loadingicon } from "../../../../AppIcons";
import BreadCrumbs from "../../../common/BreadCrumbs";
import { Formik, useFormik } from "formik";
import { useAuth } from "../../../../Context/AuthContext";
import { Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';

import { Link, useNavigate, useParams } from "react-router-dom";
// import { BASE_URL, CNEWS_BASE_URL, DC_BASE_URL, EX_BASE_URL, FF_BASE_URL, INTBANK_BASE_URL, LYRICS_BASE_URL, QBANK_BASE_URL, QUOTES_BASE_URL, SHOWTALENT_BASE_URL, formatDate } from "../../../utils/utils";
import { getTaskCategories, getTaskList, getTaskSubCategories, getUserInfo } from "../../../../api/OurService";
import CustomSelect from "../../../common/customselect";
import { SHORT_TASK_ASSETS_BASE_URL, slugify } from "../../../../utils/utils";


function TaskSubCategory() {
    const [loading, setLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [isCatLoading, setIsCatLoading] = useState(false);
    const [moreLoader, setMoreLoader] = useState(false);
    const [allTasks, setAllTasks] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [taskSubCats, setTaskSubCats] = useState([]);
    const navigate = useNavigate()
    const { gigToken, globalToken } = useAuth();
    const { taskCatName } = useParams();

    const handleTaskSubCatList = async (pageNo, catId) => {
        setPageNumber(1)
        // setLoading(true);
        const token = globalToken;
        const res = await getTaskSubCategories({ token: token, page: pageNo, catId: catId, all: catId ? null : 1 });
        if (res?.status == 1) {
            if (res?.Data?.data) {
                if (res?.Data?.current_page === pageNumber) {
                    setTaskSubCats(res?.Data);
                } else {
                    setTaskSubCats({ ...res?.Data, data: [...taskSubCats?.data, ...res?.Data?.data] });
                    setPageNumber(res?.Data?.current_page);
                }
            } else {
                setTaskSubCats({data:res?.Data});
            }
        } else {
            // toast.error(res?.message)
        }
        setLoading(false);
        setFirstLoading(false);
        setMoreLoader(false);
    }

    const defaultFetchSubCat = (pageNo) => {
        if (taskCatName) {
            if (taskCatName == 'subcategory') {
                handleTaskSubCatList(pageNo, null);
            } else {
                const catSplit = taskCatName.split('-');
                if (catSplit?.length > 1) {
                    handleTaskSubCatList(pageNo, catSplit[catSplit?.length - 1]);
                } else {
                    handleTaskSubCatList(pageNo, taskCatName);
                }

            }
        }
    }
    useEffect(() => {
        defaultFetchSubCat(pageNumber);
    }, [gigToken])



    const loadMoreTasksCats = () => {
        setMoreLoader(true)
        const nxtPage = pageNumber + 1;
        defaultFetchSubCat(nxtPage);
    }

    // const onSearchChange = (e) =>{
    //     if(e.key==='Enter'){
    //         setBtnLoading(true);
    //         handleSearchFilter(choosenDefaultCat, choosenTaskSubCat, 1, '');
    //     }
    // }
    return (
        <>
            <BreadCrumbs />

            <div className=" py-4 px-md-4 grid grid-cols-1 group-card card-body gap-4">
                {firstLoading || loading ? (
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                ) : (
                    <>
                        {/* <div className="card mb-3 border-0 shadow">
                            <div className="card-body d-flex align-items-center gap-2">
                                {
                                    !taskCatsFormatted?.length ? null :
                                        <div className="flex-1">
                                           

                                            <CustomSelect options={taskCatsFormatted} defaultOption={choosenDefaultCat} handleCustomSelectOnChange={handleCustomSelectOnChange} type={'taskCat'} isSelectLoading={isCatLoading} placeholder={'Task Category'} />
                                        </div>
                                }
                                {
                                    !taskSubCatsFormatted?.length ? null :
                                        <div className="flex-1">

                                            <CustomSelect options={taskSubCatsFormatted} defaultOption={choosenDefaultSubCat} handleCustomSelectOnChange={handleCustomSelectOnChange} type={'taskSubCat'} isSelectLoading={isSubCatLoading} placeholder={'Task Sub Category'} />

                                        </div>}
                                <div className="flex-1">
                                    <input type="search" onKeyUp={(e)=>onSearchChange(e)} onChange={(e) => setSearchTitle(e.target.value)} placeholder="Search Short Task by Title" className="form-control" />
                                </div>
                                <div className="f">
                                    <button type="button" disabled={!btnLoading ? false : true} onClick={() =>{ handleSearchFilter(choosenDefaultCat, choosenTaskSubCat, 1,'');setBtnLoading(true)}} className="btn bg-theme text-white" style={{ backgroundColor: !btnLoading ? '#ff7200' : '#ccc' }}>  {btnLoading ? (
                                        <div className="h-5 w-5">
                                            <Loadingicon />
                                        </div>
                                    ) : (
                                        "Search"
                                    )}</button>
                                </div>
                            </div>
                        </div> */}
                        <div className="px-0">
                            <div className="card mb-3 border-0 shadow">
                                <div className="card-body gig-table">
                                    {
                                        !taskSubCats?.data?.length ?
                                            <div className="flex-column not-found d-flex text-center" >
                                                <img src={notFound} className="notFoundImg" />
                                                <span style={{ opacity: 0.7 }}>No subcategory found</span>
                                            </div> : <div className="row">
                                                {
                                                    taskSubCats?.data?.map((taskSubCat, index) => (
                                                        <div className="col-md-2 px-md-2 mb-4" key={'taskcat' + index}>
                                                            <article className="hp-listing-category hp-listing-category--view-block">

                                                                <Link to={`/gigs/${taskCatName!=='subcategory'?taskCatName:'subcategory-'+taskSubCat?.parent_id}/${slugify(taskSubCat.name)}-${taskSubCat.id}/tasks`}>
                                                                    <div className="hp-listing-category__header"><div className="hp-listing-category__image">
                                                                        <span className="placeholder-thumbnail">
                                                                            {/* @if($index<=8)
                                            <img src="{{$imgUrls[$index]}}" width="70" height="70" className="rounded mx-auto  my-3"/>
                                        @else */}
                                                                            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="button" style={{ width: '100%', opacity: 1, height: 80 }}>
                                                                                <path d="M19 5v14H5V5h14m1.1-2H3.9c-.5 0-.9.4-.9.9v16.2c0 .4.4.9.9.9h16.2c.4 0 .9-.5.9-.9V3.9c0-.5-.5-.9-.9-.9zM11 7h6v2h-6V7zm0 4h6v2h-6v-2zm0 4h6v2h-6zM7 7h2v2H7zm0 4h2v2H7zm0 4h2v2H7z"></path>
                                                                            </svg>
                                                                            {/* @endif */}

                                                                        </span>
                                                                    </div></div><div className="hp-listing-category__content text-center">
                                                                        <p> </p>
                                                                        <h6 className="hp-listing-category__name mb-0 mt-4">{taskSubCat?.name}</h6>
                                                                        <p className="text-black-50 text-center lead mb-0" style={{ fontSize: '.95rem' }}>{taskSubCat?.COUNT} Tasks</p>

                                                                    </div></Link></article>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                    }
                                    {
                                        taskSubCats?.next_page_url ?
                                            <div className="text-center w-100 mt-4">
                                                <button id="loadMoreBtn" disabled={moreLoader} type="button" onClick={() => loadMoreTasksCats()} className="btn text-white m-auto d-flex justify-content-center">
                                                    {moreLoader ? (
                                                        <div className="h-5 w-5">
                                                            <Loadingicon />
                                                        </div>
                                                    ) : (
                                                        "Load More"
                                                    )}
                                                </button>
                                            </div> : null
                                    }

                                </div>
                            </div>
                        </div>
                    </>
                )
                }
            </div>

        </>
    );
}
export default TaskSubCategory;
