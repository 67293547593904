import React, {FC, useState, useEffect} from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
// import './PhoneNumberInput.css'; // Import your custom CSS file

const PhoneNumberInput = ({ value, onChange, disabled, defaultDialCode = '' }) => {
  const [defaultCountry, setDefaultCountry] = useState(null);

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        if(!defaultDialCode){
          const response = await axios.get('https://ipapi.co/json/');
          setDefaultCountry((response.data.country_code).toLowerCase());
        }else{
          const response = await axios.get(`https://restcountries.com/v2/callingcode/${defaultDialCode}`);
          if (response.data && response.data.length > 0) {
            // console.log(response.data[0].alpha2Code)
            const countryCode = response.data[0].alpha2Code.toLowerCase(); // Extract country code
            setDefaultCountry(countryCode);
            // console.log('dd', value)
          }
        }        
        // console.log(response.data.country_code)
      } catch (error) {
        console.error('Error fetching country code:', error);
      }
    };

    fetchCountryCode();
  }, []);

  return (
    <div className="phone-input-container" style={{width:'100%',marginBottom:10}}> {/* Apply the custom CSS class */}
      <PhoneInput
        country={defaultCountry}
        value={value}
        onChange={onChange}
        inputStyle={{width:'100%',borderRadius:'0.5rem',height:42,cursor:'default'}}
        dropdownStyle={{borderTopLeftRadius:'0.5rem'}}
        buttonStyle={{borderTopLeftRadius:'0.5rem',borderBottomLeftRadius:'0.5rem'}}
        countryCodeEditable={false}
        disableSearchIcon={true}
        enableSearch={true}
        disabled={disabled}
        autoFormat={false}
        
      />
    </div>
  );
};

export default PhoneNumberInput;
