import { useEffect, useRef, useState } from "react";
// import { changePassword, getUserInfo } from "../../../api/OurService";

import * as Yup from "yup";
import { Loadingicon } from "../../../../AppIcons";
import BreadCrumbs from "../../../common/BreadCrumbs";
import { ErrorMessage, Field, Formik, useFormik, useFormikContext } from "formik";
import { useAuth } from "../../../../Context/AuthContext";
import { Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Editor } from '@tinymce/tinymce-react';

import { Link, useNavigate, useParams } from "react-router-dom";
// import { BASE_URL, CNEWS_BASE_URL, DC_BASE_URL, EX_BASE_URL, FF_BASE_URL, INTBANK_BASE_URL, LYRICS_BASE_URL, QBANK_BASE_URL, QUOTES_BASE_URL, SHOWTALENT_BASE_URL, formatDate } from "../../../utils/utils";
import { createCampaign, getCountries, getGigsConfigurations, getTaskCategories, getTaskList, getTaskSubCategories, getUserInfo, startTask } from "../../../../api/OurService";
import CustomSelect from "../../../common/customselect";
import { CDN_BASE_URL, SHORT_TASK_ASSETS_BASE_URL, TINY_EDITOR_KEY, capitalizeTitle, formatDate, scrollToFirstError, slugify } from "../../../../utils/utils";
import TaskNavigationTab from "../navigationtab";
import SectionDescription from "../../../common/sectionDescription";


function CreateCampaign() {
    // const formik = useFormik();
    const [loading, setLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [isCatLoading, setIsCatLoading] = useState(false);
    const [moreLoader, setMoreLoader] = useState(false);
    const [isSubCatLoading, setIsSubCatLoading] = useState(false);
    const [isCatShow, setIsCatShow] = useState(false);
    const { user, globalToken, setIsEmailVerifyCover } = useAuth();
    const [taskCats, setTaskCats] = useState([]);
    const [taskSubCats, setTaskSubCats] = useState([]);
    const [taskCatsFormatted, setTaskCatsFormatted] = useState([]);
    const [taskSubCatsFormatted, setTaskSubCatsFormatted] = useState([]);
    const [frequencyCapFormatted, setFrequencyCapFormatted] = useState([]);
    const [ageRangeFormatted, setAgeRangeFormatted] = useState([]);
    const [freqencyCap, setFrequencyCap] = useState();
    const moderateEditorRef = useRef(null);
    const [choosenTaskCat, setChoosenTaskCat] = useState();
    const [choosenTaskSubCat, setChoosenTaskSubCat] = useState();
    const [choosenDefaultSubCat, setChoosenDefaultSubCat] = useState();
    const [choosenDefaultCat, setChoosenDefaultCat] = useState();
    const [allCountries, setAllCountries] = useState([]);
    const [searchTitle, setSearchTitle] = useState('');
    const [allTasks, setAllTasks] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const navigate = useNavigate()
    const { gigToken } = useAuth();
    const [isCountryLoading, setIsCountryLoading] = useState(true);
    const [countryFormatted, setCountryFormatted] = useState();
    const { taskCatName, taskSubCatName } = useParams();
    let cId = taskCatName?.split('-').reverse()[0];
    let scId = taskSubCatName?.split('-').reverse()[0];
    const [isAllCountry, setIsAllCountry] = useState(false);
    const [isPartialCountry, setIsPartialCountry] = useState(false);
    const DisplayingErrorMessagesSchema = Yup.object().shape({

        scat_id: Yup.string().required("Sub Category is required"),
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
        // moderator_notes: Yup.string().required("Moderate notes is required"),
        proof_requirement: Yup.string().required("Proof requirement is required"),
        per_task_cost: Yup.string().required("Per task credit is required"),
        cat_id: Yup.string().required("Category is required"),
        country_ids: Yup.array().min(1, "At least one country is required"),
        frequency_id: Yup.string().required("Can Repeat is required"),
        time_interval: Yup.string().required("Completion Time is required"),
    });

    const DisplayingErrorMessagesAllCountrySchema = Yup.object().shape({

        scat_id: Yup.string().required("Sub Category is required"),
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
        // moderator_notes: Yup.string().required("Moderate notes is required"),
        proof_requirement: Yup.string().required("Proof requirement is required"),
        per_task_cost: Yup.string().required("Per task credit is required"),
        cat_id: Yup.string().required("Category is required"),
        frequency_id: Yup.string().required("Can Repeat is required"),
        time_interval: Yup.string().required("Completion Time is required"),
    });


    const showHidecountryList = (checkbox, setFieldValue) => {
        const { checked, value } = checkbox.target;
        if (checked) {
            setIsAllCountry(true)
            setFieldValue('country_ids', [Number(value)])
        } else {
            setIsAllCountry(false)
            setFieldValue('country_ids', [])
        }
    }
    const handleGigsConfigurations = async (conf, catId = null) => {
        try {
            // const token = JSON.parse(localStorage.getItem('gigsToken')).token;
            const gigsConfRes = await getGigsConfigurations({ token: globalToken, conf: conf, cat_id: catId });
            // console.log(gigsConfRes)
            if (gigsConfRes?.success) {
                if (conf == 'country') {
                    const countryData = gigsConfRes.data?.country?.map(option => ({
                        value: option.id,
                        label: option.countryname,
                        flag: CDN_BASE_URL + option?.image
                    })) || [];
                    setCountryFormatted(countryData);

                }
                if (conf == 'cat') {
                    const categoryData = gigsConfRes.data?.category?.map(option => ({
                        value: option.id,
                        label: option.name
                    })) || [];
                    setTaskCatsFormatted(categoryData);
                }
                if (conf == 'freq') {
                    const freqData = gigsConfRes.data?.frequency?.map(option => ({
                        value: option.id,
                        label: option.frequency
                    })) || [];
                    setFrequencyCapFormatted(freqData);
                }
                if (conf == 'subcat') {
                    setTaskSubCatsFormatted([]);
                    setChoosenDefaultSubCat(null);
                    const subcategoryData = gigsConfRes.data?.subcategory?.map(option => ({
                        value: option.id,
                        label: option.name
                    })) || [];
                    setTaskSubCatsFormatted(subcategoryData);
                    setChoosenDefaultSubCat(subcategoryData[0]);
                }

                if (conf == 'AgeRange') {

                    const ageRangeData = gigsConfRes.data?.AgeRange?.map(option => ({
                        value: option.id,
                        label: option.range + ' Years'
                    })) || [];
                    // console.log('age range', ageRangeData)
                    setAgeRangeFormatted(ageRangeData);
                }

                // setCountryFormatted(countryData);
                // setTaskCatsFormatted(categoryData);
                // setTaskSubCatsFormatted(subcategoryData);
                // setChoosenDefaultSubCat(subcategoryData[0]);

                setIsCountryLoading(false);
                setIsCatLoading(false);
                setFirstLoading(false);
            }
        } catch (error) {
            console.error('Error fetching gigs configurations:', error);
            // Handle the error, e.g., display an error message to the user
        }
    }



    const handleTaskCatList = async () => {
        setIsCatLoading(true)
        // const token = JSON.parse(localStorage.getItem('gigsToken')).token;
        const res = await getTaskCategories({ token: globalToken });
        if (res?.status == 1) {
            const formattedCat = res?.Data?.data.map(option => ({
                value: option.id,
                label: option.name
            }));

            setTaskCatsFormatted(formattedCat);
            setChoosenDefaultCat(formattedCat[0]);
            setChoosenTaskCat(formattedCat[0]?.value);
            handleTaskSubCatList(formattedCat[0]?.value);

            setTaskCats(res?.Data?.data);
        } else {
            toast.error(res?.message)
        }
        setIsCatLoading(false)
        setFirstLoading(false)
    }

    const handleTaskSubCatList = async (catId) => {
        // if (catId) {
        //     setIsSubCatLoading(true)

        //     const token = JSON.parse(localStorage.getItem('gigsToken')).token;
        //     const res = await getTaskSubCategories({ token: token, catId: catId });

        //     if (res?.status == 1) {
        //         const formattedSubCat = res?.Data?.data.map(option => ({
        //             value: option.id,
        //             label: option.name
        //         }));
        //         setTaskSubCatsFormatted(formattedSubCat);
        //         setChoosenDefaultSubCat(formattedSubCat[0]);
        //         setChoosenTaskSubCat(formattedSubCat[0].value);
        //         setTaskSubCats(res?.Data?.data);
        //     } else {
        //         toast.error(res?.message)
        //     }
        //     setIsSubCatLoading(false);
        //     setIsCatLoading(false)
        // }
    }

    useEffect(() => {
        handleGigsConfigurations('country', null);
        handleGigsConfigurations('cat', null);
        handleGigsConfigurations('freq', null);
        handleGigsConfigurations('AgeRange', null);
        // handleGigsConfigurations('subcat');
        // handleTaskCatList();
        // handleCountryList();
    }, [])


    let allChoosenCountryIds = [];
    const handleCustomSelectOnChange = (value, type, label, setFieldValue) => {

        if (type == 'taskCat') {
            setTaskSubCatsFormatted([]);
            setChoosenDefaultSubCat(null);
            setChoosenTaskCat(value)
            // handleTaskSubCatList(value);

            handleGigsConfigurations('subcat', value);
            setFieldValue('cat_id', value);
        }
        if (type == 'taskSubCat') {
            setIsSubCatLoading(false);
            setIsCatLoading(false);
            setChoosenTaskSubCat(value);
            setFieldValue('scat_id', value);
        }
        if (type == 'country') {

            let cValue = value?.map(cV => (cV?.value))
            setFieldValue('country_ids', cValue);
            allChoosenCountryIds = cValue;
            if (allChoosenCountryIds?.length > 0) {
                setIsPartialCountry(true)
            } else {
                setIsPartialCountry(false)
            }
        }

    }

    const handleCreateCampaign = async (values) => {
       
        setBtnLoading(true)
        // const token = JSON.parse(localStorage.getItem('gigsToken')).token;
        const createCampaignParams = {
            ...values,
            cat_id: choosenTaskCat,
            scat_id: choosenTaskSubCat,
            user_id: gigToken?.id
        }

        const res = await createCampaign({ token: globalToken, createCampaignParams }, 'create');

        setFirstLoading(false);
        if (res?.success) {
            toast.success(res?.message);
            navigate('/gigs/my-campaign')
        } else {
            if(res?.status==5){
                toast.error(res?.errors);
                setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
            }else{
                toast.error(res?.errors)
            }
        }
        setBtnLoading(false)
    }


    // const handleCountryList = async () => {
    //     const res = await getCountries({ token: gigToken?.token });
    //     setAllCountries(res?.Country)
    // }
    // const  genderData =[
    //     {
    //         label:'Male',
    //         value:'male'
    //     },
    //     {
    //         label:'Female',
    //         value:'female'
    //     }
    // ]

    const formData = [
        {
            id: 'title',
            title: 'Title',
            type: 'text',
            name: 'title',
            placeholder: 'e.g 1 Minute Survey on Pets',
            required: true,
            editable: true
        },
        // {
        //     id: 'moderator_notes',
        //     title: 'Moderator Notes',
        //     type: 'text',
        //     name: 'moderator_notes',
        //     placeholder: 'Moderator Notes',
        //     required: true,
        //     editable: true,
        //     width: true,
        //     isEditor: true
        // }, 
        {
            id: 'cat_id',
            title: 'Category',
            type: 'text',
            name: 'cat_id',
            placeholder: 'Pick from the list',
            required: true,
            editable: false,
        }, {
            id: 'scat_id',
            title: 'Sub Category',
            type: 'text',
            name: 'scat_id',
            placeholder: 'Pick from the list',
            required: true,
            editable: false,
            half: true
        }
        ,
        {
            id: 'age',
            title: 'Select Target Audience By Age Range',
            type: 'number',
            name: 'age',
            placeholder: 'Pick from the list',
            required: false,
            editable: true,
            half: true
        }, {
            id: 'gender',
            title: 'Select Target Audience By Gender',
            type: 'text',
            name: 'gender',
            placeholder: 'Pick from the list',
            required: false,
            editable: true,
            isGender: true,
            half: true
        }, {
            id: 'country_ids',
            title: 'Select Target Audience By Country(s)',
            type: 'text',
            name: 'country_ids',
            placeholder: 'Pick one or more from the list',
            required: true,
            editable: true,
            isCountry: true
        },
        {
            id: 'description',
            title: 'Enter Your Campaign Description',
            type: 'text',
            name: 'description',
            placeholder: 'Describe the objectives of your campaign, outline the steps/tasks to be performed by participants, and include any important links or resources related to the campaign',
            required: true,
            editable: true,
            width: true,
            isEditor: true
        },
        {
            id: 'proof_requirement',
            title: 'Select Type Of Proof Required',
            type: 'text',
            name: 'proof_requirement',
            placeholder: 'Pick from the list',
            required: true,
            editable: true,
        },
        {
            id: 'proof_desc',
            title: 'Specify Task Validation Criteria',
            type: 'text',
            name: 'proof_desc',
            placeholder: 'Describe how participants should provide proof of completing tasks. E.g Browser history screenshot, System Time snapshot, Screen screenshots etc',
            required: false,
            editable: true,
            width: true,
            isEditor: true
        }, {
            id: 'frequency_id',
            title: 'Allow Task To Be Repeated',
            type: 'number',
            name: 'frequency_id',
            placeholder: 'Pick from the list',
            required: true,
            editable: true,
            isFrequency: true,
            third:true
        }, {
            id: 'time_interval',
            title: 'Time Allowed to Complete Task (In Minutes)',
            type: 'number',
            name: 'time_interval',
            placeholder: 'e.g 1',
            required: true,
            editable: true,
            isTimeInterval: true,
            third: true
        }, {
            id: 'per_task_cost',
            title: 'Credit Rate Per Approved Task',
            type: 'number',
            name: 'per_task_cost',
            placeholder: 'e.g 100',
            required: true,
            editable: true,
            third: true
        }
    ]

    return (
        <>
            <BreadCrumbs />


            {firstLoading || loading ? (
                <div className=" py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4 shadow"><div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                    <div className="h-10 w-10">
                        <Loadingicon />
                    </div>
                </div></div>
            ) : (
                <>
               
                    <div className="mb-2 py-2 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4">
                        <TaskNavigationTab tabType={'createcampaign'} />
                    </div>
                    <div className=" pb-0 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4 shadow">
                        <div className="card mb-3">
                            <div className="card-body d-flex flex-column align-items-centers gap-2">
                                 <div className="d-flex align-items-center justify-content-between">
                <SectionDescription title={'New Campaign Setup'} subTitle={'Fill out the form below to provide campaign information and set preferences. Fields marked (*) are mandatory.'}/>

                
                </div>
                
            <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-0  pb-4" />
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        title: "",
                                        description: "",
                                        // moderator_notes: "",
                                        proof_requirement: "",
                                        proof_desc: "",
                                        cat_id: "",
                                        scat_id: "",
                                        per_task_cost: "",
                                        task_thumbnails: null,
                                        gender: '',
                                        age:1,
                                        country_ids: [],
                                        frequency_id: "",
                                        time_interval: ""

                                    }}
                                    
                                    validationSchema={isAllCountry ? DisplayingErrorMessagesAllCountrySchema : DisplayingErrorMessagesSchema}
                                    onSubmit={(values, validateForm) => {

                                        handleCreateCampaign(values)
                                    }}
                                   
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        setFieldValue,
                                        handleSubmit
                                    }) => {

                                        

                                        return (
                                        <form className="" onSubmit={handleSubmit}>
                                            <div className="row">
                                                {
                                                    formData?.map((fieldMeta, index) => (
                                                        <div key={'createCamp' + index} className={`${fieldMeta.width ? 'col-md-12' : (fieldMeta?.id == 'cat_id' && !taskSubCatsFormatted?.length) ? 'col-md-12' : (fieldMeta?.id == 'cat_id' && taskSubCatsFormatted?.length) ? 'col-md-6' : (fieldMeta?.id == 'scat_id' && taskSubCatsFormatted?.length && fieldMeta?.half) ? 'col-md-6' : (fieldMeta?.id == 'scat_id' && !taskSubCatsFormatted?.length && fieldMeta?.half) ? 'col-md-6 d-none' : fieldMeta?.id !== 'scat_id' && fieldMeta?.half ? 'col-md-6' : (fieldMeta?.id=='frequency_id' || fieldMeta?.id=='time_interval' || fieldMeta?.id=='per_task_cost') && fieldMeta?.third?'col-md-4':'col-md-12'}`} style={{ marginTop: (fieldMeta?.id == 'country_ids' && taskSubCatsFormatted?.length == 0) ? 0 : 0 }}>

                                                            {
                                                                fieldMeta?.id !== 'scat_id' ? <label
                                                                    htmlFor={fieldMeta?.id}
                                                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                                                                    style={{fontSize:'.775rem',color: '#4d4d4d',fontWeight:'bold'}}
                                                                >
                                                                    {fieldMeta?.title}

                                                                    {
                                                                        fieldMeta?.required ? <span className="text-red-400 text-base">*</span> : null
                                                                    }

                                                                </label> : null
                                                            }

                                                            <input type="password" name="fake-password" autoComplete="new-password" style={{ display: 'none' }} />
                                                            {/* <input type="text" autoComplete="off" name="fake-email" style={{ display: 'none' }} /> */}
                                                            {
                                                                fieldMeta.width ?
                                                                    <>
                                                                        {
                                                                            fieldMeta?.isEditor ?
                                                                                <div style={{ marginBottom: 20 }}>
                                                                                    <Editor

                                                                                        apiKey={TINY_EDITOR_KEY}
                                                                                        onInit={(evt, editor) => moderateEditorRef.current = editor}

                                                                                        init={{
                                                                                            height: '200',
                                                                                            auto_focus: true,
                                                                                            menubar: false,
                                                                                            plugins: 'hr lists table textcolor code link image',
                                                                                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic forecolor link image media | alignleft aligncenter alignright | hr bullist numlist table | subscript superscript | removeformat code',

                                                                                            // allow custom url in link? nah just disabled useless dropdown..
                                                                                            file_picker_types: 'image',
                                                                                            file_picker_callback: function (cb, value, meta) {
                                                                                                var input = document.createElement('input');
                                                                                                input.setAttribute('type', 'file');
                                                                                                input.setAttribute('accept', 'image/*');
                                                                                                input.onchange = () => {
                                                                                                    const file = input.files[0];
                                                                                                    const reader = new FileReader();

                                                                                                    reader.onload = () => {
                                                                                                        const base64 = reader.result.split(',')[1];
                                                                                                        cb('data:image/jpeg;base64,' + base64);
                                                                                                    };

                                                                                                    reader.readAsDataURL(file);
                                                                                                };

                                                                                                input.click();
                                                                                            },
                                                                                            anchor_top: false,
                                                                                            anchor_bottom: false,
                                                                                            branding: false,
                                                                                            loading: true
                                                                                        }}
                                                                                        onEditorChange={(content, editor) => {
                                                                                            setFieldValue(fieldMeta?.id, content);

                                                                                        }}
                                                                                    />
                                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                    </div>
                                                                                </div> : <>
                                                                                    <textarea type={fieldMeta?.type}

                                                                                        name={fieldMeta?.name}
                                                                                        autoComplete={'off'}
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        value={values[fieldMeta?.id] || ""}
                                                                                        id={fieldMeta?.name} rows={6} placeholder={fieldMeta.title} className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"></textarea>
                                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                    </div></>
                                                                        }

                                                                    </>
                                                                    : fieldMeta.isGender ?
                                                                        <>
                                                                            <select
                                                                                name={fieldMeta?.name}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values[fieldMeta?.id] || ""}
                                                                                id={fieldMeta?.name} placeholder={fieldMeta.title} className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                                                                                <option value="">All</option>
                                                                                <option value="male">Male</option>
                                                                                <option value="female">Female</option>
                                                                            </select>
                                                                            <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                            </div>
                                                                        </>
                                                                        : fieldMeta.isCountry ?
                                                                            <>
                                                                                {/* <select
                                                                                    name={fieldMeta?.name}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    value={values[fieldMeta?.id] || ""}
                                                                                    id={fieldMeta?.name} placeholder={fieldMeta.title} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                                                                                    <option value="" selected disabled>Select Country</option>
                                                                                    {
                                                                                        allCountries?.length && allCountries?.map(country => (
                                                                                            <option value={country.id}>{country?.name}</option>
                                                                                        ))
                                                                                    }

                                                                                </select> */}
                                                                                {
                                                                                    countryFormatted?.length ?
                                                                                        <div className="d-flex align-items-center position-relative gap-2" style={{zIndex:10}}>
                                                                                            <div className="flex-1" style={{ pointerEvents: isAllCountry ? 'none' : 'all', opacity: isAllCountry ? 0.4 : 1 }}>
                                                                                                <CustomSelect options={countryFormatted} isMultiple={true} isClearable={true} handleCustomSelectOnChange={handleCustomSelectOnChange}
                                                                                                    setFieldValue={setFieldValue}
                                                                                                    type={'country'} isSelectLoading={isCountryLoading} placeholder={'Country'} />
                                                                                            </div>

                                                                                            <label htmlFor="allCountry" style={{ opacity: isPartialCountry ? 0.4 : 1, pointerEvents: isPartialCountry ? 'none' : 'all' }}>
                                                                                                <input type="checkbox" value={Number(251)} setFieldValue={setFieldValue} onChange={(e) => showHidecountryList(e, setFieldValue)} id="allCountry" /> All Country
                                                                                            </label></div> : null
                                                                                }

                                                                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                    {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                </div>
                                                                            </>
                                                                            : fieldMeta?.id == 'age' ?
                                                                                <>
                                                                                    <select
                                                                                        name={fieldMeta?.name}
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        value={values[fieldMeta?.id] || ""}
                                                                                        id={fieldMeta?.name} rows={6} placeholder={fieldMeta.title} className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                                                                                        {
                                                                                            ageRangeFormatted?.map((option, index) => (
                                                                                                <option key={index + 'ageRange'} value={option?.value}>{capitalizeTitle(option?.label)}</option>
                                                                                            ))
                                                                                        }

                                                                                    </select>
                                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                    </div>
                                                                                </>
                                                                                : fieldMeta.id == 'proof_requirement' ?
                                                                                    <>
                                                                                        <select
                                                                                            name={fieldMeta?.name}
                                                                                            onChange={handleChange}
                                                                                            onBlur={handleBlur}
                                                                                            value={values[fieldMeta?.id] || ""}
                                                                                            id={fieldMeta?.name} rows={6} placeholder={fieldMeta.title} className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                                                                                            <option value="" selected disabled></option>
                                                                                            <option value="Text Input Only">Text Input Only</option>
                                                                                            <option value="Screenshots">Screenshots</option>
                                                                                            <option value="Screenshots & Text Only">Screenshots & Text Only</option>
                                                                                        </select>
                                                                                        <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                            {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                        </div>
                                                                                    </>
                                                                                    : fieldMeta?.id == 'cat_id' ?
                                                                                        <>
                                                                                            {
                                                                                                !taskCatsFormatted?.length ? null :
                                                                                                    <div className="flex-1 position-relative" style={{zIndex:11}}>
                                                                                                        <CustomSelect name={fieldMeta?.name} options={taskCatsFormatted} handleCustomSelectOnChange={handleCustomSelectOnChange}
                                                                                                            setFieldValue={setFieldValue}
                                                                                                            type={'taskCat'}
                                                                                                            isSelectLoading={isCatLoading}
                                                                                                            isClearable={false}
                                                                                                            placeholder={'Pick from the list'} />
                                                                                                    </div>
                                                                                            }
                                                                                            <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                                {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                            </div>
                                                                                        </>
                                                                                        : fieldMeta?.id == 'scat_id' ?
                                                                                            <div className="position-relative" style={{zIndex:11}}>
                                                                                                {
                                                                                                    !taskSubCatsFormatted?.length ? null : <label
                                                                                                        htmlFor={fieldMeta?.id}
                                                                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                                                                                                        style={{fontSize:'.775rem',color: '#4d4d4d',fontWeight:'bold'}}
                                                                                                    >
                                                                                                        {fieldMeta?.title}

                                                                                                        {
                                                                                                            fieldMeta?.required ? <span className="text-red-400 text-base">*</span> : null
                                                                                                        }
                                                                                                    </label>
                                                                                                }

                                                                                                {
                                                                                                    !taskSubCatsFormatted?.length ? null :
                                                                                                        <><div className="flex-1" style={{zIndex:11}}>
                                                                                                            <CustomSelect name={fieldMeta?.name} options={taskSubCatsFormatted} handleCustomSelectOnChange={handleCustomSelectOnChange} type={'taskSubCat'}
                                                                                                                setFieldValue={setFieldValue}
                                                                                                                isSelectLoading={isSubCatLoading}
                                                                                                                placeholder={'Pick from the list'}
                                                                                                                isClearable={false} />
                                                                                                        </div>
                                                                                                            <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                                                {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                                            </div>
                                                                                                        </>
                                                                                                }

                                                                                            </div>
                                                                                            : fieldMeta?.isFrequency ?
                                                                                                <>
                                                                                                    <select
                                                                                                        name={fieldMeta?.name}
                                                                                                        onChange={handleChange}
                                                                                                        onBlur={handleBlur}
                                                                                                        value={values[fieldMeta?.id] || ""}
                                                                                                        id={fieldMeta?.name} placeholder={fieldMeta.title} className=" border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                                                                                                        <option value="" selected disabled>Can Repeat</option>
                                                                                                        {
                                                                                                            frequencyCapFormatted?.map((freqOption, index) => (
                                                                                                                <option key={'fc' + index} value={freqOption?.value}>After {freqOption?.label}</option>
                                                                                                            ))
                                                                                                        }


                                                                                                    </select>
                                                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                                    </div>
                                                                                                </>
                                                                                                //     fieldMeta?.isTimeInterval?
                                                                                                //    <> <select
                                                                                                //     name={fieldMeta?.name}
                                                                                                //     onChange={handleChange}
                                                                                                //     onBlur={handleBlur}
                                                                                                //     value={values[fieldMeta?.id] || ""}
                                                                                                //     id={fieldMeta?.name} placeholder={fieldMeta.title} className="bg-gray-50 border border-gray-300 text-gray-900 mb-4 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                                                                                                //     <option value="" selected disabled>Select Time Interval</option>
                                                                                                //     <option value={1}>5</option>
                                                                                                //     <option value={2}>10</option>
                                                                                                //     <option value={3}>15</option>
                                                                                                //     <option value={4}>20</option>

                                                                                                // </select></>
                                                                                                : <>
                                                                                                    <input
                                                                                                        disabled={!fieldMeta?.editable}
                                                                                                        type={fieldMeta?.type}
                                                                                                        name={fieldMeta?.id}
                                                                                                        autoComplete={'off'}
                                                                                                        id={fieldMeta?.name}
                                                                                                        className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                                                                        placeholder={fieldMeta?.title}
                                                                                                        onChange={handleChange}
                                                                                                        onBlur={handleBlur}
                                                                                                        value={values[fieldMeta?.id] || ""}
                                                                                                    />
                                                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                                    </div>
                                                                                                </>
                                                            }


                                                        </div>
                                                    ))
                                                }
                                                <div className="col-md-12 mb-3">
                                                    <label className="mb-2" style={{fontSize:'.775rem',color: '#4d4d4d',fontWeight:'bold'}}>Set Campaign Image (Minimum 767px X 500px | Upto 1 MB | Supported Formats JPG,PNG,BMP,WEBP)</label>
                                                    <input type="file" onChange={(event) => {
                                                        setFieldValue('task_thumbnails', event.currentTarget.files[0]);
                                                    }}
                                                        onBlur={handleBlur}
                                                        className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                        name="task_thumbnails" />
                                                </div>
                                            </div>
                                            {/* </div> */}
                                            <div className="col-md-2">
                                                <button
                                                    type="submit"
                                                    disabled={btnLoading}
                                                    className="mt-2 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center"
                                                >
                                                    {btnLoading ? (
                                                        <div className="h-5 w-5">
                                                            <Loadingicon />
                                                        </div>
                                                    ) : (
                                                        "Create"
                                                    )}
                                                </button>
                                            </div>
                                        </form>)
                                    }
                                    }

                                </Formik>
                                <small className="text-black-50 mt-3">Note* : Credits greater than or equal to "Credit Rate Per Approved Task", must be added to activate a campaign after you click "Create". </small>
                            
                               </div>
                               
                        </div>
                    </div>
                </>
            )
            }

        </>
    );
}
export default CreateCampaign;
