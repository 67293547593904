import React from 'react'
import { CDN_BASE_URL, nFormatter} from '../../../../utils/utils';
import qtThumbPlaceholder from '../../../../assets/images/qt_thumb.png';

export default function AuthorList({ author, boxSize=null, mH=false, redirectionFn, type, thumbPrefix=`showtalent/images/quotes/author/thumbs/`, sType=null }) {
    return (
        <div className={`${boxSize} mb-2 cursor-pointer hover-c-change`} onClick={() => redirectionFn()} title={author?.name}>
            <div className={`col-md-12 col-12 py-0 col-sm-12 left-angle-border card_box ${mH ? 'mh-119' : 'mh-93'}`}>
                <div className='d-flex align-items-center'>
                    <span className="books_icon bg-transparent px-3">
                        {
                            // CDN_BASE_URL + `${thumbPrefix + author?.image}`
                            author?.image ? <img alt={author?.name} className="thumbnail rounded" width="80" height="80" src={qtThumbPlaceholder} /> : <img src={qtThumbPlaceholder} width="80" height="80" className="feature_images rounded  overflow-hidden" alt={author?.name} />
                        }
                    </span>
                    <span className="w-100 d-flex flex-column">
                        <h6 className="m-0 pr-3 ellipsis-text lquestion" title={author?.name}>
                            {author?.name}
                        </h6>
                        <span className="span pb-1 color-gray text-capitalize fsize-11 c-a5">
                            {
                                type == "author" ? <>{ nFormatter(author?.quote_count)} Quotes
                                </> : null
                            }
                        </span>
                    </span>
                </div>
                <i className='fa-solid fa-arrow-right-long me-2 text-black-50' style={{ opacity: 0.5 }}></i>
            </div>
        </div>
        // <div className={`d-flex w-100 hover-div flex-md-row flex-column justify-content-between align-items-center list-alter  lafter mb-2 py-2 pr-3 position-relative overflow-hidden cursor-pointer`} onClick={() => redirectionFn()} style={{ border: '1px solid #f1e4e4', borderLeft: '3px solid #ccc' }}>

        //     <div className="d-flex align-items-start p-md-2 pe-0 pe-md-2 py-md-2 py-2 px-2 w-100 w-md-auto ">

        //         <div className="thumbnail-containers mr-3">
        //             {
        //                 author?.image ?
        //                     <img src={CDN_BASE_URL + `${thumbPrefix + author?.image}`} width="80" height="80" className="rounded bg-gray" />
        //                     : <img src={`${qtThumbPlaceholder}`} width="80" height="80" className="rounded bg-gray" style={{ border: '1px solid #f1e4e4' }} />
        //             }

        //         </div>

        //         <div className="flex-1">
        //             <h6 className="mb-0 justify-content-between d-md-block d-flex align-items-start w-100 mt-0 text-capitalize title-task color-333">
        //                 <span className="theme-a cursor-pointer d-flex align-items-center gap-2 pr-md-0 pr-5"><strong>{author?.name}</strong> </span>
        //             </h6>


        //             <div className="w-100 text-black-50 text-capitalize mt-1 mb-0 d-md-flexs d-inline-block  align-items-center">
        //                {
        //                 type=="author"?<><small><span>{ nFormatter(author?.quote_count)} Quotes</span></small></>:null
        //                } 
                                       
        //             </div>
        //             <p className="mb-0 mt-1 color-gray fsize-meta desc-camp">{author?.quote_count} Inspirational and Famous Quotes by {author?.name}!</p>
        //         </div>
        //     </div>
        // </div>
    )
}
