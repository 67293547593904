import { Navigate, useLocation, useNavigate } from "react-router-dom";
import LandingScreen from "../components/landingScreen.js/LandingScreen";
import { useAuth } from "../Context/AuthContext";
import Login from "../components/auth/Login";

const PrivateRoutes = () => {
  const { user } = useAuth();
  // const location = useLocation();
  // const navigate = useNavigate();
  // const getParams = () =>{
  //   const queryParams = new URLSearchParams(location.search);
  //   const type = queryParams.get('type'); // Get the 'type' query parameter
  //   const tCat = queryParams.get('tCat'); // Get the 'tCat' query parameter
  //   const tScat = queryParams.get('tScat'); // Get the 'tScat' query parameter
  //   const tId = queryParams.get('tId'); // Get the 'tId' query parameter
  //   return {
  //     type:type || null,
  //     tCat:tCat || null,
  //     tScat:tScat || null,
  //     tId:tId || null,
  //   }
  // }
  
  // getParams={()=>getParams()} 
  return user ? <LandingScreen /> : <Login />;
};

export default PrivateRoutes;
