import React, { useState, useContext } from "react";
const SearchContext = React.createContext({});

const SearchProvider = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const searchContextValue = {
    searchValue,
    setSearchValue,
    currentPage,
    setCurrentPage,
    listData,
    setListData
  };

  return <SearchContext.Provider value={searchContextValue} {...props} />;
};

const useSearch = () => useContext(SearchContext);

export { SearchProvider, useSearch };
