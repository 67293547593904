import React from 'react'

export default function PopularCareerCategories({catsList, showArrow, title}) {
  return (
    <>
      <div className="heading-main position-relative my-3 overflow-hidden">
          <h5 className="text-left bg-white pr-3" style={{width:'fit-content'}}><strong>{title}</strong></h5>
          <hr className="heading-hr mb-0" />
      </div>
      
    <div className="links-container">
        {
            catsList?.map((cat, i)=><a href={cat?.redirectUrl} key={i} className='right-side-links w-100 d-flex justify-content-between align-items-center'>{cat?.name}
            {
              showArrow?<i className='fa fa-angle-right ml-3' style={{opacity:.6}}></i>:null
            }
            
        </a>)
        }
        
       
    </div>
    </>
  )
}
