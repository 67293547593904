import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { CAREER_NEWS_BASE_URL, CNEWS_URL, convertDateFormat, DASHBOARD_URL, ERROR_SOMETHING_WENT_WRONG, placeholderImages, slugify, slugToNormal, yearSatra } from '../../../../utils/utils';
import { bookMarkNews, getBranchDetail, getNewsByPattern, getNewsDetail, getNewsDetailPatternWise, likeUnlikeDislike } from '../../../../api/careernews';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import PopularCareerCategories from '../popularcategories';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../../Context/AuthContext';
import BranchDetailList from '../common/branchDetailList';
import NewsListItem from '../common/newsList';
import CommonComments from '../../../common/comments/commonComments';
import Share from '../../../common/share';
import styles from "../../../../assets/css/styles/Home.module.css";



function NewsDetail() {
    const { globalToken } = useAuth();
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const[upvoteBtnLoader, setUpvoteBtnLoader] = useState(false);
    const[downvoteBtnLoader, setDownvoteBtnLoader] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [newsDetailData, setNewsDetailData] = useState([]);
    const [posterImageBaseURL, setPosterImageBaseURL] = useState();
    const [newsId, setNewsId] = useState();
    const [newsSlug, setNewsSlug] = useState();
    const [isShareActive, setIsShareActive] = useState(false);
    const navigation = useNavigate();

    const [relatedSearches, setRelatedSearches] = useState([{
        name: "NIELIT CCC Admit Card April 2022 Details",
        value: "NIELIT CCC Admit Card April 2022 Details",
        redirectUrl: "#"
    },
    {
        name: "NIELIT CCC Admit Card April 2022",
        value: "NIELIT CCC Admit Card April 2022",
        redirectUrl: "#"
    },
    {
        name: "NIELIT Jobs Notification 2022 – 98 Posts, Salary, Application Form",
        value: "NIELIT Jobs Notification 2022 – 98 Posts, Salary, Application Form",
        redirectUrl: "#"
    },
    {
        name: "Student NIELIT CCC Admit Card May 2022 Exam",
        value: "Student NIELIT CCC Admit Card May 2022 Exam",
        redirectUrl: "#"
    },
    {
        name: "NIELIT",
        value: "NIELIT",
        redirectUrl: "#"
    },
    {
        name: "Nielit 2022",
        value: "Nielit 2022",
        redirectUrl: "#"
    }]);

    const params = useParams();
    const location = useLocation();
    const currentPath = location.pathname;

    const isHindiNews = currentPath.includes('hindi-news');
    useEffect(() => {
        if (params?.newsId && params?.newsSlug) {
            setNewsId(params?.newsId);
            // setNewsSlug(params?.newsSlug);
            handleFetchNewsDetailEn(params?.newsId);
        }
        
    }, [])

    // fetch news detail english
    const handleFetchNewsDetailEn = async (newsId) => {        
        const resNewsDetail = await getNewsDetail({ token: globalToken, newsId, language:isHindiNews?'hi':'en' });
            // console.log('newsDetail', resNewsDetail);
            setLoading(false);
            setLoadingLoadMore(false);
        if (resNewsDetail?.success) {
            setPosterImageBaseURL(resNewsDetail?.image_base_url);
            setIsNotFound(false)
            if (resNewsDetail?.data) {
                setNewsDetailData(resNewsDetail?.data);
            } else {
                setNewsDetailData({});
                setIsNotFound(true);
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setUpvoteBtnLoader(false);
        setDownvoteBtnLoader(false)
    }


    


    const popularCareerCats = [
        {
            name: "About",
            value: "about",
            redirectUrl: `/career-news/news/${newsDetailData?.id}/${newsDetailData?.slug}`
        },
        {
            name: "Eligibility",
            value: "eligibility",
            redirectUrl: `/career-news/eligibility/news/${newsDetailData?.id}/${newsDetailData?.slug}`
        },
        {
            name: "Dates",
            value: "dates",
            redirectUrl: `/career-news/dates/news/${newsDetailData?.id}/${newsDetailData?.slug}`
        },
        {
            name: "Pattern",
            value: "pattern",
            redirectUrl: `/career-news/pattern/news/${newsDetailData?.id}/${newsDetailData?.slug}`
        },
        {
            name: "Syllabus",
            value: "syllabus",
            redirectUrl: `/career-news/syllabus/news/${newsDetailData?.id}/${newsDetailData?.slug}`
        },
        {
            name: "Answer Keys",
            value: "answerkeys",
            redirectUrl: `/career-news/answerkeys/news/${newsDetailData?.id}/${newsDetailData?.slug}`
        },
        {
            name: "Results",
            value: "results",
            redirectUrl: `/career-news/results/news/${newsDetailData?.id}/${newsDetailData?.slug}`
        },
        {
            name: "Cutoff",
            value: "cutoff",
            redirectUrl: `/career-news/cutoff/news/${newsDetailData?.id}/${newsDetailData?.slug}`
        },
        {
            name: "Admit Card",
            value: "admitcard",
            redirectUrl: `/career-news/admitcard/news/${newsDetailData?.id}/${newsDetailData?.slug}`
        }
    ];



    const FavouriteButton = ({
        is_fav_camp
    }) => {
        const [favBtnLoading, setFavBtnLoading] = useState(false);
        const handleStartTask = async () => {
            setFavBtnLoading(true)

            const token = globalToken;
            const res = await bookMarkNews({ token: token, newsId: newsDetailData?.id, type:isHindiNews?'hi':'en', favourite:is_fav_camp?0:1 });
            if (res?.success) {
                handleFetchNewsDetailEn(newsDetailData?.id)
            } else {
                
            }
            setFavBtnLoading(false)

        }
        return (
            <button type="button" disabled={favBtnLoading} onClick={handleStartTask} className="btn bg-white p-0 d-flex btn-like-circular" style={{ border: 'none', boxShadow: 'none' }}>
                {favBtnLoading ? (
                    <div className="h-5 w-5">
                        <Loadingicon />
                    </div>
                ) : <>{
                    is_fav_camp ? <i className="fa fa-heart heart active"></i> : <i className="fa heart fa-heart"></i>
                }</>}
            </button>
        )
    }


    const InlineShare = ({news, isShareActive, setIsShareActive}) =>{
        const isShare = isShareActive;

        const handleShareClick = () => {
            setIsShareActive(!isShareActive);
        };
        
        return <small className="position-relative" title="Share">
        {
        isShare ?  <Share shareContent={{
            url:`${DASHBOARD_URL}/career-news/${isHindiNews?'hindi-news':'news'}/${news?.id}/${news?.slug}`,
            quote: `${news?.description}`,
            hashing: `#tuteehub`,
            title: `${news?.title}`,
        }} isCustomPos={true} /> : null
        }
        <span
        className={`${styles.icons} ${styles.shareIco}`}
        style={{display:'inline-block'}}
        role="button"
        onClick={() => handleShareClick()}
        ></span>{" "}
    </small>
    }


    const handleLikeUnlikeDislike = async (voteType, type) => {
       if(type=='dLike')setDownvoteBtnLoader(true)
       else setUpvoteBtnLoader(true)
        const resLikeUnlike = await likeUnlikeDislike({token:globalToken, newsId:newsDetailData?.id, voteType});
        if(resLikeUnlike?.success){
            handleFetchNewsDetailEn(newsDetailData?.id)
            toast.success(resLikeUnlike?.data?.message);
        }
        
    }

    const modifyAnchorTags = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        // Select all anchor tags
        const anchorTags = doc.querySelectorAll('a');
        // Add target="_blank" to each anchor tag
        anchorTags.forEach(tag => {
            tag.setAttribute('target', '_blank');
        });

        // Return modified HTML content
        return doc.documentElement.innerHTML;
    };

    return (<>
        {/* <BreadCrumbs /> */}
        
        {
            loading ? (

                <div className="w-full flex items-center justify-center py-4 text-themecolor min-h-[400px] group-card card-body">
                    <div className="h-10 w-10 theme-color">
                            <Loadingicon />
                        </div>
                </div>
            ) :
            <>
            <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
            <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                </li>
                <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/career-news' style={{ fontSize: 14, textDecoration: 'none' }}>Career News</NavLink>
                </li>
                <li
                    className={`breadcrumb-item capitalize rest-bread`}
                >
                    <NavLink
                        to={`/career-news/top-10-trending-branches-exams/${newsDetailData?.cat?.id}/${newsDetailData?.scat?.id}/${newsDetailData?.scat?.slug}`}
                        style={{ fontSize: 14, textDecoration: 'none' }}
                        dangerouslySetInnerHTML={{ __html: `${newsDetailData?.cat?.name}` }}
                    />
                </li>
                <li
                    className={`breadcrumb-item capitalize rest-bread`}
                >
                    <NavLink
                        to={`/career-news/top-10-trending-careers-streams/${newsDetailData?.cat?.id}/${newsDetailData?.cat?.slug}/${newsDetailData?.scat?.id}/${newsDetailData?.scat?.slug}`}
                        style={{ fontSize: 14, textDecoration: 'none' }}
                        dangerouslySetInnerHTML={{ __html: `${newsDetailData?.scat?.name}` }}
                    />
                </li>
                <li
                    className={`breadcrumb-item capitalize active clamping ellipsis`}
                >
                    {slugToNormal(newsDetailData?.slug)}
                </li>

            </ol>
        </nav>
                <div className="row">
                    <div className="col-md-12">
                        <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4">
                            <div className="px-0">
                                <div className="flex-column d-flex flex-md-row justify-content-between">

                                    <div className="mr-md-5 pr-md-5 flex-1">
                                        <h1 className="title-job d-flex align-items-center justify-content-between text-capitalize">
                                            <strong>{newsDetailData?.title}</strong>
                                            <span className="d-flex gap-3 align-items-center">
                                                <FavouriteButton  is_fav_camp={newsDetailData?.isuserfavourite==0?false:true}/>
                                                <InlineShare news={newsDetailData} isShareActive={isShareActive} setIsShareActive={setIsShareActive}/> 
                        </span>
                                        </h1>
                                        <div className="d-flex mb-4 flex-md-row flex-column justify-content-between align-md-items-center">
                                                
                                            <span className="text-black-50 gig-table items-center"><a className="a-hover cursor-pointer text-black-50" onClick={()=>navigation(`/career-news/top-10-trending-branches-exams/${newsDetailData?.cat?.id}/${newsDetailData?.scat?.id}/${newsDetailData?.scat?.slug}`)}>{newsDetailData?.cat?.name}</a>
                                            <span className="big-dots"></span>
                                             <a className="a-hover cursor-pointer text-black-50" onClick={()=>navigation(`/career-news/top-10-trending-careers-streams/${newsDetailData?.cat?.id}/${newsDetailData?.cat?.slug}/${newsDetailData?.scat?.id}/${newsDetailData?.scat?.slug}`)}>{newsDetailData?.scat?.name}</a>
                                             <span className="big-dots"></span>
                                             {/* <span>1.01K likes</span>
                                             <span className="big-dots"></span> */}
                                             <span>{newsDetailData?.comment_count} comments</span>
                                             <span className="big-dots"></span>
                                             <span>{newsDetailData?.userlikes_count} up votes</span>
                                             {
                                                    upvoteBtnLoader?<span className="custom-loader-small ms-2 theme-color">
                                                    <Loadingicon />
                                                </span>:<><i
                                                    className={`fas fa-thumbs-up cursor-pointer ms-2 ${newsDetailData?.islogin_like==1 && "text-themecolor"
                                                    }`}
                                                    onClick={() =>
                                                        handleLikeUnlikeDislike(newsDetailData?.islogin_like==1 ? "remove" : "like",'lke')
                                                    }
                                                ></i>{" "}</>
                                                }
                                             <span className="big-dots"></span>
                                             <span>{newsDetailData?.userdislikes_count} down votes</span>
                                             {
                                                    downvoteBtnLoader?<span className="custom-loader-small ms-2 theme-color">
                                                    <Loadingicon />
                                                </span>:<><i
                                                    className={`fas fa-thumbs-down cursor-pointer ms-2 ${newsDetailData?.islogin_dislike==1 && "text-themecolor"
                                                    }`}
                                                    onClick={() =>
                                                        handleLikeUnlikeDislike(newsDetailData?.islogin_dislike==1 ? "remove" : "dislike", 'dLike')
                                                    }
                                                ></i>{" "}</>
                                                }
                                             {/* <span className="big-dots"></span>
                                             <span>0 shares</span> */}
                                             <span className='big-dots'></span>
                                             <span>{convertDateFormat(newsDetailData?.posting_date || newsDetailData?.created_at)}</span>
                                             </span>
                                            {/* <span className="text-black-50">Published on {convertDateFormat(newsDetailData?.posting_date)}</span> */}

                                        </div>
                                        <div className="thumbnail-containers rounded w-100">
                                            {
                                                newsDetailData?.file_name?<img src={posterImageBaseURL +'/'+ newsDetailData?.file_name} className="rounded lazyload w-100 h-auto" alt='thumbnail-containers' />:<img src={placeholderImages} className="rounded lazyload w-100 h-auto" alt='thumbnail-containers' />
                                            }
                                            
                                        </div>
                                        <div className="w-100 text-black-60 mt-4 leads cn-desc" dangerouslySetInnerHTML={{
                                            __html: modifyAnchorTags(`${newsDetailData?.description || newsDetailData?.about} ${newsDetailData?.admitcard || newsDetailData?.answerkeys || newsDetailData?.syllabus  || newsDetailData?.dates  || newsDetailData?.pattern  || newsDetailData?.eligibility  || newsDetailData?.results  || newsDetailData?.cutoff || newsDetailData?.other}`)
                                        }}></div>

                                    </div>

                                </div>


                            </div>
                        </div>

                        <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4 mt-4">
                            <div className="heading-main position-relative my-3 overflow-hidden">
                                <h5 className="text-left bg-white pr-3 b"><strong>Comments({newsDetailData?.comment_count})</strong></h5>
                                <hr className="heading-hr mb-0" />
                            </div>
                            <CommonComments nData={newsDetailData} handleFetchNewsDetailEn={handleFetchNewsDetailEn}/>
                        </div>
                    </div>
                    {/* <div className="col">
                        <div className="position-sticky" style={{ top: 80 }}>
                            <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-1">
                                
                                <PopularCareerCategories catsList={popularCareerCats} showArrow={true} title={'Important Computer Science Engineering Links'}/>
                            </div>
                            <div className="py-4 mt-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4">
                                <div className="d-flex align-items-center w-100 py-md-2 py-2 px-0 w-100 w-md-auto max-left-contents justify-content-between">
                                    <div className="thumbnail-containers mr-3">

                                        <img src={require(`../../../../assets/images/logo_other_platform.webp`)} width={50} height={50} className="bg-gray border" style={{ borderRadius: 100 }} />
                                    </div>
                                    <div className="flex-1">
                                        <h5 className="mb-0 mt-0 text-capitalize title-task color-333">
                                            <strong>{'TuteeHUB'}</strong> </h5>
                                        <p className="text-black-50 text-capitalize mb-0 d-flex align-items-center gap-2">
                                            <strong className="text-blacks d-flex align-items-center gap-1">1L+ Subscribers
                                            </strong>
                                        </p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div> */}
                </div></>
        }

    </>)
}

export default NewsDetail;
