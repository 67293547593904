import React, { useEffect, useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { Loadingicon } from '../../../AppIcons';

const PopoverComponent = ({ title, contents }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const popoverRef = useRef(null);

  const handleClick = () => {
    setShow(!show);
  };

  const handleClickOutside = (event) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      !target.current.contains(event.target)
    ) {
      setShow(false);
    }
  };

  // const handleBuyPlanFn = (type, taskId, promotionId) => {
   
  //   handleBuyPlan(type, taskId, promotionId)
  // };
  useEffect(() => {
    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show]);

  return (
    <div>
      <button ref={target} onClick={handleClick} variant="success">
        <i className='fa fa-circle-info text-black-50' style={{opacity:0.5,fontSize:'1.4rem'}}></i>
      </button>
      <Overlay
        show={show}
        target={target.current}
        placement="bottom"
        containerPadding={20}
        rootClose
        onHide={() => setShow(false)}
      >
        <Popover ref={popoverRef} id="popover-basic">
          <Popover.Body>
            <div className='w-100' dangerouslySetInnerHTML={{ __html: contents }} />
            {/* {task?.promotion_status && task?.promotion_remaining_frequency > 0 && (<div class="px-3" style={{marginTop:-20}}>
              <button
                type="button"
                disabled={btnBuyLoading}
                className="btn edit-btn btn-sm mt-2"
                onClick={() => handleBuyPlanFn('promotions', task?.id, task?.promotion_id)}
              >
                {btnBuyLoading ? (
                    <div className="h-5 w-5">
                        <Loadingicon />
                    </div>
                ) : (
                    `Top Up`
                )}
              </button>
              </div>
            )} */}

          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
};

export default PopoverComponent;
