import React from 'react'
import { CDN_BASE_URL, convertDateFormat, convertDateFormatOnly, limitSubtitle, nFormatter, stripTags } from '../../../../utils/utils';
import careerThumbPlaceholder from '../../../../assets/images/feeds_default_placeholder.webp';
import { Link, useNavigate } from 'react-router-dom';

export default function NewsListItem({ newsData, patternLinks=null, stripBottom=false, thumbPrefix='thcn/news/desktop/thumb/', redirectionFn, type }) {

    const navigation = useNavigate();
    return (
        <div className={`d-flex w-100 hover-div flex-md-row flex-column justify-content-between align-items-center list-alter  lafter mb-2 py-2 pr-3 position-relative overflow-hidden cursor-pointer`}  onClick={(e) => redirectionFn(e)} style={{ border: '1px solid #f1e4e4', borderLeft: '3px solid #ccc' }}>

            <div className="d-flex align-items-start p-md-2 pe-0 pe-md-2 py-md-2 py-2 px-2 w-100 w-md-auto ">

                <div className="thumbnail-containers mr-3">
                    {
                        newsData?.file_name ?
                        <img
                        src={
                            CDN_BASE_URL +
                            `${thumbPrefix+newsData?.file_name}`
                        }
                        width="90"
                        height="90"
                        className="rounded bg-gray border"
                        alt="Thumbnail"
                    />
                            : <img src={`${careerThumbPlaceholder}`} width="90" height="90" className="rounded bg-gray" style={{ border: '1px solid #f1e4e4' }} />
                    }

                </div>

                <div className="flex-1">
                    <h6 className="mb-0 justify-content-between d-md-block d-flex align-items-start w-100 mt-0 text-capitalize title-task color-333">
                        <span className="theme-a cursor-pointer d-flex align-items-center gap-2 pr-md-0 pr-5"><strong>{newsData?.title}</strong> {

                        }</span>
                    </h6>
                    <span className='w-100 d-flex align-items-center'>
                    {

                        type!=='hindiNews' && type!=='latestNews'?<><small><a onClick={(e) => {e.stopPropagation();e.preventDefault();navigation(`/career-news/top-10-trending-careers-streams/${newsData?.cat?.id}/${newsData?.cat?.slug}`)}} className="theme-a-light cursor-pointer">{newsData?.cat?.name}</a></small>
                        <span className="big-dots"></span>
                        <small><a onClick={(e) => {e.stopPropagation();e.preventDefault(); navigation(`/career-news/top-10-trending-branches-exams/${newsData?.cat?.id}/${newsData?.scat?.id}/${newsData?.scat?.slug}`)}} className="theme-a-light cursor-pointer">{newsData?.scat?.name}</a></small>
                        <span className="big-dots"></span></>:null
                    }
                    
                    <span className='span px-1 color-gray text-capitalize fsize-11 c-a5'>{convertDateFormatOnly(newsData?.posting_date || newsData?.created_at)}</span>
                    </span>
                    {/* <div className="w-100 text-black-50 text-capitalize mt-1 mb-0 d-md-flexs d-inline-block  align-items-center">
                        <small>{nFormatter(newsData?.news_count || 0)} News dangerouslySetInnerHTML={{__html:`${newsData?.about}`}}</small>
                    </div> */}
                    <p className="mb-0 mt-1 color-gray fsize-meta desc-camp" >{limitSubtitle(stripTags(newsData?.about || newsData?.description || newsData?.other), 290) }</p>
                    {
                        stripBottom?<> <div> <hr style={{borderColor:"#ccc",marginTop:10,marginBottom:3}} /></div>
                        <div class="">
                            {
                                patternLinks?.length?patternLinks?.map((patternLink, pLIndex) =>(<small>
                                    <Link key={pLIndex} onClick={(e)=>{e.preventDefault();e.stopPropagation();navigation(`/career-news/news/${newsData?.id}/${newsData?.scat?.id}/${newsData?.scat?.slug}${patternLink?.redirectUrl}`);window.location.reload()}} className='color-blue-hover'>
                                        {patternLink?.name}
                                    </Link>
                                    {
                                        pLIndex < patternLinks?.length-1?<span className="big-dots"></span>:null
                                        
                                    }
                                   </small> 
                                )):null
                            }
                           
                        </div></>:null
                    }
                   
                </div>
                
            </div>
        </div>
    )
}
