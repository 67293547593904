import React, { useEffect, useState } from 'react';
import Users from '../babbleusers';
import { UsersLoader } from '../loaders';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

export default function SidebarRight({ popularUsers, leaderboardLoader }) {

  return (
    <div className='col-md-2 text-right position-sticky'>
       <div className="mb-4" style={{ width: '100%', maxWidth: 280}}>
        {/* <h5 className="text-black-50 mb-4 text-left">Other Apps</h5> marginTop: scrollY > 160 ? -160 : -scrollY*/}
        <div className={`heading-main heading-main-2 position-relative  mb-3 overflow-hidden`}>
            {/* <div className="circle-head"></div> */}
            <h5 className="text-left bg-transparent pr-3" style={{backgroundColor:'#f8f8f7!important',fontSize:'1rem'}}>Other Apps</h5>
            <hr className="heading-hr mb-0" />
          </div>
        <div className="d-flex flex-column">
          <div className="showtalent-sec position-relative rounded float-right align-items-start mb-3 d-flex" data-tooltip-id="showtalent"
            data-tooltip-place="left"
            data-tooltip-variant="dark"
            data-tooltip-html="<div style='maxWidth:200;wordBreak:'break-word;line-height:20px;z-index:3;'>
            <Link to={'https://play.google.com/store/apps/details?id=com.tuteehub.showtalent'} target='_blank' className='d-flex outline-0 text-decoration-none flex-column text-black text-left'><p className='m-0 text-left' style='text-align:left;'><strong>ShowTalent - Brand Yourself</strong></p>
                <p className='text-bold m-0 small text-left' style={{maxWidth:200,wordBreak:'break-all',color:'#585858', fontSize:11,textDecoration:'none';margin-top:-20px}}>A Talent Platform To Showcase Your Skills</p>
            </Link>
            </div>"
          >
            <Link to={'https://play.google.com/store/apps/details?id=com.tuteehub.showtalent'} target='_blank'>
            <div className='d-flex align-items-start'>
            <div className="logo-container mec-3 text-center align-items-center justify-content-center d-flex" style={{ borderRadius: 10, opacity: 0.8 }}>
              
                <img src={require(`../../../assets/images/showtalent.webp`)} width={96} height={96} layout="fixed" />
             
            </div>
            <div>
              <p className='text-left mb-0 text-black fsize-8'><strong>ShowTalent - Brand Yourself</strong></p>
              <p className='text-bold m-0 small text-left text-black-50'>A Talent Platform To Showcase Your Skills</p>
            </div>
            </div>
            </Link>
            <Tooltip id="showtalent" />
          </div>

          <div className="showtalent-sec position-relative rounded float-right align-items-center mb-3 d-flex" style={{ width: '100%', maxWidth: 280 }}
            data-tooltip-id="tuteehub"
            data-tooltip-place="left"
            data-tooltip-variant="dark"
            data-tooltip-html="<div style='maxWidth:200;wordBreak:'break-word;line-height:20px;z-index:3;'>
            <Link to={'https://play.google.com/store/apps/details?id=com.tuteehub.showtalent'} target='_blank' className='d-flex outline-0 text-decoration-none flex-column text-black text-left'><p className='m-0 text-left' style='text-align:left;'><strong>TuteeHUB - Prepare Yourself</strong></p>
                <p className='text-bold m-0 small text-left' style={{maxWidth:200,wordBreak:'break-all',color:'#585858', fontSize:11,textDecoration:'none';margin-top:-20px}}>A Platform For Learn, Work & Earn To Meet Your Goals</p>
            </Link>
            </div>"
          >
            <Link to={'https://play.google.com/store/apps/details?id=com.tuteehub'} target='_blank' className='text-black'>
            <div className='d-flex align-items-start'>
            <div className="logo-container mec-3 text-center align-items-center justify-content-center d-flex" style={{ borderRadius: 10, opacity: 0.8 }}>
              
                <img src={require(`../../../assets/images/tuteehub.webp`)} width={96} height={96} layout="fixed" />
              
            </div>
            <div>
              <p className='text-left mb-0  fsize-8'><strong>TuteeHUB - Prepare Yourself</strong></p>
              <p className='text-bold m-0 small text-left text-black-50'>A Platform For Learn, Work & Earn To Meet Your Goals</p>
            </div>
            </div>
            </Link>
            <Tooltip id="tuteehub" />
          </div>
        </div>
      </div>
      <div className='position-sticky fixed-right p-0 w-100' style={{top:100}}>
       
        <Users popularUsers={popularUsers} leaderboardLoader={leaderboardLoader} />
      </div>
    </div>
  )
}
