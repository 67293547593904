import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import { getAccountDetail, getPlansList, onAddBankDetails, onUpdateBankDetails } from "../../../api/OurService";

import * as Yup from "yup";
import { Loadingicon } from "../../../AppIcons";
import BreadCrumbs from "../../common/BreadCrumbs";
import { Formik } from "formik";
import { useAuth } from "../../../Context/AuthContext";
function PaymentDetails() {
  const [loading, setLoading] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [bankDetails, setBankDetails] = useState(null);
  const [choosenPaymentPreferenceType, setChoosenPaymentPreferenceType] = useState();
  const BankDisplayingErrorMessagesSchema = Yup.object().shape({
    accountnumber: Yup.string().required("Account number is required"),
    accountname: Yup.string().required("Account name is required"),
    ifsccode: Yup.string().required("IFSC code is required"),
    bankname: Yup.string().required("Bank name is required"),
    confirmaccount: Yup.string()
      .required("Confirm account number is required")
      .oneOf([Yup.ref('accountnumber'), null], 'Account numbers must match')
  });
  const PaypalDisplayingErrorMessagesSchema = Yup.object().shape({
    paypal_email: Yup.string().email("Invalid email").required("Paypal email is required")
  });
  const { user } = useAuth();
  const fetchBankDetails = async () => {
    setLoading(true)
    try {
      const res = await getAccountDetail(user?.tuteehub_id);
      if (res?.status == 1) {
        setBankDetails(res?.data);
        setChoosenPaymentPreferenceType(res?.data?.paypal_email?'paypal':'bank');
        
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching plans:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBankDetails();
  }, [])

  const addBankDetails = async (values) => {
    setBtnLoader(true)
    let addBankDetailParams = null;
    if (values?.payment_preference == 'bank') {
      delete values.confirmaccount;
      delete values.paypal_email;
      addBankDetailParams = values;
    } else {
      addBankDetailParams = { paypal_email: values?.paypal_email, payment_preference: values.payment_preference }
    }

    
    addBankDetailParams = { ...addBankDetailParams, tuteehub_id: user?.tuteehub_id }

    try {
      const res = await onAddBankDetails(addBankDetailParams);
      if (res?.status == 1) {
        toast.success('Account detail saved!')
      } else {
        toast.error(res?.message)
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    setBtnLoader(false)

  };

  const updateBankDetails = async (values) => {
    setBtnLoader(true)

    let addBankDetailParams = null;
    if (values?.payment_preference == 'bank') {
      delete values.confirmaccount;
      delete values.paypal_email;
      addBankDetailParams = values;
    } else {
      addBankDetailParams = { paypal_email: values?.paypal_email, payment_preference: values.payment_preference }
    }
    
    addBankDetailParams = { ...addBankDetailParams, tuteehub_id: user?.tuteehub_id }
    // values = { ...values, tuteehub_id: user?.tuteehub_id }
    try {
      const res = await onUpdateBankDetails(addBankDetailParams);
      if (res?.status == 1) {
        toast.success('Account detail updated!')
      } else {
        toast.error(res?.message)
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    setBtnLoader(false)


  };
  // useEffect(()=>{fetchPlans();},[])

  const preferenceType = [
    {
      label: 'Bank',
      value: 'bank'
    }, {
      label: 'Paypal',
      value: 'paypal'
    }
  ]
  const formData = choosenPaymentPreferenceType == 'bank' ? [
    {
      id: 'payment_preference',
      title: 'Payment Preference',
      type: 'text',
      name: 'payment_preference',
      placeholder: 'Payment Preference',
      isPaymentPrefrence: true
    },
    {
      id: 'bankname',
      title: 'Bank Name',
      type: 'text',
      name: 'bankname',
      placeholder: 'Bank Name'
    },
    {
      id: 'accountnumber',
      title: 'Account Number',
      type: 'password',
      name: 'accountnumber',
      placeholder: 'Acount Number'
    }, {
      id: 'confirmaccount',
      title: 'Re-enter Account Number',
      type: 'text',
      name: 'confirmaccount',
      placeholder: 'Re-enter Account Number'
    }, {
      id: 'ifsccode',
      title: 'IFSC Code',
      type: 'text',
      name: 'ifsccode',
      placeholder: 'IFSC Code'
    }, {
      id: 'accountname',
      title: 'Account Holder Name',
      type: 'text',
      name: 'accountname',
      placeholder: 'Account Name'
    }
  ] : [
    {
      id: 'payment_preference',
      title: 'Payment Preference',
      type: 'text',
      name: 'payment_preference',
      placeholder: 'Payment Preference',
      isPaymentPrefrence: true
    },
    {
      id: 'paypal_email',
      title: 'Paypal Email',
      type: 'text',
      name: 'paypal_email',
      placeholder: 'Paypal Email'
    }
  ]

  const handlePaymentPreferenceChange = (e, setFieldValue) => {
    const pValue = e.target.value;
    setFieldValue('payment_preference', pValue);
    if (pValue == 'bank') {
      setChoosenPaymentPreferenceType('bank')
    } else {
      setFieldValue('paypal_email', "");
      setChoosenPaymentPreferenceType('paypal')
    }
  }

  return (
    <>
      <BreadCrumbs />
      {/* <div className="grid grid-cols-12 graph-card-box text-center py-3 px-0">
      <div className="col-span-5 text-card flex items-center">
        <h2
          className="heading-star px-0 flex items-center text-left cursor-pointer"
          style={{ color: "#021442" }}
        >
          Payment Details
        </h2>
      </div>
    </div> */}
      <div className=" py-4 px-0 grid grid-cols-1 group-card card-body gap-4">
        {loading ? (
          <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
            <div className="h-10 w-10">
              <Loadingicon />
            </div>
          </div>
        ) : (
          <>
            <div className="px-5">
              <div>
                <Formik
                  initialValues={{
                    bankname: bankDetails?.bankname || "",
                    accountnumber: bankDetails?.accountnumber || "",
                    confirmaccount: bankDetails?.accountnumber || "",
                    ifsccode: bankDetails?.ifsccode || "",
                    accountname: bankDetails?.accountname || "",
                    payment_preference: choosenPaymentPreferenceType,
                    paypal_email: bankDetails?.paypal_email || ""
                  }}
                  validationSchema={choosenPaymentPreferenceType == 'bank' ? BankDisplayingErrorMessagesSchema : PaypalDisplayingErrorMessagesSchema}
                  onSubmit={(values) => {
                    if (bankDetails) {
                      updateBankDetails(values)
                    }
                    else {
                      addBankDetails(values)
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <form className="" onSubmit={handleSubmit}>
                      <div className="col-md-6">
                        {
                          formData?.map(fieldMeta => (
                            <>
                              <label
                                htmlFor={fieldMeta?.id}
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                              >
                                {fieldMeta?.title}
                                <span className="text-red-400 text-base">*</span>
                              </label>
                              {
                                fieldMeta?.isPaymentPrefrence ?
                                  <div className="d-block w-100 mb-4">
                                    <select
                                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                      name={fieldMeta?.name}
                                      id={fieldMeta?.id}
                                      onChange={(e) => handlePaymentPreferenceChange(e, setFieldValue)}
                                      onBlur={handleBlur}
                                    >
                                      {/* {
                                        preferenceType?.map((p, i) => ( */}
                                          <option value='bank' selected={bankDetails?.paypal_email?false:true}>Bank</option>
                                          <option value='paypal' selected={!bankDetails?.paypal_email?false:true}>Paypal</option>

                                          {/* <option value={p.value}>{p.label}</option> */}
                                        {/* ))
                                      } */}

                                    </select>
                                  </div> : <>
                                    <input type="password" name="fake-password" autoComplete="new-password" style={{ display: 'none' }} />
                                    {/* <input type="text" autoComplete="off" name="fake-email" style={{ display: 'none' }} /> */}
                                    <input
                                      type={fieldMeta?.type}
                                      name={fieldMeta?.id}
                                      autoComplete={'off'}
                                      id={fieldMeta?.id}
                                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                      placeholder={fieldMeta?.title}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values[fieldMeta?.id]}
                                    />
                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                      {touched[fieldMeta?.id] && errors[fieldMeta?.id] && errors[fieldMeta?.id]}
                                    </div></>
                              }


                            </>
                          ))
                        }
                        <div className="col-md-2">
                          <button
                            type="submit"
                            disabled={btnLoader}
                            className="bg-themecolor text-center text-white border py-2 px-8 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                          >
                            {btnLoader ? (
                              <div className="h-5 w-5 text-white m-auto">
                                <Loadingicon />
                              </div>
                            ) : (
                              "Save"
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}

                </Formik>
              </div>
            </div>
          </>
        )
        }
      </div>
    </>
  );
}
export default PaymentDetails;
