import React, { useEffect, useState } from "react";
import styles from "../../../assets/css/styles/Home.module.css";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { SHOWTALENT_USER_DETAIL_BASE_LINK, TUTEEHUB_MAIN_URL, nFormatter } from "../../../utils/utils";
import { UsersLoader } from "../loaders";

function Users({ popularUsers, leaderboardLoader }) {
  const colors = [
    '#E0BBE4',
    '#957DAD',
    '#D291BC',
    '#A7BED3',
    '#FF968A',
    '#8FCACA',
    '#97C1A9',
  ];

  const [scrollY, setScrollY] = useState(0);
  const [userColors, setUserColors] = useState([]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (popularUsers?.length > 0) {
      const colorsForUsers = popularUsers.map(() => colors[Math.floor(Math.random() * colors.length)]);
      setUserColors(colorsForUsers);
    }
  }, [popularUsers]);

  return (
    <>
     
      <div>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6798492140859853"
          crossOrigin="anonymous"></script>
        <ins className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-6798492140859853"
          data-ad-slot="2443246227"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
        <script>
          (adsbygoogle = window.adsbygoogle || []).push({});
        </script>
        <div className={`heading-main heading-main-2 position-relative  mb-3 overflow-hidden`}>
            {/* <div className="circle-head"></div> */}
            <h5 className="text-left bg-transparent w-100 d-flex justify-content-between align-items-center" id="custom-head-transparent" style={{fontSize:'1rem',backgroundColor:'transparent'}}><span style={{backgroundColor:'#f8f8f7'}} className="pe-2">Weekly Leaderboard</span>
            <a href={TUTEEHUB_MAIN_URL + `earn-credits`} title="How to Earn Credits" className="color-blue-link" target="_blank" style={{background: '#f8f8f7',paddingLeft: 10}}><i className="fa fa-external-link"></i></a>
            </h5>
            <hr className="heading-hr mb-0" />
          </div>
        {/* <h5 className="text-black-50 mb-3 text-left d-flex justify-content-between align-items-center">Leaderboard
          <a href={TUTEEHUB_MAIN_URL + `earn-credits`} title="How to Earn Credits" className="color-blue-link" target="_blank"><i className="fa fa-external-link"></i></a>
        </h5> */}
        <div className="scrolled-user position-relative" style={{ height: '100vh' }}>
          {
            !leaderboardLoader ? popularUsers?.map((user, index) =>
              <div key={index} className="d-flex align-items-center justify-content-between mb-2 pb-2">
                <div className={(styles.otherPlatformLink, "otherPlatformLink max-60 d-flex align-items-center")}>
                  <div className="d-flexs">
                  <a href={`${SHOWTALENT_USER_DETAIL_BASE_LINK}${user?.username}`} target='_blank' className="theme-hover-color"> 
                    {
                      <div className="thumb_name text-uppercase" style={{ backgroundColor: userColors[index] || '#000' }}>
                        {user?.user_name.slice(0, 2).toUpperCase() || 'NA'}
                      </div>
                    }
                    </a>
                  </div>
                  <span className="ms-2 text-left d-flex justify-content-between">
                    <span className="d-flex flex-column align-items-start">
                    <a href={`${SHOWTALENT_USER_DETAIL_BASE_LINK}${user?.username}`} target='_blank' className="theme-hover-color"> 
                      <span className="text-capitalize">{user?.user_name || 'NA'}</span>
                    </a>
                      <span className="d-flex align-items-center">
                        <small style={{ color: "rgba(0,0,0,0.3)" }}><strong>#{index + 1}</strong></small>
                        <span className="big-dots"></span>
                        <small style={{ color: "rgba(0,0,0,0.3)" }}>{nFormatter(user?.user_credit)} Credits</small>
                      </span>
                    </span>
                  </span>
                </div>
              </div>) : <>
              <UsersLoader />
              <UsersLoader />
              <UsersLoader />
              <UsersLoader />
              <UsersLoader />
              <UsersLoader />
              <UsersLoader />
              <UsersLoader />
              <UsersLoader />
              <UsersLoader />
              <UsersLoader />
              <UsersLoader />
              <UsersLoader />
              <UsersLoader />
            </>
          }
        </div>
      </div>
    </>
  );
}

export default Users;
