import React, { useEffect } from 'react'
import { CDN_BASE_URL, convertDateFormat, convertDateFormatOnly, limitSubtitle, nFormatter, stripTags } from '../../../../utils/utils';
import careerThumbPlaceholder from '../../../../assets/images/feeds_default_placeholder.webp';
import { Link, useNavigate } from 'react-router-dom';
import quotesThumbPlaceholder from '../../../../assets/images/quotes_placeholder.png';

export default function QList({ quote, thumbPrefix='showtalent/images/quotes/category/thumbs/', redirectionFn, type }) {

    const navigation = useNavigate();
    
    return (
        <div className={`d-flex w-100 hover-div flex-md-row flex-column justify-content-between align-items-center list-alter  lafter mb-2 py-2 pr-3 position-relative overflow-hidden cursor-pointer`}  onClick={(e) => redirectionFn(e)} style={{ border: '1px solid #f1e4e4', borderLeft: '3px solid #ccc' }}>

            <div className="d-flex align-items-start p-md-2 pe-0 pe-md-2 py-md-2 py-2 px-2 w-100 w-md-auto ">

                <div className="thumbnail-containers border-0 mr-3">
                    {
                        quote?.image_url ?
                        <img
                        src={
                            quotesThumbPlaceholder
                        }
                        width="60"
                        height="60"
                        className="rounded bg-none border-0 qThumbs me-md-3 opacity-05"
                        alt="Thumbnail"
                    />
                            : <img src={`${quotesThumbPlaceholder}`} width="90" height="90" className="rounded bg-gray" style={{ border: '1px solid #f1e4e4' }} />
                    }

                </div>

                <div className="flex-1">
                    <h6 className="mb-0 justify-content-between d-md-block d-flex align-items-start w-100 mt-0 text-capitalize title-task color-333">
                        <span className="theme-a cursor-pointer d-flex align-items-center gap-2 pr-md-0 pr-5 lh-1p6">{stripTags(quote?.title)}</span>
                    </h6>
                    <div className="d-flex  mt-2 w-100 gap-2 align-items-center mw-200">
                        <Link onClick={(e)=>{e.stopPropagation();e.preventDefault();navigation(`/quotes/authors/${quote?.author?.id}/${quote?.author?.slug}`);window.location.reload()}} className=" hover-theme text-black-50">
                                <div className="user-pic">
                                    {
                                        quote?.author?.image && quote?.author?.image !== '' ? 
                                        <img src={`${CDN_BASE_URL}showtalent/images/quotes/author/thumbs/${quote?.author?.image}`} width={25} height={25} style={{borderRadius:100}}/>
                                            :
                                            <img src={require('../../../../assets/images/placeholder_big.png')} width={25} height={25} />
                                    }
                                </div>
                            </Link>
                            <div className='flex-1'>
                                <small className="m-0">
                                    <Link onClick={(e)=>{e.stopPropagation();e.preventDefault();navigation(`/quotes/authors/${quote?.author?.id}/${quote?.author?.slug}`);window.location.reload()}} className=" hover-theme text-black-50">
                                        <em>by </em>
                                        <strong className="text-capitalize ">{quote?.author?.name}</strong>
                                    </Link>
                                </small>
                                {/* </a> */}
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
