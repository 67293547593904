import { FEEDS_API_Basepath } from "../../utils/utils";

export function PostDataAuth(url, token, data) {
  return new Promise((resolve, reject) => {
    fetch(FEEDS_API_Basepath + url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
