import React, { useState } from 'react';
// import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Button } from 'bootstrap';

const PasswordToggleIcon = ({ showPassword, onClick }) => {
  return (
      <button
        type='button'
        onClick={onClick}  
        className='bg-transparent btn border-0 text-black-50 position-absolute btn-default'
        style={{position:'absolute', right:0, top:34, opacity:0.4}}     
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </button>
  );
};

export default PasswordToggleIcon;
