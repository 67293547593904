import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import { mostViewedQuestions } from "../../../api/OurService";
import { useAuth } from "../../../Context/AuthContext";
import { convertLongNumberAbbreviated } from "../../../utils/utils";
import { Loadingicon } from "../../../AppIcons";

function MostViewedQuestions({
  topicId,
  getBankDeatils,
  setQuestionId,
  setIsFavouriteUser,
  state,
  type
}) {
  const [loading, setLoading] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hashMore, setHasMore] = useState(true);
  const { qToken, iQToken, globalToken } = useAuth();
  const token = globalToken;
    // state?.moduleName == "question-Bank" ? qToken?.token : iQToken?.token;

  useEffect(() => {
    getMostviewedQList();
  }, [currentPage]);

  useEffect(()=>{
    return ()=> localStorage.setItem('feedDetail', 'feedDetail')
  },[])

  const getMostviewedQList = async () => {
    setLoading(true);
    setScrollLoading(true);
    
    try {
      const res = await mostViewedQuestions(
        topicId,
        token,
        currentPage,
        state?.moduleName
      );
      setListData([...listData, ...res?.data?.data]);
     
      if (res?.data?.length < 30) {
        setHasMore(false);
      }
      setScrollLoading(false);
    } catch (error) {
      toast.error(error.message || "Something went wrong!");
    } finally {
      setLoading(false);
      setScrollLoading(false);
    }
  };

  return (
    <div className="question-box" id="question-box">
      {!isEmpty(listData) ? (
        
        <div className="card-header-bar header1 px-8 py-3 mb-4">
        <h5 className="theme-orange-color m-0 fsize-14">  {/* { listData?.[0]?.topic?.question_count}  */}
          Most Viewed {type=='fullforms'?'Fullforms':type=='dictionary'?'Word Meanings':'Questions'} of {listData?.[0]?.topic?.topic_name}</h5>
      </div>
      ) : null}
    <div className="px-8 qblistmost">
      <InfiniteScroll
        dataLength={listData?.length}
        next={() => {
          setCurrentPage(currentPage + 1);
        }}
        hasMore={hashMore}
        loader={
          scrollLoading ? (
            <div className="h-10 w-10 mx-auto theme-color my-3">
            <Loadingicon />
          </div>
          ) : null
        }
        refreshFunction={() => {}}
        // scrollableTarget={"mViewQ"}
        pullDownToRefresh
        pullDownToRefreshThreshold={90}
      >
        <div id="mViewQa" className="pr-2">
          {!isEmpty(listData)
            ? listData?.map((item) => (
                <div
                  className="flex items-center justify-between mb-3 cursor-pointer"
                  onClick={() => {
                    setIsFavouriteUser(item?.is_favourite_by_user);
                    setQuestionId(item?.id);
                    getBankDeatils(true, item?.id);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  <div className="flex items-center ">
                    <span className="mr-2 text-2xl font-bold text-gray-400" style={{color:"#bfd6df", fontSize:24}}>
                      Q.
                    </span>
                    <span className="font-medium text-base blue-link">
                      {(item?.question).replace(/<\/?[^>]+(>|$)/g, "")}
                    </span>
                  </div>
                  <span className="btn btn-sm btn-light gap-1 align-items-center d-flex light-custom color-333 ml-3" style={{fontSize:11,color:'#8a8989'}}>
                    <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                    {convertLongNumberAbbreviated(item?.views)}
                  </span>
                </div>
              ))
            : null}
        </div>
      </InfiniteScroll>
      </div>
    </div>
  );
}
export default MostViewedQuestions;
