import { useEffect, useState } from "react";
import { Loadingicon } from "../../../../AppIcons";
import BreadCrumbs from "../../../common/BreadCrumbs";
import { useAuth } from "../../../../Context/AuthContext";
import { Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';

import { Link, useNavigate } from "react-router-dom";
import { favUser, getFavouriteEmployers, getTaskCategories, getTaskList, getTaskSubCategories, getUserInfo, unFavUser } from "../../../../api/OurService";
import { SHORT_TASK_ASSETS_BASE_URL, convertRating, slugify } from "../../../../utils/utils";
import StarRatings from "react-star-ratings";
import TaskNavigationTab from "../navigationtab";


function FavouriteEmployers() {
    const [loading, setLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [isCatLoading, setIsCatLoading] = useState(false);
    const [moreLoader, setMoreLoader] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [allFavEmployers, setAllFavEmployers] = useState([]);
    const [campCounts, setCampCounts] = useState();
    const navigate = useNavigate()
    const { gigToken, globalToken, setIsEmailVerifyCover } = useAuth();

    const handleFavEmployerList = async (pageNo) => {
        setLoading(true);
        const token = globalToken;
        const res = await getFavouriteEmployers({ token: token, pNumber:pageNo });  
        if(res?.success){
          setCampCounts(res?.data?.camp_counts);
        if (res?.data?.favuser?.current_page === pageNumber) {
            setAllFavEmployers(res?.data?.favuser);
           
        } else {
            setAllFavEmployers({ ...res?.data?.favuser, data: [...allFavEmployers?.data, ...res?.data?.favuser?.data] });
            setPageNumber(res?.data?.favuser?.current_page);
        }
    }
        setLoading(false);
        setFirstLoading(false);
        setMoreLoader(false);
    }

    useEffect(() => {
        handleFavEmployerList(pageNumber);
    }, [gigToken])



    const loadMoreTasksCats = () => {
        setMoreLoader(true)
        const nxtPage = pageNumber + 1;        
        handleFavEmployerList(nxtPage);
    }

    const FavouriteButton = ({
        userId,
        is_fav_camp
    })=>{
        const[favBtnLoading, setFavBtnLoading] = useState(false);
        const handleFavUnfavUser = async () => {
            setFavBtnLoading(true)          
            const token = globalToken;
            const res = is_fav_camp?await unFavUser({ token: token, user_id: userId }):await favUser({ token: token, user_id: userId });
            if (res?.success) {
                handleFavEmployerList(pageNumber);
            }else{
                if(res?.status==5){
                    toast.error(res?.errors);
                    setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
                }else{
                    toast.error(res?.errors)
                }
                // toast.error(res?.errors)
            }
            setFavBtnLoading(false)    
        }
        return(
            <button type="button" disabled={favBtnLoading} onClick={handleFavUnfavUser} className="btn p-0 d-flex btn-like-circular" style={{border:'none', boxShadow:'none'}}>
                                        {favBtnLoading ? (
                <div className="h-5 w-5">
                    <Loadingicon />
                </div>
            ) : <>{
                is_fav_camp?<i className="fa fa-heart heart active"></i>:<i className="fa heart fa-heart"></i>
            }</>}
                                </button>
        )
    }

    
    return (
        <>
            <BreadCrumbs />

           
                {firstLoading || loading ? ( <div className=" py-4 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                    </div>
                ) : firstLoading && !loading ? (
                    <div className=" py-4 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    <div className="flex-column not-found d-flex text-center" >
                    <img src={notFound} className="notFoundImg" />
                    <span style={{ opacity: 0.7 }}>No favourite employer found</span>             
                  
                </div></div>) : (
                    <>
                 <div className="mb-2 py-2 d-none d-md-block px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4">
                        <TaskNavigationTab tabType={'favEmployer'} />
                    </div>
                    <div className="py-md-4 px-md-3 grid grid-cols-1 group-card card-body gap-1">
                     {
                                        allFavEmployers?.data?.length > 0 ?
                                        allFavEmployers?.data?.map((favUser, index) => <>  
                                        <div className="col-md-12">
                        <div className="card mb-0 border-0 shadow" style={{borderColor:'#f0f0f0', boxShadow:'0 0.5rem 1rem rgb(0 0 0 / 9%)'}}>
                        <div className="card-body d-flex justify-content-between align-items-center">
                        
                            <div className="d-flex align-items-center p-md-2 py-md-2 py-2 px-2 w-100 w-md-auto max-left-content ps-0 ps-md-2" key={index}>
                                <div className="thumbnail-containers mr-3">
                                    {
                                        // SHORT_TASK_ASSETS_BASE_URL+(favUser?.user?.profile_image)
                                        favUser?.user?.profile_image ?
                                            <img src={favUser?.user?.profile_image} width="70" height="70" className="bg-gray" style={{borderRadius:150}} />
                                            :
                                            <div><img src={require('../../../../assets/images/profile_placeholder.png')} width={40} height={40} /></div>
                                    }
                                </div>
                                <div className="flex-1">
                                    {/* <small className="text-black-50 text-capitalize">{offer?.category}</small>  */}
                                    <h6 className="mb-0 mt-0 text-capitalize title-task color-333">
                                    <Link to={`/gigs/favourite-employers/${slugify(favUser?.user?.name)}/campaigns/${favUser?.fav_user_id}`} className="theme-a"><strong>{favUser?.user?.name}</strong></Link></h6>
                                    <p className="mt-1 text-black-50 text-capitalize mb-0 d-flex align-items-center"> <small style={{marginTop:-5}}>
                                        <StarRatings
                    rating={convertRating(favUser?.user?.rating)}
                    starRatedColor="#F08645"
                    changeRating={null}
                    numberOfStars={5}
                    starDimension="15px"
                    starSpacing="0"
                    name="rating"
                  /></small>
                    <span className="big-dots"></span>
                    <small>Campaigns: <strong>{campCounts[favUser?.fav_user_id]}</strong></small>
                    </p>
                                                        
                                </div>

                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-center">
                            <FavouriteButton userId={favUser?.fav_user_id} is_fav_camp={true}/>
                            </div>
                        </div>
                    </div></div>  
                                        </>
                                        ):<div className="flex-column not-found d-flex text-center" >
                                        <img src={notFound} className="notFoundImg" />
                                        <span style={{ opacity: 0.7 }}>No favourite employer found</span>             
                                      
                                    </div>
                    }
                         </div>
                    </>
                )
                }
           

        </>
    );
}
export default FavouriteEmployers;
