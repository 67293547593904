import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Hearticon, Loadingicon, LongArrowLeft } from "../../../AppIcons";
import {
  getQuestionsDetails,
  getUserInfo,
  postFavouriteQuestion,
  postQuestionComment,
  postQuestionLikedislike,
  postQuestionRating,
  postQuestionReport,
} from "../../../api/OurService";
import { useEffect, useState } from "react";
import { useAuth } from "../../../Context/AuthContext";

import styles from "../../../assets/css/styles/Home.module.css";
import { toast } from "react-toastify";
// import smallLogo from "../../../assets/logo/small-logo.png";
import { isEmpty } from "lodash";
import StarRatings from "react-star-ratings";
import Modal from "../../common/Modal";
import BreadCrumbs from "../../common/BreadCrumbs";
import MostViewedQuestions from "./MostViewedQuestions";
import { USER_INFO_KEY, convertLongNumberAbbreviated, parseQuestions, slugify, stripHtmlTags } from "../../../utils/utils";
import { useSearch } from "../../../Context/SearchContext";
import Share from "../../common/share";
import MixedContent from "../../common/mathjaxcontect";

function QuestionFeed() {
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate();
  const [qDetail, setQdetail] = useState({});
  const[favBtnLoader, setFavBtnLoader] = useState(false);
  const[btnLoader, setBtnLoader] = useState(false);
  const[replyBtnLoader, setReplyBtnLoader] = useState(false);
  const[upvoteBtnLoader, setUpvoteBtnLoader] = useState(false);
  const[downvoteBtnLoader, setDownvoteBtnLoader] = useState(false);

  const [isCommenting, setIsCommenting] = useState(false);
  const [commentValue, setCommentValue] = useState({});
  const [isRatingOpen, setIsRatingOpen] = useState(false);
  const [ratingVal, setRatingVal] = useState(0);
  const [viewAnswer, setViewAnswer] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [reportVal, setReportVal] = useState(0);
  const [reportLoading, setReportLoading] = useState(false);
  const [ratingLoading, setRatingLoading] = useState(false);
  const [replyCommentId, setReplyCommentId] = useState(null);
  const [isFavouriteUser, setIsFavouriteUser] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const { listData, setListData, currentPage, setCurrentPage, searchValue, setSearchValue } = useSearch();


  const { state, pathname, search, hash } = useLocation();

  useEffect(() => {
    
    if (!state && !pathname) {
      // alert(1)
      navigate("/");
    }else if(!state && pathname){
        const qId = pathname?.split('-')[pathname?.split('-')?.length - 1]
        setQuestionId(qId);
        getBankDeatils(true, qId)
    }else{
     
    }
  }, []);

  useEffect(() => {
    // Extract the question ID from the URL
    const pathSegments = pathname.split('-');
    const newQuestionId = pathSegments[pathSegments.length - 1];
  
    // If the question ID in the URL is different from the current questionId state
    if (newQuestionId && newQuestionId !== questionId) {
      setQuestionId(newQuestionId);
      getBankDeatils(true, newQuestionId);
    }
  }, [pathname]);

  const getIdFromPath = () =>{

  }

  const { qToken, user, setUser, setFinance, globalToken, setIsEmailVerifyCover } = useAuth();
  const [questionId, setQuestionId] = useState(state?.qId);
  const { name } = useParams();
  // const location = useLocation();
  const[shareContent, setShareContent] = useState({
    url: name == 'question-Bank' ? `https://questionbank.tuteehub.com/mcqs/` + slugify(qDetail?.question)?.substring(0, 80) + '-' + state?.qId : name == 'intereview-questions' ? `https://interviewquestions.tuteehub.com/qna/` + slugify(qDetail?.question)?.substring(0, 80) + '-' + state?.qId : name == 'fullforms' ? `https://fullforms.tuteehub.com/abbreviation/` + slugify(qDetail?.question)?.substring(0, 80) + '-' + state?.qId
      : `https://dictionary.tuteehub.com/meaning/` + slugify(qDetail?.question)?.substring(0, 80) + '-' + state?.qId,
    quote: `${qDetail?.question}`,
    hashing: `#tuteehub`,
    title: `${qDetail?.question}`,
  });
  const token = globalToken;

  // let shareContent = ;


  const handleReplyClick = (id) => {
    setReplyCommentId((prevId) => (prevId === id ? null : id));
  };

  const getBankDeatils = async (loader, qId = questionId) => {
    fetchUserInfo(user?.tuteehub_id)
    if (loader) {
      setLoading(true);
    }
    try {
      const res = await getQuestionsDetails(qId, token, name);
      // console.log('state', state)
      // console.log('res', res)
      setQdetail(res?.data?.data);
      setIsFavouriteUser(res?.data?.data?.is_favourite==0?false:true)
      const qData = res?.data?.data;
      setShareContent({
        url: name == 'question-Bank' ? `https://questionbank.tuteehub.com/mcqs/` + slugify(res?.data?.data?.question)?.substring(0, 80) + '-' + state?.qId : name == 'intereview-questions' ? `https://interviewquestions.tuteehub.com/qna/` + slugify(res?.data?.data?.question)?.substring(0, 80) + '-' + state?.qId : name == 'fullforms' ? `https://fullforms.tuteehub.com/abbreviation/` + slugify(res?.data?.data?.question)?.substring(0, 80) + '-' + state?.qId
          : `https://dictionary.tuteehub.com/meaning/` + slugify(res?.data?.data?.question)?.substring(0, 80) + '-' + state?.qId,
        quote: `${res?.data?.data?.question}`,
        hashing: `#tuteehub`,
        title: `${res?.data?.data?.question}`,
      })
      setRatingVal(res?.data?.data?.user_rating?.rating);
      // console.log({ catId:qData?.topic_id, qId: qData?.id, topicName:qData?.topic?.topic_name, isFavourite:false, moduleName:name, subid:qData?.subject_id })
      // navigation(`/${name}/${slugify(qData?.subject?.subject_title)}/${slugify(qData?.topic?.topic_name)}/${qData?.slug}`)
      navigate(`/${name}/${slugify(qData?.subject?.subject_title)}/${slugify(qData?.topic?.topic_name)}/${qData?.slug}`, {
        state: { catId:qData?.topic_id, qId: qData?.id, topicName:qData?.topic?.topic_name, isFavourite:false, moduleName:name, subid:qData?.subject_id }})
        // window.location.href=`/${name}/${slugify(qData?.subject?.subject_title)}/${slugify(qData?.topic?.topic_name)}/${qData?.slug}`;
      } catch (error) {
      toast.error(error.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
    setBtnLoader(false);

  };
  useEffect(() => {
    
    // console.log(globalToken)
    if (searchValue !== ""){
      // alert(1)
      // navigate(-1)
      handListItemLink();
    }
  }, [searchValue]);



  const handListItemLink = () => {     
    navigate(`/${name}/${slugify(qDetail?.subject?.subject_title)}/${slugify(qDetail?.topic?.topic_name)}`, {
      state: {
        subid:state?.subid,
        topicName:state?.topicName,
        catId:state?.catId,
        moduleName:name,
        comingFrom:'qDetail'
      },
    });
  };

  useEffect(() => {
    return () => localStorage.setItem('feedDetail', 'feedDetail')
  }, [])

  // useEffect(() => {
  //   // Function to get query ID from URL or state
  //   const getQueryIdFromLocation = () => {
  //     const state = location.state;
  //     return state ? state.qId : null;
  //   };

  //   const queryId = getQueryIdFromLocation();
  //   if (queryId) {
  //     getBankDeatils(true, queryId);
  //   }
  // }, [location]);

  useEffect(() => {
    if(state)
    getBankDeatils(true);
    // setIsFavouriteUser(state?.isFavourite);
  }, []);

  const handleComment = async (commentId) => {
    setIsCommenting(true);
    setReplyBtnLoader(true);
    try {
      if (!isEmpty(commentValue)) {
        const res = await postQuestionComment(
          qDetail?.id,
          commentValue?.value,
          commentId,
          token,
          name
        );
        if (res?.success) {
          // alert(JSON.stringify(res))
          toast.success(res?.message);
          await getBankDeatils(false);
          setReplyCommentId(null);
          setCommentValue({});
        } else {
          if(res?.status==5){
            toast.error(res?.errors);
            setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
        }else{
          toast.error(res?.errors || "Something went wrong!");
        }
          // toast.error(res?.errors || "Something went wrong!");
        }
        setIsCommenting(false)
      }
    } catch (error) {
      toast.error(error?.message || "Something went wrong!");
      setIsCommenting(false)
    }
    setReplyBtnLoader(false);
  };
  const changeRating = (newRating) => {
    setRatingVal(newRating);
  };

  const submitRating = async () => {
    setRatingLoading(true);
    try {
      const res = await postQuestionRating(questionId, ratingVal, token, name);
      if (res?.success) {
        setRatingLoading(false);
        await getBankDeatils(true);
        setIsRatingOpen(false);
        toast.success(res?.message);
      } else {
        if(res?.status==5){
          setIsRatingOpen(false);
          toast.error(res?.errors);
          setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
      }else{
        toast.error(res?.errors || "Something went wrong!");
      }
        // toast.error(res?.errors || "Something went wrong!");
      }
    } catch (error) {
      toast.error(error?.message || "Something went wrong!");
    } finally {
      setRatingLoading(false);
    }
  };
  const submitReport = async () => {
    setReportLoading(true);
    try {
      const res = await postQuestionReport(questionId, reportVal, token, name);
      if (res?.success) {
        setReportLoading(false);
        await getBankDeatils(true);
        setIsReportOpen(false);
        toast.success(res?.message);
        setReportVal(null);
      } else {
        if(res?.status==5){
          setIsReportOpen(false);
          toast.error(res?.errors);
          setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
      }else{
        toast.error(res?.message || "Something went wrong!");
      }
        // toast.error(res?.message || "Something went wrong!");
      }
    } catch (error) {
      toast.error(error?.message || "Something went wrong!");
    } finally {
      setReportLoading(false);
    }
  };

  const fetchUserInfo = async (userId) => {
    const res = await getUserInfo(userId, user?.access_token);
    if (res?.success) {
      // console.log(res)
      setUser({ ...user, credits: res?.data?.credits })
      localStorage.setItem('userDetails', JSON.stringify({ ...user, credits: res?.data?.credits }))
      localStorage.setItem('____fi', JSON.stringify({ __crdt: res.data?.credits }))
      setFinance({ __crdt: res.data?.credits })
    }
    // console.log(userDetails?.slug)

  }

  const qLikeDesike = async (value) => {
    if(value=='like' || value=='unlike')
    setUpvoteBtnLoader(true);
    if(value=='remove' || value=='dislike')
      setDownvoteBtnLoader(true);
    try {
      const res = await postQuestionLikedislike(questionId, value, token, name);

      if (res?.success) {
        await getBankDeatils(false);
        toast.success(res?.data?.message);
      } else {
        if(res?.status==5){
            toast.error(res?.errors);
            setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
        }else{
          toast.error(res?.errors || "Something went wrong!");
        }
        // toast.error(res?.errors || "Something went wrong!");
      }
    } catch (error) {
      toast.error(error?.message || "Something went wrong!");
    }
setUpvoteBtnLoader(false);
setDownvoteBtnLoader(false);

  };
  const favouriteQuestion = async (value) => {
    setFavBtnLoader(true);
    try {
      const res = await postFavouriteQuestion(questionId, value, token, name);
      if (res?.success) {
        await getBankDeatils(false);
        setIsFavouriteUser(!isFavouriteUser);

      } else {
        toast.error(res?.data?.message || "Something went wrong!");
      }
    } catch (error) {
      toast.error(error?.message || "Something went wrong!");
    }

    setFavBtnLoader(false);

  };
  function closeRatingModal() {
    setIsRatingOpen(false);
  }
  function closeReportModal() {
    setReportVal(null);
    setIsReportOpen(false);
  }

  const nextPrevIds = (nextRPrevUrl) => {
    
    // Parse the nextUrl string to create a DOM element
    const parser = new DOMParser();
    const doc = parser.parseFromString(nextRPrevUrl, 'text/html');
    const anchor = doc.querySelector('a');
    if (anchor && anchor.getAttribute('href') && anchor.getAttribute('href') !== '#') {

      // Extract the href attribute
      const href = anchor.getAttribute('href');

      // Split the href by hyphens and get the last part
      const parts = href.split('-');
      const nextRPRevId = parts[parts.length - 1];
      return nextRPRevId;
    } else {
      return null;
    }
    

  }

  const handlePrevNext = (id) => {
    setBtnLoader(true)
    getBankDeatils(false, id);
    setQuestionId(id);
  };

  return (
    <>
      <BreadCrumbs />
      {/* <div className="grid grid-cols-12 graph-card-box text-center pt-10">
        <div className="col-span-5 text-card flex items-center">
          <h2
            className="heading-star px-4 flex items-center text-left cursor-pointer"
            style={{ color: "#021442" }}
          > 
            {qDetail?.subject?.subject_title}
          </h2>
        </div>
      </div> */}

      {loading ? (
        <div className="w-full flex items-center justify-center mt-0 mb-20 text-themecolor min-h-[400px] card-body">
          <div className="h-10 w-10 theme-color">
            <Loadingicon />
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-12 px-0 gap-4 py-0 feed-card">
          <div className="col-span-12 py-4 w-full  border-none child-menu   card-body">
            {/* <div className="col-span-12 flex items-center px-8 feed-profile-box">
              <div className="small-logo-box flex items-center">
                <img src={smallLogo} />
                <div className="small-logo-text px-4">
                  <h3>Tuteehub</h3>
                  <p>90.01k Follwers</p>
                </div>
              </div>
              <div className="btn-box">
                <a href="#" className="btn">
                  Follow
                </a>
              </div>
            </div> */}
            {/* <hr className="divider" /> */}

            <div className="col-span-12  px-8 question-box">
              {/* <p>Question.</p> */}
              <h1 className="mainQ-title d-flex align-items-start justify-content-between gap-3">
                {/* <span>{parseQuestions(qDetail?.question)}</span> */}
                <span><MixedContent content={qDetail?.question}/></span>
                <span style={{ width: 80, marginTop:4, color:'#838486' }} className="d-flex align-items-center justify-content-end gap-2">
                  <span
                    className={`item-list cursor-pointer  d-flex mt-0.5 ${isFavouriteUser ? "theme-color" : null
                      }`}
                    onClick={() => favouriteQuestion(isFavouriteUser ? 0 : 1)}
                  >
                    {
                      favBtnLoader?<span className="theme-color" style={{width:20,height:20,display:'inline-block'}}>
                      <Loadingicon />
                    </span>:<Hearticon />
                    }
                    
                  </span>
                  <span className="position-relative  ml-2">
                    {
                      isShare ? <Share shareContent={shareContent} isCustomPos={true} /> : null
                    }
                    <span
                      className={`${styles.icons} ${styles.shareIco}`}
                      role="button"
                      onClick={() => setIsShare(!isShare)}
                    ></span>{" "}
                  </span>
                 

                </span>
              </h1>
              <div className="App"></div>
              {/* <p className="color-light-gray tour-step-3 mt-0 text-capitalize mt-2 mb-0 d-md-flex d-none align-items-center">
                <span className="metas-q">
                <StarRatings
                    rating={!!qDetail?.avg_rating ? qDetail?.avg_rating : 0}
                    starRatedColor="#F08645"
                    changeRating={null}
                    numberOfStars={5}
                    starDimension="20px"
                    starSpacing="0"
                    name="rating"
                  />
                  <span className="ml-1 mr-1">
                    ( {qDetail?.total_rating} )
                  </span>
                </span>
                <span className="big-dots"></span>
                <span className="metas-q" title="Views">
                  <i className="fa fa-eye me-1"></i> 
                  {convertLongNumberAbbreviated(qDetail?.views)}
                </span>
                <span className="big-dots"></span>
                <span className="metas-q" title="Views">
                  <i className="fa fa-comment me-1"></i> 
                  {convertLongNumberAbbreviated(qDetail?.comments_count)}
                </span>
                <span className="big-dots"></span>
                <span className="metas-q cursor-pointer" title="Up Votes"  onClick={() =>
                        qLikeDesike(qDetail?.isUserLike > 0 ? "unlike" : "like")
                      }>
                        <i
                      className={`fas fa-thumbs-up cursor-pointer me-1 ${qDetail?.isUserLike > 0 && "text-themecolor"
                        }`}
                    ></i>
                      {convertLongNumberAbbreviated(qDetail?.likes_count)}
                    
                </span>
              </p> */}
              <ul className="feedback-item flex mb-0">
                <li className="flex items-center">
                  <StarRatings
                    rating={!!qDetail?.avg_rating ? qDetail?.avg_rating : 0}
                    starRatedColor="#F08645"
                    changeRating={null}
                    numberOfStars={5}
                    starDimension="20px"
                    starSpacing="0"
                    name="rating"
                  />
                  <span className="ml-1 mr-1">
                    ( {qDetail?.total_rating} )
                    <span className="big-dots"></span>
                  </span>
                </li>
                <li className="item-list">
                  {convertLongNumberAbbreviated(qDetail?.views)} views .
                </li>
                <li className="item-list">
                  {convertLongNumberAbbreviated(qDetail?.comments_count)}{" "}
                  comments
                  <span className="big-dots"></span>
                </li>
                <li className="item-list d-flex align-items-center">
                  {convertLongNumberAbbreviated(qDetail?.likes_count)} up votes
                  {
                    upvoteBtnLoader?<span className="custom-loader-small ms-2 theme-color">
                    <Loadingicon />
                  </span>:<><i
                    className={`fas fa-thumbs-up cursor-pointer ms-2 ${qDetail?.isUserLike > 0 && "text-themecolor"
                      }`}
                    onClick={() =>
                      qLikeDesike(qDetail?.isUserLike > 0 ? "unlike" : "like")
                    }
                  ></i>{" "}</>
                  }
                  
                  <span className="big-dots"></span>
                </li>
                <li className="item-list  d-flex align-items-center">
                  {convertLongNumberAbbreviated(qDetail?.dislikes_count)} down
                  votes
                  {
                    downvoteBtnLoader?<span className="custom-loader-small ms-2 theme-color">
                    <Loadingicon />
                  </span>:<><i
                    className={`fas fa-thumbs-down ms-2 cursor-pointer ${qDetail?.isUserDisLike > 0 && "text-themecolor"
                      }`}
                    onClick={() =>
                      qLikeDesike(
                        qDetail?.isUserDisLike > 0 ? "remove" : "dislike"
                      )
                    }

                  ></i>{" "}</>
                  }
                  
                  <span className="big-dots"></span>
                </li>
                <li className="item-list">
                  {convertLongNumberAbbreviated(qDetail?.share_count)} share
                </li>

              </ul>
              <hr style={{
                margin: 0,
                borderColor: '#f0f0f0'
              }} />
               
              {(qDetail?.question_type == "radio" || qDetail?.question_type == "checkbox") && qDetail?.answers ? (
                <ol
                  className="py-2 ml-1 pl-4 answer-card"
                  style={{ listStyleType: "upper-alpha" }}
                >
                
                  {
                    JSON.parse(qDetail?.answers)?.map((item) => (
                      <li key={item.option_value} style={{ fontSize: '1rem' }}>{parseQuestions(item.option_value)}</li>
                    ))}
                </ol>
              ):null}
            </div>
            <div className="col-span-12  px-8 question-box question-box-card py-2 mt-3 mb-3 mb-1">
              <button
                href="#"
                className="btn-soft mr-2 cursor-pointer hover:bg-themecolor hover:text-white"
                onClick={() => setViewAnswer(!viewAnswer)}
              >
                {viewAnswer ? 'Hide Answer' : 'View Answer'}
              </button>

              <button
                className="btn-soft mr-2 cursor-pointer hover:bg-themecolor hover:text-white"
                onClick={() => setIsRatingOpen(true)}
              >
                Give Rating
              </button>
              <button
                className="btn-soft mr-2 cursor-pointer hover:bg-themecolor hover:text-white"
                onClick={() => setIsReportOpen(true)}
              >
                Report
              </button>
            </div>
            {viewAnswer ? (<div className="px-8 mb-5">
              {
                name == 'fullforms' || name == 'interview-questions' || name == "dictionary"
                  ? <div id="questAns">
                    <div>
                      <h5 className="fsize-14 font-normal mt-0 regular-font" style={{
                        maxHeight: 300,
                        overflow: 'hidden'
                      }}><strong>Answer: </strong> </h5>
                      {parseQuestions(qDetail?.answer)}</div></div> : 
                        qDetail?.question_type=='radio'?<div id="questAns">
                        <div>
                          <h5 className="fsize-14 font-normal mt-0 regular-font" style={{
                            maxHeight: 300,
                            overflow: 'hidden'
                          }}><strong>Correct Answer is: </strong> {qDetail?.correct_answers}</h5>
                        </div>
                      </div>:<div id="questAns">
                        <div>
                          <h5 className="fsize-14 font-normal mt-0 regular-font" style={{
                            maxHeight: 300,
                            overflow: 'hidden'
                          }}><strong>Correct Answer is: </strong> {JSON.parse(qDetail?.correct_answers)?.map(item => item.answer).join(', ')}</h5>
                        </div>
                      </div>
                      
              }


            </div>) : null}
            <div className="flex justify-between px-8 mb-3">
              <button id="nextprevbtn"
                className={` text-white px-5 py-3 bg-opacity-100 rounded disabled:bg-gray-200 font-medium disabled:text-black`}
                style={{ backgroundColor: !qDetail?.previous_question_id && !nextPrevIds(qDetail?.previous_url) ? '#ccc' : '#ff7200' }}
                disabled={(!qDetail?.previous_question_id && !nextPrevIds(qDetail?.previous_url)) || btnLoader}
                onClick={() => name == 'fullforms' || name == 'dictionary' ? handlePrevNext(nextPrevIds(qDetail?.previous_url)) : handlePrevNext(qDetail?.previous_question_id)}
              >
                {
                  btnLoader?<div className="h-5 w-5 mr-2">
                  <Loadingicon />
                </div>:<>Previous</>
                }
               
              </button>
              <button id="nextprevbtn"
                className={` text-white px-5 py-3 bg-opacity-100 rounded disabled:bg-gray-200 font-medium disabled:text-black`}
                style={{ backgroundColor: !qDetail?.previous_question_id && !nextPrevIds(qDetail?.next_url) ? '#ccc' : '#ff7200' }}
                disabled={(!qDetail?.next_question_id && !nextPrevIds(qDetail?.next_url)) || btnLoader}
                onClick={() => name == 'fullforms' || name == 'dictionary' ? handlePrevNext(nextPrevIds(qDetail?.next_url)) : handlePrevNext(qDetail?.next_question_id)}
              >
                 {
                  btnLoader?<div className="h-5 w-5 mr-2">
                  <Loadingicon />
                </div>:<>Next</>
                }
              </button>
            </div>
            {isRatingOpen ? (
              <Modal
                closeModal={closeRatingModal}
                setIsOpen={setIsRatingOpen}
                isOpen={isRatingOpen}
                callBack={submitRating}
                title="Rate the Question"
                disabled={ratingVal > 0 ? false : true}
                loading={ratingLoading}
              >
                <div className="card  border-0">
                  <div className="card-body shadow">
                    <StarRatings
                      rating={ratingVal}
                      starRatedColor="#F08645"
                      starHoverColor="rgb(253 160 104)"
                      changeRating={changeRating}
                      numberOfStars={5}
                      starDimension="30px"
                      starSpacing="0"
                      name="rating"
                    />
                  </div>
                </div>

              </Modal>
            ) : null}
            {isReportOpen ? (
              <Modal
                closeModal={closeReportModal}
                setIsOpen={setIsReportOpen}
                isOpen={isReportOpen}
                callBack={submitReport}
                title="Report"
                disabled={!reportVal || reportVal?.length<=0?true:false}
                loading={reportLoading}
              >
                <input
                  type="text"
                  placeholder="Enter Report Message"
                  className="w-full p-3 border rounded-lg text-base"
                  onChange={(e) => setReportVal(e.target.value)}
                />
              </Modal>
            ) : null}


          </div>
          <div className="col-span-12 py-4 w-full  border-none child-menu   card-body">
            <div className="col-span-12 flex items-center px-8 question-box py-6 position-relative">
              <a href="#" className="comment-profile">
                <img src={user?.profile_image ? user?.profile_image : require(`../../../assets/images/profile_placeholder.png`)} />
              </a>
              <input
                type="text"
                name=""
                placeholder="Type comment here"
                value={commentValue.type === "user" ? commentValue?.value : ""}
                className="comment-box py-2 mr-0 px-4 placeholder:font-italitc border border-slate-10a0 rounded-md  focus:outline-none postCommentFrm"
                onChange={(e) =>
                  setCommentValue({ type: "user", value: e.target.value })
                }
              />
              <button
                type="button"
                className="btn-soft flex items-center comment-btn bg-none"
                onClick={() => handleComment(0)}
                disabled={
                  isEmpty(commentValue)
                    ? true
                    : commentValue?.type === "user"
                      ? isEmpty(commentValue.value)
                      : true
                }
              >
                {
                  isCommenting ? <div className="h-5 w-5 mr-2">
                    <Loadingicon />
                  </div> : <i className="fab fa-telegram-plane pr-1"></i>
                }

              </button>
            </div>
            <div className="col-span-12 flex items-center px-8 question-box ">
              <button
                className="new-com-select mb-3"
                onClick={() => setShowComment(!showComment)}
              >
                Newest Comment <i className="fa fa-angle-down ml-2"></i>
                {/* <select name="cars" id="cars">
                    <option value="newest">Newest Comment</option>
                  </select> */}
              </button>
            </div>
            {showComment ? isEmpty(qDetail?.comments) ? (
              <div className="text-center font-bold pl-28 py-8 question-box ">
                No Data to Display.
              </div>
            ) : (
              qDetail?.comments?.map((item) => (
                <div className="px-8">
                  <div
                    className="col-md-12 flex items-start pt-3 question-box "
                    key={item.id}
                  >
                    <a href="#" className="comment-profile">
                      <img src={item.user[0]?.profile_image} />
                    </a>
                    <div className="reply-box px-3 w-full">
                      <h2 className="w-100 mb-0" style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        {item.user[0]?.name}
                        {item?.user[0]?.id !== qToken?.id ? (
                          <span
                            className="cursor-pointer px-0"
                            style={{ fontSize: 14, fontWeight: '100', color: '#585858' }}
                            onClick={() => handleReplyClick(item.id)}
                          >
                            <svg
                              width="16"
                              height="12"
                              viewBox="0 0 16 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ color: '#585858' }}
                            >
                              <path
                                d="M7.37407 2.33336H5.99907V1.00002C5.99851 0.86205 5.93917 0.727593 5.82923 0.615151C5.71928 0.502709 5.56413 0.417806 5.38512 0.372124C5.2061 0.326441 5.01202 0.322223 4.82958 0.36005C4.64713 0.397877 4.48529 0.47589 4.3663 0.583357L0.699637 3.91669C0.565713 4.03867 0.494797 4.19092 0.499218 4.34697C0.50364 4.50303 0.583118 4.65303 0.723837 4.77089L4.3905 7.83756C4.51328 7.9397 4.67593 8.0122 4.8568 8.0454C5.03766 8.0786 5.22815 8.07093 5.40286 8.02341C5.57758 7.97589 5.72824 7.89077 5.83475 7.77939C5.94127 7.66802 5.99859 7.53569 5.99907 7.40002V6.33336H7.8324C9.60157 6.33336 12.4066 7.46489 12.4066 10.5C12.407 10.6464 12.4737 10.7886 12.5963 10.9047C12.7189 11.0209 12.8906 11.1044 13.0849 11.1425C13.2792 11.1807 13.4854 11.1712 13.6717 11.1157C13.8579 11.0602 14.0139 10.9617 14.1155 10.8353C14.7992 9.97408 15.1594 8.99719 15.1597 8.00273C15.1601 7.00827 14.8007 6.03125 14.1176 5.16977C13.4345 4.30829 12.4518 3.59269 11.2681 3.09481C10.0844 2.59694 8.74145 2.33433 7.37407 2.33336Z"
                                fill="#2C90D2"
                              />
                            </svg>{" "}
                            &nbsp; reply
                          </span>
                        ) : null}
                      </h2>
                      <p>{item.comment}</p>
                      {replyCommentId === item.id && (
                        <div className="reply-container">
                    <div className="position-relative mb-4 w-100 flex d-flex flex-row align-items-center question-box py-3 pt-0">
                      <input
                        type="text"
                        name=""
                        placeholder="Type comment here"
                        value={
                          commentValue.type === "reply"
                            ? commentValue?.value
                            : ""
                        }
                        style={{width:'auto'}}
                        className="comment-box flex-1 py-2 px-4 placeholder:font-italitc border border-slate-100 rounded-md  focus:outline-none shadows ml-0"
                        onChange={(e) =>
                          setCommentValue({
                            type: "reply",
                            value: e.target.value,
                          })
                        }
                      />
                      <button
                        type="button"
                        id="replybtns"
                        style={{
                          // fontSize: '16px!important',
                          // position: 'absolute',
                          // right: '30px!important',
                          // background: '#f0f0f0!important',
                          borderRadius: '8px',
                          paddingLeft:15,
                          paddingRight:15,
                          right:0
                        }}
                        className="btn-soft flex items-center position-relative comment-btn re-comment-btn px-3"
                        onClick={() => handleComment(item.id)}
                        disabled={
                          isEmpty(commentValue) || replyBtnLoader
                            ? true
                            : commentValue?.type === "reply" || replyBtnLoader
                              ? isEmpty(commentValue.value)
                              : true
                        }
                      >
                        {
                          replyBtnLoader?<div className="h-5 w-5 theme-color">
                          <Loadingicon />
                        </div>:<><i className="fab fa-telegram-plane pr-1"></i> Submit</>
                        }
                        
                      </button>
                    </div>
                    </div>
                  )}
                   {item?.subcomment?.map((el) => (
                    <div
                      className="col-md-12 flex items-center py-4 pt-0 question-box "
                      key={el.id}
                    >
                      <a href="#" className="comment-profile">
                        <img src={el.user[0]?.profile_image} />
                      </a>
                      <div className="reply-box px-3 w-full">
                        <h2 className="w-100 mb-0" style={{ fontSize: '14px', fontWeight: 'bold' }}>{el.user[0]?.name}</h2>
                        <p className="m-0">{el.comment}</p>
                      </div>
                    </div>
                  ))}
                    </div>
                  </div>
                  
                 
                </div>
              ))
            ) : null}
          </div>
          <div className="col-span-12 py-4 w-full  border-none child-menu   card-body">
            {/* <hr className="divider" /> */}
            <div className="col-span-12 flex items-center px-8 feed-profile-box">
              <div className="text-icon-box ">
                <div className="text-group">
                  <h3><strong>Join Our Private Community</strong></h3>
                  <p className="mb-0">For exclusive updates and alerts</p>
                </div>
                <div className="small-logo-text pl-5">
                  <a href="https://twitter.com/TuteeHUB" target={"_blank"}>
                    <i className="fab fa-twitter text-[#00acee]"></i>
                  </a>
                  <a
                    href="https://chat.whatsapp.com/I2CZn2zjQJXDqAz7OJ8uAa"
                    target={"_blank"}
                  >
                    <i className="fab fa-whatsapp"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/TuteeeHub"
                    target={"_blank"}
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCW-5SuG0vv3CpWzq3GfVK-Q"
                    target={"_blank"}
                  >
                    <i className="fab fa-youtube"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/tuteehub-platform"
                    target={"_blank"}
                  >
                    <i className="fab fa-linkedin text-[#0072b1]"></i>
                  </a>
                </div>
              </div>
            </div>
            {/* <hr className="divider" /> */}
          </div>
          <div className="col-span-12 pb-4 w-full  border-none child-menu   card-body">
            <MostViewedQuestions
              topicId={state?.catId}
              getBankDeatils={getBankDeatils}
              setQuestionId={setQuestionId}
              setIsFavouriteUser={setIsFavouriteUser}
              state={state}
              type={name}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default QuestionFeed;
