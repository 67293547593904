import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BreadCrumbs from '../../../common/BreadCrumbs';

function HowGigsWorks() {
  const navigate = useNavigate();
  const location = useLocation();
  const[loading, setLoading] = useState(true);
  
  useEffect(()=>{
    // handleTabChange('notifications');
    // fetchShowtalentToken();
  },[])


 
  return (
        <>
         <BreadCrumbs />
        <div className=" py-4 px-0 grid grid-cols-1 group-card card-body gap-4">
              
        </div>
    </>
  )
}

export default HowGigsWorks;