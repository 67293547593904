import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UseLocalStorage from "../Hooks/useLocalStorage";
import useLocalStorage from "../Hooks/useLocalStorage";
import { useCookies } from "react-cookie";

const AuthContext = React.createContext({});

const AuthProvider = (props) => {
  const navigate = useNavigate();

  const [cookies, setCookie, removeCookie] = useCookies([
    "___gtkn"
  ]);

  const [user, setUser] = useLocalStorage("userDetails", null);
  const [finance, setFinance] = useLocalStorage("____fi", null);
  // const [globalToken, setGlobalToken] = useLocalStorage("___gtkn", null); // Updated to use local storage
  const [globalToken, setGlobalToken] =  useState(cookies["___gtkn"] || null); // Updated to use local storage
  const [qToken, setQtoken] = useLocalStorage("questionBankToken", null);
  const [reloadNotificaton, setReloadNotificaton] = useState(0);
  const [iQToken, setIQtoken] = useLocalStorage("InterviewQuestionToken", null);
  const [ffToken, setFFToken] = useLocalStorage("FullFormToken", null);
  const [dcToken, setDcToken] = useLocalStorage("DictionaryToken", null);
  const [gigToken, setGigtoken] = useLocalStorage("gigsToken", null);
  const [unreadNotifications, setUnreadNotifications] = useLocalStorage("unreadNotifications", null);
  const [isHeader, setIsHeader] = useLocalStorage("isHeader", true);
  const [isEmailVerifyCover, setIsEmailVerifyCover] = useLocalStorage("isEmailVerifyCover", true);
  const [showStrip, setShowStrip] = useLocalStorage("showStrip", true);
  const [askMeAnythingToken, setAskMeAnythingToken] = useState();
  const [assistantAndThreadKey, setAssistantAndThreadKey] = useState();

  useEffect(() => {
    setCookie("___gtkn", globalToken, { path: '/', maxAge: 3600 });
  })
  function handleLogout() { 
   localStorage.clear();
    removeCookie("___gtkn", { path: '/' });
    // Clear the state
    setUser(null);
    setReloadNotificaton(null);
    setIsHeader(true);
    setQtoken(null);
    setGigtoken(null);
    setIQtoken(null);
    setGlobalToken(null);
    setShowStrip(null);
    setFFToken(null);
    setDcToken(null);
    setFinance(null);
    setIsEmailVerifyCover(null);
    setUnreadNotifications(null);
    setAssistantAndThreadKey(null);
    setAskMeAnythingToken(null);
    navigate('/');
    // window.location.href = '/';
    // navigate('/login');
  }
  const authContextValue = {
    user,
    setUser,
    qToken,
    isHeader,
    globalToken,
    setGlobalToken,
    setIsHeader,
    setQtoken,
    showStrip,
    setShowStrip,
    setReloadNotificaton,
    reloadNotificaton,
    setDcToken,
    unreadNotifications,
    setUnreadNotifications,
    dcToken,
    setFinance,
    finance,
    gigToken,
    setGigtoken,
    isEmailVerifyCover,
    setIsEmailVerifyCover,
    handleLogout,
    setAssistantAndThreadKey,
    assistantAndThreadKey,
    iQToken,
    ffToken,
    setFFToken,
    setIQtoken,
    setAskMeAnythingToken,
    askMeAnythingToken
  };

  return <AuthContext.Provider value={authContextValue} {...props} />;
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
