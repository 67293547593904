import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { ERROR_SOMETHING_WENT_WRONG, slugToNormal, yearSatra } from '../../../../utils/utils';
import { getLatestGovNews, getTopLastDateApplyNews, getTopOtherNews, getTopSarkariNews } from '../../../../api/careernews';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import PopularCareerCategories from '../popularcategories';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../../Context/AuthContext';
import BranchDetailList from '../common/branchDetailList';
import NewsListItem from '../common/newsList';


function LastDateToApplyNews() {
    const { globalToken } = useAuth();
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [newsList, setNewsList] = useState([]);
    const navigation = useNavigate();
    useEffect(() => {        
            handleFetchAllLastDateToApplyNews(1);  
    }, [])

    // fetch all last date to apply news
    const handleFetchAllLastDateToApplyNews = async (pageNo) => {
        const resLastDateToApplyNews = await getTopLastDateApplyNews({ token:globalToken, pageNo});
        if (resLastDateToApplyNews?.success) {
            setIsNotFound(false)
            resLastDateToApplyNews?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resLastDateToApplyNews?.data?.length && pageNo == 1) {
                setNewsList(resLastDateToApplyNews?.data);
            } else if (resLastDateToApplyNews?.data?.length && pageNo !== 1) {
                setNewsList([...newsList, ...resLastDateToApplyNews?.data]);
            } else {
                if (pageNo == 1) {
                    setNewsList([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
    }

    // load more news data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        handleFetchAllLastDateToApplyNews(nxtPage);
        setPageNo(nxtPage);
    }

    
    const popularCareerCats = [
        {
            name: "Latest Govt Jobs",
            value: "Latest Govt Jobs",
            redirectUrl: "#"
        },
        {
            name: "Govt Jobs By Qualification",
            value: "Govt Jobs By Qualification",
            redirectUrl: "#"
        },
        {
            name: "Govt Jobs By Location",
            value: "Govt Jobs By Location",
            redirectUrl: "#"
        },
        {
            name: "Latest Private Jobs",
            value: "Latest Private Jobs",
            redirectUrl: "#"
        },
        {
            name: "Private Jobs By Qualification",
            value: "Private Jobs By Qualification",
            redirectUrl: "#"
        },
        {
            name: "Private Jobs By Location",
            value: "Private Jobs By Location",
            redirectUrl: "#"
        },
        {
            name: "Latest Internships",
            value: "Latest Internships",
            redirectUrl: "#"
        },
        {
            name: "Internships By Education",
            value: "Internships By Education",
            redirectUrl: "#"
        },
        {
            name: "Internships By Location",
            value: "Internships By Location",
            redirectUrl: "#"
        }
    ];

    return (<>
        {/* <BreadCrumbs /> */}
        <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                    </li>                    
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/career-news' style={{ fontSize: 14, textDecoration: 'none' }}>Career News</NavLink>
                    </li> 
                    <li 
                        className={`breadcrumb-item capitalize active clamping ellipsis`}
                        >
                        {slugToNormal(`other-latest-10-careernews`)}
                    </li>
                    
      </ol>
    </nav>
        <div className='row'>
            <div className='col-md-9'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    <div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong>Latest 10 Last Date to Apply on Jobs and Admissions For Session ${yearSatra()}</strong>`} subTitle={`Attention! Upcoming last dates for applying to popular Public / Private Sector Job exams, University/College/Degree/Course Entrance Exams, other Competition exams and job alerts in 2024. Practice for Banking, Railways, Defense, Civil Services entrance and more in Question Bank. Prepare for any job interview with Interview Questions`} />
                    </div>
                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />
                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <>
                        {
                            newsList?.map((newsData, sIndex) => (                             
                                <NewsListItem
                                key={sIndex} 
                                thumbPrefix={`thcn/news/desktop/thumb/`}
                                newsData={newsData}  
                                type="hindiNews" 
                                redirectionFn={()=>navigation(`/career-news/news/${newsData?.id}/${newsData?.slug}`)}
                                />
                            ))
                        }

                    </>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
            <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>                   
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div>
        </div>

    </>)
}

export default LastDateToApplyNews;
