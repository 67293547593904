import React from 'react'
import { CDN_BASE_URL, nFormatter } from '../../../../utils/utils';
import careerThumbPlaceholder from '../../../../assets/images/feeds_default_placeholder.webp';

export default function BranchDetailList({ stream, redirectionFn, type }) {
    return (
        <div className={`d-flex w-100 hover-div flex-md-row flex-column justify-content-between align-items-center list-alter  lafter mb-2 py-2 pr-3 position-relative overflow-hidden cursor-pointer`} onClick={() => redirectionFn()} style={{ border: '1px solid #f1e4e4', borderLeft: '3px solid #ccc' }}>

            <div className="d-flex align-items-start p-md-2 pe-0 pe-md-2 py-md-2 py-2 px-2 w-100 w-md-auto ">

                <div className="thumbnail-containers mr-3">
                    {
                        stream?.image ?
                        <img
                        src={
                            CDN_BASE_URL +
                            `thcn/category/thumb/${stream?.image.includes('pattern/') ? 'patterns/'+stream?.image.split('pattern/')[1]:stream?.image.includes('about/') ? stream?.image.split('about/')[1] : stream?.image}`
                        }
                        width="80"
                        height="80"
                        className="rounded bg-gray"
                        alt="Thumbnail"
                    />
                            : <img src={`${careerThumbPlaceholder}`} width="80" height="80" className="rounded bg-gray" style={{ border: '1px solid #f1e4e4' }} />
                    }

                </div>

                <div className="flex-1">
                    <h6 className="mb-0 justify-content-between d-md-block d-flex align-items-start w-100 mt-0 text-capitalize title-task color-333">
                        <span className="theme-a cursor-pointer d-flex align-items-center gap-2 pr-md-0 pr-5"><strong>{stream?.title}</strong> {

                        }</span>
                    </h6>
                    <div className="w-100 text-black-50 text-capitalize mt-1 mb-0 d-md-flexs d-inline-block  align-items-center">
                        <small>{nFormatter(stream?.news_count || 0)} News</small>
                    </div>
                    <p className="mb-0 mt-1 color-gray fsize-meta desc-camp">{stream?.description}</p>
                </div>
            </div>
        </div>
    )
}
