import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Loadingicon } from "../../../../AppIcons";
import BreadCrumbs from "../../../common/BreadCrumbs";
import { Formik } from "formik";
import { useAuth } from "../../../../Context/AuthContext";
import { toast } from "react-toastify";
import { Editor } from '@tinymce/tinymce-react';

import notFound from '../../../../assets/images/not_found_new.png';
import { useNavigate, useParams } from "react-router-dom";
import { createCampaign, getGigsConfigurations, getTaskCategories, getTaskDetail } from "../../../../api/OurService";
import CustomSelect from "../../../common/customselect";
import { CDN_BASE_URL, TINY_EDITOR_KEY } from "../../../../utils/utils";
import TaskNavigationTab from "../navigationtab";


function EditCampaign() {
    // const formik = useFormik();
    const [loading, setLoading] = useState(true);
    const [firstLoading, setFirstLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [isCatLoading, setIsCatLoading] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [isSubCatLoading, setIsSubCatLoading] = useState(false);
    const { user, setIsEmailVerifyCover } = useAuth();
    const [taskCatsFormatted, setTaskCatsFormatted] = useState([]);
    const [choosenDefaultSubCat, setChoosenDefaultSubCat] = useState();
    const [taskSubCatsFormatted, setTaskSubCatsFormatted] = useState([]);
    const [frequencyCapFormatted, setFrequencyCapFormatted] = useState([]);    
    const [ageRangeFormatted, setAgeRangeFormatted] = useState([]);

    const moderateEditorRef = useRef(null);
    const [choosenTaskCat, setChoosenTaskCat] = useState();
    const [choosenDefautlTaskCat, setChoosenDefautlTaskCat] = useState();
    const [choosenTaskSubCat, setChoosenTaskSubCat] = useState();
    const [choosenCampaign, setChoosenCampaign] = useState();
    const [choosenDefaultCountries, setChoosenDefaultCountries] = useState([]);
    const [choosenDefaultCountryIds, setChoosenDefaultCountryIds] = useState([]);
    const navigate = useNavigate()
    const params = useParams();
    const { gigToken, globalToken } = useAuth();
    const [isCountryLoading, setIsCountryLoading] = useState(true);
    const [countryFormatted, setCountryFormatted] = useState();
    const { taskCatName, taskSubCatName } = useParams();
    let cId = taskCatName?.split('-').reverse()[0];
    let scId = taskSubCatName?.split('-').reverse()[0];

    const DisplayingErrorMessagesSchema = Yup.object().shape({

        scat_id: Yup.string().required("Sub Category is required"),
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
        proof_requirement: Yup.string().required("Proof requirement is required"),
        per_task_cost: Yup.string().required("Per task credit is required"),
        cat_id: Yup.string().required("Category is required"),
        // country_ids: Yup.array().min(1, "At least one country is required"),
        frequency_id: Yup.string().required("Frequency is required"),
        time_interval: Yup.string().required("Duration is required"),
    });


    const handleGigsConfigurations = async (conf, catId = null, comingFrom) => {
        try {
            // const token = JSON.parse(localStorage.getItem('gigsToken')).token;
            const gigsConfRes = await getGigsConfigurations({ token: globalToken, conf: conf, cat_id: catId });
            if (gigsConfRes?.success) {
                // if (conf == 'country') {
                //     const countryData = gigsConfRes.data?.map(option => ({
                //         value: option.id,
                //         label: option.countryname
                //     })) || [];
                //     setCountryFormatted(countryData);

                // }
                if (conf == 'cat') {
                    const categoryData = gigsConfRes.data?.category?.map(option => ({
                        value: option.id,
                        label: option.name
                    })) || [];
                    setTaskCatsFormatted(categoryData);
                }
                if (conf == 'freq') {
                    const freqData = gigsConfRes.data?.frequency?.map(option => ({
                        value: option.id,
                        label: option.frequency
                    })) || [];
                    setFrequencyCapFormatted(freqData);
                }
                if(conf=='AgeRange'){
                
                    const ageRangeData = gigsConfRes.data?.AgeRange?.map(option => ({
                        value: option.id,
                        label: option.range + ' Years'
                    })) || [];
                    // console.log('age range', ageRangeData)
                    setAgeRangeFormatted(ageRangeData);
                    // matchAgeGroup(ageRangeData)
                }
                if (conf == 'subcat') {
                    setTaskSubCatsFormatted([]);
                    setChoosenDefaultSubCat(null);
                    const subcategoryData = gigsConfRes.data?.subcategory?.map(option => ({
                        value: option.id,
                        label: option.name
                    })) || [];
                    setTaskSubCatsFormatted(subcategoryData);

                    if (comingFrom) {
                        setChoosenDefaultSubCat({ label: choosenCampaign?.scat?.name, value: choosenCampaign?.scat?.id })
                        setChoosenTaskSubCat(choosenCampaign?.scat?.id);
                    } else {
                        setChoosenDefaultSubCat(subcategoryData[0]);
                        setChoosenTaskSubCat(subcategoryData[0]?.value)
                    }

                }
                // setIsCountryLoading(false);
                setIsCatLoading(false);
                setFirstLoading(false);
            }
        } catch (error) {
            console.error('Error fetching gigs configurations:', error);
        }
    }


    const matchAgeGroup = (value)=>{
        const matchedAgeData = ageRangeFormatted?.filter((ag)=> ag?.label.split(' Years')[0] == value);
        return matchedAgeData[0]?.value;
    }
    const getCampaignDetails = async (cId) => {
        // const token = JSON.parse(localStorage.getItem('gigsToken')).token;
        const campaignDetailRes = await getTaskDetail({ token: globalToken, task_id: cId });
        setLoading(false);
        
        if (campaignDetailRes?.success && campaignDetailRes?.data) {
            // if(campaignDetailRes?.data?.campaigncountry?.length){
            //     let countryData = campaignDetailRes?.data?.campaigncountry?.map(option => ({
            //         value: option.id,
            //         label: option.countryname
            //     })) || [];
            //     let cIds = campaignDetailRes?.data?.campaigncountry?.map(option => option.id) || [];

            //     setChoosenDefaultCountryIds(cIds);
            //     setChoosenDefaultCountries(countryData);

            // }
            
            setChoosenCampaign(campaignDetailRes?.data);
            setIsNotFound(false);
        } else {
            setIsNotFound(true);
        }

    }
    useEffect(() => {
        // handleGigsConfigurations('country', null, null);
        handleGigsConfigurations('cat', null, null);
        handleGigsConfigurations('freq', null, null);
        handleGigsConfigurations('AgeRange', null, null);
    }, [])
    useEffect(() => {
        if (params?.cId) {
            getCampaignDetails(params?.cId);
        }
    }, [params?.cId])


    useEffect(() => {
        // setChoosenDefautlTaskCat({label:choosenCampaign?.cat?.name,value:choosenCampaign?.cat?.id}); 
        if (choosenCampaign) {
            // alert(JSON.stringify({label:choosenCampaign?.cat?.name,value:choosenCampaign?.cat?.id}))


            setChoosenDefaultSubCat({ label: choosenCampaign?.scat?.name, value: choosenCampaign?.scat?.id })
            setChoosenTaskCat(choosenCampaign?.cat?.id);
            setChoosenTaskSubCat(choosenCampaign?.scat?.id);
            handleGigsConfigurations('subcat', choosenCampaign?.scat?.cat_id, 'pre');

        }
    }, [choosenCampaign])

    const handleCustomSelectOnChange = (value, type, label, setFieldValue) => {
        if (type == 'taskCat') {
            setTaskSubCatsFormatted([]);
            setChoosenDefaultSubCat(null);
            setChoosenTaskCat(value)

            handleGigsConfigurations('subcat', value, null);
            setFieldValue('cat_id', value);
        }
        if (type == 'taskSubCat') {
            setIsSubCatLoading(false);
            setIsCatLoading(false);
            setChoosenTaskSubCat(value);
            setFieldValue('scat_id', value);
        }
        // if (type == 'country') {
        //     let cValue = value?.map(cV => (cV?.value))
        //     setFieldValue('country_ids', cValue);
        // }

    }

    const handleEditCampaign = async (values) => {
        setBtnLoading(true)
        // const token = JSON.parse(localStorage.getItem('gigsToken')).token;
        const createCampaignParams = {
            ...values,
            cat_id: choosenTaskCat,
            scat_id: choosenTaskSubCat,
            user_id: gigToken?.id,
            id: choosenCampaign?.id
        }
        const res = await createCampaign({ token: globalToken, createCampaignParams }, 'edit');

        setFirstLoading(false);
        if (res?.success) {
            toast.success(res?.message);
            navigate('/gigs/my-campaign')
        } else {
            if(res?.status==5){
                toast.error(res?.errors);
                setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
            }else{
                toast.error(res?.errors)
            }
            // toast.error(res?.errors)
        }
        setBtnLoading(false)
    }


    const formData = [
        {
            id: 'title',
            title: 'Title',
            type: 'text',
            name: 'title',
            placeholder: 'e.g 1 Minute Survey on Pets',
            required: true,
            editable: true
        },
         {
            id: 'cat_id',
            title: 'Category',
            type: 'text',
            name: 'cat_id',
            placeholder: 'Pick from the list',
            required: true,
            editable: false
        }, {
            id: 'scat_id',
            title: 'Sub Category',
            type: 'text',
            name: 'scat_id',
            placeholder: 'Pick from the list',
            required: true,
            editable: false,
            half: true
        },
        // {
        //     id: 'country_ids',
        //     title: 'Country',
        //     type: 'text',
        //     name: 'country_ids',
        //     placeholder: 'Country',
        //     required: true,
        //     editable: true,
        //     isCountry: true
        // },
        {
            id: 'age',
            title: 'Select Target Audience By Age Range',
            type: 'number',
            name: 'age',
            placeholder: 'Pick from the list',
            required: false,
            editable: true,
            half: true
        }, {
            id: 'gender',
            title: 'Select Target Audience By Gender',
            type: 'text',
            name: 'gender',
            placeholder: 'Pick from the list',
            required: false,
            editable: true,
            isGender: true,
            half: true
        },{
            id: 'description',
            title: 'Enter your campaign description',
            type: 'text',
            name: 'description',
            placeholder: 'Describe the objectives of your campaign, outline the steps/tasks to be performed by participants, and include any important links or resources related to the campaign',
            required: true,
            editable: true,
            width: true,
            isEditor: true
        },
        {
            id: 'proof_requirement',
            title: 'Select Type Of Proof Required',
            type: 'text',
            name: 'proof_requirement',
            placeholder: 'Pick from the list',
            required: true,
            editable: true,
        },
        {
            id: 'proof_desc',
            title: 'Specify Task Validation Criteria',
            type: 'text',
            name: 'proof_desc',
            placeholder: 'Describe how participants should provide proof of completing tasks. E.g Browser history screenshot, System Time snapshot, Screen screenshots etc',
            required: false,
            editable: true,
            width: true,
            isEditor: true
        }, {
            id: 'frequency_id',
            title: 'Allow Task To Be Repeated',
            type: 'number',
            name: 'frequency_id',
            placeholder: 'Pick from the list',
            required: true,
            editable: true,
            isFrequency: true,
            third: true
        }, {
            id: 'time_interval',
            title: 'Time Allowed to Complete Task (In Minutes)',
            type: 'number',
            name: 'time_interval',
            placeholder: 'e.g 1',
            required: true,
            editable: true,
            isTimeInterval: true,
            third: true
        }, {
            id: 'per_task_cost',
            title: 'Credit Rate Per Approved Task',
            type: 'number',
            name: 'per_task_cost',
            placeholder: 'e.g 100',
            required: true,
            editable: true,
            third: true
        }
    ]

    return (
        <>
            <BreadCrumbs />

            <div className=" py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4 shadow">
                {loading && !isNotFound ? (
                    <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                        <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                            <div className="h-10 w-10">
                                <Loadingicon />
                            </div>
                        </div>
                    </div>) : !loading && isNotFound ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="vdo-module-notfound">
                                    <img src={notFound} className="notFoundImg" />
                                    <p className="color-gray text-center">No task found to edit</p>
                                </div>
                            </div>
                        </div>) : (
                    <>
                        {/* <TaskNavigationTab tabType={'createcampaign'} /> */}
                        <div className="card mb-3 border-0 shadows">
                            <div className="card-body d-flex align-items-start flex-column gap-2">

                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        title: choosenCampaign?.title,
                                        age: matchAgeGroup(choosenCampaign?.age),
                                        description: choosenCampaign?.description,
                                        proof_requirement: choosenCampaign?.proof_requirement,
                                        proof_desc: choosenCampaign?.proof_req_desc,
                                        cat_id: choosenCampaign?.cat_id,
                                        scat_id: choosenCampaign?.subcat_id,
                                        per_task_cost: choosenCampaign?.per_task_credit,
                                        task_thumbnails: null,
                                        gender: choosenCampaign?.gender,
                                        // country_ids: choosenDefaultCountryIds,
                                        frequency_id: choosenCampaign?.frequency_id,
                                        time_interval: choosenCampaign?.time_interval

                                    }}
                                    validationSchema={DisplayingErrorMessagesSchema}
                                    onSubmit={(values) => {
                                        handleEditCampaign(values)
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        setFieldValue,
                                        handleSubmit,
                                    }) => (
                                        <form className="" onSubmit={handleSubmit}>
                                            <div className="row">
                                                {
                                                    formData?.map(fieldMeta => (
                                                        <div className={`${fieldMeta.width ? 'col-md-12' : (fieldMeta?.id == 'cat_id' && !taskSubCatsFormatted?.length) ? 'col-md-12' : (fieldMeta?.id == 'cat_id' && taskSubCatsFormatted?.length) ? 'col-md-6' : (fieldMeta?.id == 'scat_id' && taskSubCatsFormatted?.length && fieldMeta?.half) ? 'col-md-6' : (fieldMeta?.id == 'scat_id' && !taskSubCatsFormatted?.length && fieldMeta?.half) ? 'col-md-6 d-none' : fieldMeta?.id !== 'scat_id' && fieldMeta?.half ? 'col-md-6' : (fieldMeta?.id=='frequency_id' || fieldMeta?.id=='time_interval' || fieldMeta?.id=='per_task_cost') && fieldMeta?.third?'col-md-4':'col-md-12'}`} style={{ marginTop: (fieldMeta?.id == 'country_ids' && taskSubCatsFormatted?.length == 0) ? 0 : 0 }}>

                                                            {
                                                                fieldMeta?.id !== 'scat_id' ? <label
                                                                    htmlFor={fieldMeta?.id}
                                                                    className="block mb-2 text-sm font-bold text-gray-900 dark:text-gray-900"
                                                                    style={{fontSize:'.775rem',color: '#4d4d4d'}}
                                                                >
                                                                    {fieldMeta?.title}

                                                                    {
                                                                        fieldMeta?.required ? <span className="text-red-400 text-base">*</span> : null
                                                                    }

                                                                </label> : null
                                                            }

                                                            <input type="password" name="fake-password" autoComplete="new-password" style={{ display: 'none' }} />
                                                            {
                                                                fieldMeta.width ?
                                                                    <>
                                                                        {
                                                                            fieldMeta?.isEditor ?
                                                                                <div style={{ marginBottom: 20 }}>
                                                                                    <Editor

                                                                                        apiKey={TINY_EDITOR_KEY}
                                                                                        onInit={(evt, editor) => moderateEditorRef.current = editor}
                                                                                        initialValue={choosenCampaign?.description}
                                                                                        init={{
                                                                                            height: '200',
                                                                                            auto_focus: true,
                                                                                            menubar: false,
                                                                                            plugins: 'hr lists table textcolor code link image',
                                                                                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic forecolor link image media | alignleft aligncenter alignright | hr bullist numlist table | subscript superscript | removeformat code',

                                                                                            // allow custom url in link? nah just disabled useless dropdown..
                                                                                            file_picker_types: 'image',
                                                                                            file_picker_callback: function (cb, value, meta) {
                                                                                                var input = document.createElement('input');
                                                                                                input.setAttribute('type', 'file');
                                                                                                input.setAttribute('accept', 'image/*');
                                                                                                input.onchange = () => {
                                                                                                    const file = input.files[0];
                                                                                                    const reader = new FileReader();

                                                                                                    reader.onload = () => {
                                                                                                        const base64 = reader.result.split(',')[1];
                                                                                                        cb('data:image/jpeg;base64,' + base64);
                                                                                                    };

                                                                                                    reader.readAsDataURL(file);
                                                                                                };

                                                                                                input.click();
                                                                                            },
                                                                                            anchor_top: false,
                                                                                            anchor_bottom: false,
                                                                                            branding: false,
                                                                                            loading: true
                                                                                        }}
                                                                                        onEditorChange={(content, editor) => {
                                                                                            setFieldValue(fieldMeta?.id, content);

                                                                                        }}
                                                                                    />
                                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                    </div>
                                                                                </div> : <>
                                                                                    <textarea type={fieldMeta?.type}

                                                                                        name={fieldMeta?.name}
                                                                                        autoComplete={'off'}
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        value={values[fieldMeta?.id] || ""}
                                                                                        id={fieldMeta?.name} rows={6} placeholder={fieldMeta.title} className=" border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"></textarea>
                                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                    </div></>
                                                                        }

                                                                    </>
                                                                    : fieldMeta?.id=='age'?
                                                                    <>
                                                                            <select
                                                                                name={fieldMeta?.name}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values[fieldMeta?.id] || ""}
                                                                                id={fieldMeta?.name} rows={6} placeholder={fieldMeta.title} className=" border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                                                                                    {
                                                                                        ageRangeFormatted?.map((option, index)=>(
                                                                                            <option key={index+'ageRange'} value={option?.value}>{option?.label}</option>
                                                                                        ))
                                                                                    }
                                                                                
                                                                            </select>
                                                                            <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                            </div>
                                                                        </>
                                                                    : fieldMeta.isGender ?
                                                                        <>
                                                                            <select
                                                                                name={fieldMeta?.name}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values[fieldMeta?.id] || ""}
                                                                                id={fieldMeta?.name} placeholder={fieldMeta.title} className=" border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                                                                                <option value="">All</option>
                                                                                <option value="male">Male</option>
                                                                                <option value="female">Female</option>
                                                                            </select>
                                                                            <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        // fieldMeta.isCountry ?
                                                                        //     <>
                                                                        //         {
                                                                        //             countryFormatted?.length ?
                                                                        //                 <div className="flex-1">
                                                                        //                     <CustomSelect options={countryFormatted} defaultOption={choosenDefaultCountries} isMultiple={true} isClearable={true} handleCustomSelectOnChange={handleCustomSelectOnChange}
                                                                        //                         setFieldValue={setFieldValue}
                                                                        //                         type={'country'} isSelectLoading={isCountryLoading} placeholder={'Country'} />
                                                                        //                 </div> : null
                                                                        //         }

                                                                        //         <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                        //             {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                        //         </div>
                                                                        //     </>
                                                                        //     : 
                                                                        fieldMeta.id == 'proof_requirement' ?
                                                                            <>
                                                                                <select
                                                                                    name={fieldMeta?.name}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    value={values[fieldMeta?.id] || ""}
                                                                                    id={fieldMeta?.name} rows={6} placeholder={fieldMeta.title} className=" border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                                                                                    <option value="" selected disabled></option>
                                                                                    <option value="Text Input Only">Text Input Only</option>
                                                                                    <option value="Screenshots">Screenshots</option>
                                                                                    <option value="Screenshots & Text Only">Screenshots & Text Only</option>
                                                                                </select>
                                                                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                    {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                </div>
                                                                            </>
                                                                            : fieldMeta?.id == 'cat_id' ?
                                                                                <>

                                                                                    {
                                                                                        !taskCatsFormatted?.length ? null :
                                                                                            <div className="flex-1">
                                                                                                <CustomSelect name={fieldMeta?.name} defaultOption={{ label: choosenCampaign?.cat?.name, value: choosenCampaign?.cat?.id }} options={taskCatsFormatted} handleCustomSelectOnChange={handleCustomSelectOnChange}
                                                                                                    setFieldValue={setFieldValue}
                                                                                                    type={'taskCat'}
                                                                                                    isSelectLoading={isCatLoading}
                                                                                                    isClearable={false}
                                                                                                    placeholder={'Pick from the list'} />
                                                                                            </div>
                                                                                    }
                                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                    </div>
                                                                                </>
                                                                                : fieldMeta?.id == 'scat_id' ?
                                                                                    <>
                                                                                        {
                                                                                            !taskSubCatsFormatted?.length ? null : <label
                                                                                                htmlFor={fieldMeta?.id}
                                                                                                className="block mb-2 text-sm font-bold text-gray-900 dark:text-gray-900"
                                                                                                style={{fontSize:'.775rem',color: '#4d4d4d'}}
                                                                                            >
                                                                                                {fieldMeta?.title}

                                                                                                {
                                                                                                    fieldMeta?.required ? <span className="text-red-400 text-base">*</span> : null
                                                                                                }
                                                                                            </label>
                                                                                        }

                                                                                        {
                                                                                            !taskSubCatsFormatted?.length ? null :
                                                                                                <><div className="flex-1">
                                                                                                    <CustomSelect name={fieldMeta?.name} defaultOption={choosenDefaultSubCat} options={taskSubCatsFormatted} handleCustomSelectOnChange={handleCustomSelectOnChange} type={'taskSubCat'}
                                                                                                        setFieldValue={setFieldValue}
                                                                                                        isSelectLoading={isSubCatLoading}
                                                                                                        placeholder={'Pick from the list'}
                                                                                                        isClearable={false} />
                                                                                                </div>
                                                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                                    </div>
                                                                                                </>
                                                                                        }

                                                                                    </>
                                                                                    : fieldMeta?.isFrequency ?
                                                                                        <>
                                                                                            <select
                                                                                                name={fieldMeta?.name}
                                                                                                onChange={handleChange}
                                                                                                onBlur={handleBlur}
                                                                                                value={values[fieldMeta?.id] || ""}
                                                                                                id={fieldMeta?.name} placeholder={fieldMeta.title} className=" border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                                                                                                <option value="" selected disabled>Select Frequency</option>
                                                                                                {
                                                                                                    frequencyCapFormatted?.map(freqOption => (
                                                                                                        <option value={freqOption?.value}>{freqOption?.label}</option>
                                                                                                    ))
                                                                                                }


                                                                                            </select>
                                                                                            <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                                {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                            </div>
                                                                                        </>
                                                                                        : <>
                                                                                            <input
                                                                                                disabled={!fieldMeta?.editable}
                                                                                                type={fieldMeta?.type}
                                                                                                name={fieldMeta?.id}
                                                                                                autoComplete={'off'}
                                                                                                id={fieldMeta?.name}
                                                                                                className=" border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                                                                placeholder={fieldMeta?.title}
                                                                                                onChange={handleChange}
                                                                                                onBlur={handleBlur}
                                                                                                value={values[fieldMeta?.id] || ""}
                                                                                            />
                                                                                            <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                                {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                            </div>
                                                                                        </>
                                                            }


                                                        </div>
                                                    ))
                                                }
                                                {
                                                    choosenCampaign?.thumbnail? <div className="col-md-1 mb-3">
                                                    <div className="image-container rounded">
                                                        <img src={CDN_BASE_URL + '' + choosenCampaign?.thumbnail} alt="campaign image" className="rounded" />

                                                    </div>
                                                </div>:''
                                                }
                                               
                                                <div className="col-md-11 mb-3">
                                                    <label className="mb-2" style={{fontSize:'.775rem',color: '#4d4d4d',fontWeight:'bold'}}>Set Campaign Image (Minimum 767px X 500px | Upto 1 MB | Supported Formats JPG,PNG,BMP,WEBP)</label>
                                                    <input type="file" onChange={(event) => {
                                                        setFieldValue('task_thumbnails', event.currentTarget.files[0]);
                                                    }}
                                                        onBlur={handleBlur}
                                                        className=" border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                        name="task_thumbnails" />
                                                </div>
                                            </div>
                                            {/* </div> */}
                                            <div className="col-md-2">
                                                <button
                                                    type="submit"
                                                    disabled={btnLoading}
                                                    className="mt-2 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center"
                                                >
                                                    {btnLoading ? (
                                                        <div className="h-5 w-5">
                                                            <Loadingicon />
                                                        </div>
                                                    ) : (
                                                        "Save"
                                                    )}
                                                </button>
                                            </div>
                                        </form>
                                    )}

                                </Formik>
                                <small className="text-black-50 mt-3">Note* : Credits greater than or equal to "Credit Rate Per Approved Task", must be added to activate a campaign after you click "Save". </small>
                           
                            </div>
                        </div>

                    </>
                )
                }
            </div>

        </>
    );
}
export default EditCampaign;
